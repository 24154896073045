import React from 'react'

const IconTag = (props) => {
  return (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.2" d="M16.33 10.9385L10.8427 16.4257C10.7455 16.5229 10.6136 16.5775 10.4762 16.5775C10.3387 16.5775 10.2069 16.5229 10.1096 16.4257L3.67022 9.98822C3.57316 9.89105 3.51862 9.75935 3.51855 9.62201V3.61426H9.52631C9.66365 3.61433 9.79536 3.66887 9.89252 3.76593L16.33 10.2034C16.3784 10.2516 16.4168 10.3089 16.443 10.372C16.4692 10.435 16.4827 10.5027 16.4827 10.571C16.4827 10.6392 16.4692 10.7069 16.443 10.7699C16.4168 10.833 16.3784 10.8903 16.33 10.9385Z" fill="#667085" />
      <path d="M16.6962 9.83653L10.2593 3.3997C10.1634 3.30298 10.0492 3.2263 9.92333 3.17411C9.79748 3.12193 9.66252 3.09528 9.52628 3.09571H3.51853C3.38101 3.09571 3.24912 3.15034 3.15188 3.24758C3.05464 3.34483 3.00001 3.47672 3.00001 3.61424V9.62199C2.99957 9.75822 3.02622 9.89319 3.07841 10.0191C3.13059 10.1449 3.20727 10.2591 3.30399 10.3551L9.74082 16.7919C9.83712 16.8882 9.95145 16.9647 10.0773 17.0168C10.2031 17.0689 10.338 17.0957 10.4742 17.0957C10.6104 17.0957 10.7453 17.0689 10.8711 17.0168C10.997 16.9647 11.1113 16.8882 11.2076 16.7919L16.6962 11.3033C16.7925 11.207 16.8689 11.0927 16.921 10.9669C16.9732 10.841 17 10.7062 17 10.57C17 10.4337 16.9732 10.2989 16.921 10.173C16.8689 10.0472 16.7925 9.93283 16.6962 9.83653ZM10.4739 16.0589L4.03705 9.62199V4.13276H9.52628L15.9631 10.5696L10.4739 16.0589ZM7.1482 6.46612C7.1482 6.61995 7.10258 6.77033 7.01712 6.89823C6.93165 7.02614 6.81018 7.12583 6.66806 7.1847C6.52594 7.24357 6.36955 7.25897 6.21867 7.22896C6.0678 7.19895 5.92921 7.12487 5.82043 7.0161C5.71166 6.90732 5.63758 6.76873 5.60757 6.61786C5.57756 6.46698 5.59296 6.3106 5.65183 6.16847C5.7107 6.02635 5.81039 5.90488 5.9383 5.81941C6.0662 5.73395 6.21658 5.68833 6.37041 5.68833C6.57669 5.68833 6.77453 5.77028 6.92039 5.91614C7.06625 6.062 7.1482 6.25984 7.1482 6.46612Z" fill="#343330" />
    </svg>
  )
}

export default IconTag