import React from 'react'

const IconReadyTesting = (props) => {
  return (
    <svg {...props} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.2222" r="8.25" stroke="currentColor" stroke-width="1.5" />
      <path d="M10 4.22223C11.5913 4.22223 13.1174 4.85437 14.2426 5.97959C15.3679 7.10481 16 8.63093 16 10.2222C16 11.8135 15.3679 13.3397 14.2426 14.4649C13.1174 15.5901 11.5913 16.2222 10 16.2222L10 10.2222V4.22223Z" fill="currentColor" />
    </svg>

  )
}

export default IconReadyTesting