import React from 'react'

const IconTodo = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4723 2.96393C11.2935 2.09313 10.4427 1.53216 9.57183 1.71094C8.94116 1.84043 8.44831 2.33328 8.31884 2.96393H7.78149V5.75363H12.441V2.96393H11.4723ZM10.0575 8.03066H7.15556C6.88842 8.03066 6.67188 7.81413 6.67188 7.54699C6.67188 7.27988 6.88841 7.06334 7.15556 7.06334H10.0574C10.3245 7.06334 10.5411 7.27988 10.5411 7.54699C10.5411 7.81413 10.3246 8.03066 10.0575 8.03066ZM10.0575 14.6083H7.15556C6.88842 14.6083 6.67188 14.3918 6.67188 14.1246C6.67188 13.8575 6.88841 13.641 7.15556 13.641H10.0574C10.3245 13.641 10.5411 13.8575 10.5411 14.1246C10.5411 14.3918 10.3246 14.6083 10.0575 14.6083ZM9.28363 10.3522H7.15556C6.88842 10.3522 6.67188 10.1356 6.67188 9.86853C6.67188 9.60141 6.88841 9.38488 7.15556 9.38488H9.28363C9.55074 9.38488 9.76727 9.60141 9.76727 9.86853C9.76727 10.1356 9.55074 10.3522 9.28363 10.3522ZM9.28363 12.4802H7.15556C6.88842 12.4802 6.67188 12.2637 6.67188 11.9966C6.67188 11.7295 6.88841 11.513 7.15556 11.513H9.28363C9.55074 11.513 9.76727 11.7295 9.76727 11.9966C9.76727 12.2637 9.55074 12.4802 9.28363 12.4802ZM15.6678 5.74201C15.6678 4.73794 14.9559 3.98153 14.0118 3.98153C13.7447 3.98153 13.5281 4.19808 13.5281 4.46518C13.5281 4.73229 13.7447 4.94883 14.0118 4.94883C14.4858 4.94883 14.7005 5.35896 14.7005 5.74201V6.57583C15.0283 6.59493 15.3528 6.65141 15.6678 6.74414V5.74201Z" fill="currentColor" />
            <path d="M14.7008 15.6298C14.7008 16.3495 14.2598 16.3495 14.1205 16.3495H5.84036C5.45344 16.3495 5.02783 16.098 5.02783 15.6298V5.74203C5.00646 5.3259 5.32649 4.9712 5.74264 4.94985C5.75457 4.94923 5.76651 4.9489 5.77846 4.94887C6.04558 4.94887 6.26211 4.73233 6.26211 4.46522C6.26211 4.19812 6.04558 3.98157 5.77846 3.98157C4.82859 3.98237 4.05924 4.75303 4.06006 5.70288C4.06006 5.71595 4.06024 5.729 4.06054 5.74207V15.6298C4.07591 16.5774 4.85654 17.3331 5.80413 17.3178C5.81621 17.3176 5.82829 17.3173 5.84038 17.3168H14.1204C15.0491 17.3168 15.6681 16.6398 15.6681 15.6298V15.0495C15.3533 15.1435 15.0288 15.2013 14.7008 15.2216V15.6298Z" fill="currentColor" />
            <path d="M14.3833 7.09971C12.3105 7.09971 10.6302 8.78006 10.6302 10.8529C10.6302 12.9257 12.3105 14.606 14.3833 14.606C16.4561 14.606 18.1364 12.9257 18.1364 10.8529C18.1364 8.78006 16.4561 7.09971 14.3833 7.09971ZM16.0935 10.0016L14.5923 12.1471C14.5592 12.1944 14.516 12.2337 14.4658 12.2622C14.4156 12.2906 14.3596 12.3075 14.3021 12.3115H14.2673C14.2139 12.3116 14.1611 12.3007 14.1121 12.2794C14.0632 12.258 14.0192 12.2268 13.9829 12.1877L12.6964 10.7929C12.5472 10.6398 12.5504 10.3948 12.7035 10.2457C12.8565 10.0966 13.1015 10.0998 13.2505 10.2528C13.2555 10.2579 13.2604 10.2632 13.2651 10.2686L14.2324 11.3094L15.4589 9.5586C15.5813 9.3834 15.8225 9.3405 15.9977 9.46283C16.173 9.58516 16.2159 9.8264 16.0935 10.0016ZM12.8929 5.37503C12.8929 5.60216 12.7087 5.78628 12.4816 5.78628H7.37513C7.14795 5.78628 6.96387 5.60216 6.96387 5.37503V3.35021C6.96387 3.12308 7.148 2.93896 7.37513 2.93896H12.4816C12.7088 2.93896 12.8928 3.12308 12.8928 3.35021L12.8929 5.37503Z" fill="currentColor" />
        </svg>

    )
}

export default IconTodo