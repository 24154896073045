import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Loader from '../../common/ui/Loader';

const TimeSheetChart = ({ data, loading }) => {
  const [series, setSeries] = useState([{
    name: "Time",
    data: []
  }]);

  const convertDecimalToRealHours = (decimalHours) => {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    const realHours = hours + minutes / 100;
    return realHours;
  };

  const [options, setOptions] = useState({
    chart: {
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      curve: 'straight',
      lineCap: 'butt',
      colors: "#5461d6",
      width: 2,
      dashArray: 0,
    },
    title: {
      text: 'Timings',
      align: 'left',
      style: {
        fontSize: "16px",
        fontWeight: 600
      }
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      show: false
    }
  });

  useEffect(() => {
    if (data?.data && data.data.length > 0) {
      const timeData = data.data.map((time) => convertDecimalToRealHours(time.totalHours));
      const labelsData = data.data.map((item) => moment(item.day).format("DD MMM"));
      const labels = data.data.map((item) => item.formattedTime);

      setSeries([{ name: "Time", data: timeData }]);
      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          categories: labelsData,
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return (
              '<div class="arrow_box px-2">' +
              labels[dataPointIndex] +
              '</div>'
            );
          }
        }
      }));
    }
  }, [data]);

  return (
    <div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px' }}>
          <Loader />
        </div>
      ) : series[0].data.length === 0 ? (
        <div>Loading ...</div>
      ) : (
        <ReactApexChart options={options} series={series} type="line" width={"100%"} height={250} className="timesheet-chart" />
      )}
    </div>
  );
};

export default TimeSheetChart;
