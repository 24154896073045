import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import API from '../services/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
    const [user, setUser] = useState();
    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('user')))
    }, [])
    const logout = async () => {
        try {
            // await API.post('/auth/logout');
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            toast.success('Log out successful!');
            window.location.pathname = '/login';
        } catch (err) {
            toast.error("Invalid login details");
        }
    };
    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, logout, user, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
