import React from 'react'

const IconDone= (props) => {
  return (
    <svg {...props} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_109_8665)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1.77771C5.03913 1.77771 1 5.89164 1 10.9444C1 15.9971 5.03913 20.111 10 20.111C14.9609 20.111 19 15.9971 19 10.9444C19 5.89164 14.9609 1.77771 10 1.77771ZM10 3.44438C14.0765 3.44438 17.3636 6.79237 17.3636 10.9444C17.3636 15.0964 14.0765 18.4444 10 18.4444C5.92348 18.4444 2.63636 15.0964 2.63636 10.9444C2.63636 6.79237 5.92348 3.44438 10 3.44438ZM12.9995 8.02771C12.7869 8.03116 12.584 8.11872 12.4338 8.27185L8.7983 11.8672L7.56623 10.6498C7.48946 10.5719 7.39825 10.5102 7.29791 10.4685C7.19757 10.4268 7.09009 10.4058 6.98171 10.4067C6.87333 10.4077 6.76621 10.4305 6.66658 10.474C6.56694 10.5174 6.47677 10.5806 6.40131 10.6598C6.32584 10.7391 6.26658 10.8328 6.22697 10.9355C6.18736 11.0383 6.16819 11.148 6.17056 11.2584C6.17294 11.3687 6.19682 11.4775 6.24081 11.5784C6.28481 11.6793 6.34804 11.7703 6.42684 11.8461L8.22941 13.6267C8.38229 13.7773 8.58674 13.8613 8.79943 13.861C9.01212 13.8607 9.21633 13.776 9.36879 13.625L13.5732 9.46647C13.6928 9.35116 13.7755 9.20175 13.8105 9.03778C13.8454 8.87381 13.8309 8.70291 13.7689 8.54745C13.707 8.392 13.6003 8.25922 13.4631 8.16648C13.3258 8.07375 13.1642 8.02538 12.9995 8.02771Z" fill="currentcolor" />
      </g>
      <defs>
        <clipPath id="clip0_109_8665">
          <rect width="20" height="20" fill="white" transform="translate(0 0.777771)" />
        </clipPath>
      </defs>
    </svg>

  )
}

export default IconDone