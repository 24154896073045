import React from 'react'

const IconSubtask = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6668 11.3333C12.5873 11.3333 13.3335 10.5871 13.3335 9.66667C13.3335 8.74619 12.5873 8 11.6668 8C10.7464 8 10.0002 8.74619 10.0002 9.66667C10.0002 10.5871 10.7464 11.3333 11.6668 11.3333Z" fill="#7E869E" fill-opacity="0.25" stroke="currentColor" stroke-width="1.2" />
            <path d="M14.9998 18C15.9203 18 16.6665 17.2538 16.6665 16.3333C16.6665 15.4129 15.9203 14.6667 14.9998 14.6667C14.0794 14.6667 13.3332 15.4129 13.3332 16.3333C13.3332 17.2538 14.0794 18 14.9998 18Z" fill="#7E869E" fill-opacity="0.25" stroke="currentColor" stroke-width="1.2" />
            <path d="M4.1665 3.83333V13C4.1665 14.5713 4.1665 15.357 4.65466 15.8452C5.14281 16.3333 5.92849 16.3333 7.49984 16.3333H13.3332" stroke="currentColor" stroke-width="1.2" />
            <path d="M4.1665 6.33333C4.1665 7.90468 4.1665 8.69036 4.65466 9.1785C5.14281 9.66667 5.92849 9.66667 7.49984 9.66667H9.99984" stroke="currentColor" stroke-width="1.2" />
        </svg>

    )
}

export default IconSubtask