import React from 'react'

const IconInProgress = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10.8889" r="8.25" stroke="currentColor" stroke-width="1.5" />
            <path d="M10 4.88892C11.1596 4.88892 12.2943 5.22491 13.2669 5.85627C14.2395 6.48762 15.0083 7.38727 15.4803 8.4464L10 10.8889V4.88892Z" fill="currentColor" />
        </svg>

    )
}

export default IconInProgress