import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import { requestNotificationPermission } from '../firebase-notifications';
import { toast } from 'react-toastify';
import { onMessage } from 'firebase/messaging';
import { messaging } from '../firebase-config';

const DashboardLayout = () => {
  const [openSidebar, setOpenSidebar] = useState(false);

  const location = useLocation();

  // Create the navigate function inside App, where it is properly inside the Router context
  const navigate = useNavigate();

  useEffect(() => {
    requestNotificationPermission();

    // Listen for foreground messages
    const unsubscribe = onMessage(messaging, (payload) => {
      const pID = payload?.data?.projectId;
      const sID = payload?.data?.sprintId;

      // Show toast with click event for navigation
      const toastId = toast.info(
        <>
          <strong>{payload.notification.title}</strong>
          <br />
          {payload.notification.body}
        </>, {
        position: "bottom-right",
        autoClose: true, // Disable auto-close
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClick: () => {
          // Navigate to the project and sprint details on toast click
          if (pID && sID) {
            const cleanPID = JSON.parse(pID);
            const cleanSID = JSON.parse(sID);
            navigate(`/project/${cleanPID}/${cleanSID}`, { replace: true, });
            // Close the toast after navigation
            toast.update(toastId, {
              render: "Navigating...",
              type: "success",
              autoClose: 2000,
              closeButton: true,
            });
          }
        },
      });
    });

    // Cleanup function to unsubscribe from the message listener
    return () => {
      unsubscribe();
    };
  }, [navigate]); // Add navigate to the dependency array

  useEffect(() => {
    setOpenSidebar(false);
  }, [location])

  return (
    <div className='h-screen'>
      <div className="flex h-screen">
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
        <main className='lg:w-[calc(100%-250px)] w-full lg:ms-[250px] bg-[#F3F3F8] p-4 lg:ps-0'>
          <div className="bg-white rounded-lg h-full">
            <Header openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
            <div className="h-[calc(100%-57px)] overflow-y-auto">
              <Outlet />
            </div>
          </div>
          {/* <div className="p-4 ps-0 bg-white">
          </div> */}
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;