import React, { useState } from 'react';
import { CirclePlus, Plus } from 'lucide-react';
import BugItem from './BugItem';
import { cn } from '../lib/utils';
import QuickAddTaskItem from './QuickAddTaskItem';
import IconCicularPlus from './svgIcons/IconCicularPlus';

const QuickAddTaks = ({ className, status, task, addTask, newBug, setNewBug }) => {
    const [tasks, setTasks] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    const handleAddTask = () => {
        const newTask = {
            id: tasks.length + 1,
            title: '',
            image: 'https://example.com/image.jpg',
            timeAgo: 'Just now',
        };
        setTasks([...tasks, newTask]);
        setIsEditing(true); // Enable editing for the newly added task

    };

    const handleSaveTask = (index, newTitle) => {
        const updatedTasks = [...tasks];
        updatedTasks[index].title = newTitle; // Update the title of the task
        setTasks(updatedTasks); // Save the updated tasks
        // setIsEditing(false); // End editing mode
        handleAddTask()
    };

    const handleCancel = (index) => {
        const updatedTasks = [...tasks];
        updatedTasks.splice(index, 1); // Remove the task if canceled
        setTasks(updatedTasks);
        setIsEditing(false); // End editing mode
    };

    return (
        <div>
            {/* Render Bug Items */}
            {tasks.map((task, index) => (
                <QuickAddTaskItem
                    key={task.id}
                    task={task}
                    isEditing={isEditing && index === tasks.length - 1} // Only the latest task is editable
                    // onSave={(newTitle) => handleSaveTask(index, newTitle)} // Save task
                    onCancel={() => handleCancel(index)} // Cancel task
                    setIsEditing={setIsEditing}
                    addTask={addTask}
                    newBug={newBug}
                    setNewBug={setNewBug}
                    status={status}
                />
            ))}

            {/* Add New Task Button */}
            {!isEditing && (
                <div
                    className={cn('border-b border-border py-2 ps-2 px-3 hover:bg-gray-100 cursor-pointer flex items-center gap-2.5', className)}
                    onClick={handleAddTask}
                >
                    <IconCicularPlus className="h-4 w-4" />
                    <p className="text-gray-400">New Task</p>
                </div>
            )}
        </div>
    );
};

export default QuickAddTaks;
