import { Dialog, DialogPanel, DialogTitle, Select } from '@headlessui/react'
import { X } from 'lucide-react'
import React, { useEffect } from 'react'
import { Input } from '../../common/Input'
import { Button } from '../../common/ui/Button'
import { roleTypes } from '../../staticData/data'
import Buttonloader from '../../common/ui/ButtonLoader'

const EditUserModal = ({ isOpen, setIsOpen, newUser, setNewUser, userData, setSelectedUser, handleSubmit, loader }) => {

    useEffect(() => {
        setNewUser({ name: userData?.name, email: userData?.email, role: userData?.role });
    }, [userData])


    const close = () => {
        setIsOpen(false);
        setSelectedUser();
        setNewUser({ name: '', email: '', role: '' })
    }

    return (
        <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => close()}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel
                        transition
                        className="w-full max-w-md rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 overflow-hidden"
                    >
                        <DialogTitle as="h3" className="text-base/7 font-medium text-dark bg-forground px-4 py-2 flex justify-between border-b border-border">
                            <div className="">
                                <p className='text-xl font-bold'>Edit Employee</p>
                            </div>
                            <span className='cursor-pointer' onClick={() => close()}><X className='text-dark/50' /></span>
                        </DialogTitle>
                        <div className="">
                            <form onSubmit={handleSubmit}>
                                <div className="p-8 py-5">
                                    <div className="mb-3">
                                        <Input placeholder='User Name'
                                            value={newUser?.name}
                                            onChange={(e) => setNewUser({ ...newUser, name: e.target.value })} />
                                    </div>
                                    <div className="mb-3">
                                        <Input placeholder='User Email'
                                            value={newUser?.email}
                                            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} />
                                    </div>
                                    <div className="mb-3">
                                        <Select
                                            className='w-full rounded-md border border-border bg-[#FBFBFF] px-4 py-3.5 text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50'
                                            value={newUser?.role}
                                            onChange={(e) => {
                                                setNewUser({ ...newUser, role: e.target.value });
                                            }}
                                        >
                                            <option value="">Select Role</option>
                                            {roleTypes?.map((user) => (
                                                <option key={user.id} value={user.value} selected={newUser?.role === user.value}>
                                                    {user.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <div className="py-4 px-6 border-t border-border flex items-center justify-end gap-2">
                                    <Button variant='outline' type="button" onClick={() => close()}>
                                        Cancel
                                    </Button>
                                    {/* <Button type="submit">
                                        Update Employee
                                    </Button> */}
                                    <Button type="submit" disabled={loader}>
                                        {loader ? <Buttonloader /> : 'Update Employee'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export default EditUserModal