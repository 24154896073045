import React from 'react'

const IconHold = (props) => {
  return (
    <svg {...props} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0461 19.3346C8.25686 19.3343 6.50789 18.8036 5.0203 17.8094C3.53271 16.8152 2.37329 15.4023 1.68862 13.7493C1.00396 12.0963 0.824789 10.2773 1.17377 8.52249C1.52276 6.76764 2.38422 5.15566 3.64925 3.89036C5.35416 2.23458 7.64212 1.31613 10.0187 1.33349C12.3952 1.35086 14.6695 2.30266 16.3501 3.98318C18.0306 5.66371 18.9824 7.938 18.9998 10.3146C19.0171 12.6911 18.0987 14.9791 16.4429 16.684C15.6048 17.5268 14.608 18.195 13.51 18.65C12.412 19.105 11.2346 19.3377 10.0461 19.3346ZM10.0461 3.00682C6.03163 3.00682 2.76571 6.27274 2.76571 10.2872C2.76571 14.3016 6.03163 17.5675 10.0461 17.5675C14.0605 17.5675 17.3264 14.3016 17.3264 10.2872C17.3264 6.27274 14.0605 3.00682 10.0461 3.00682Z" fill="currentColor" />
      <path d="M8.4558 13.998C8.22148 13.998 7.99674 13.9049 7.83105 13.7392C7.66535 13.5735 7.57227 13.3488 7.57227 13.1145V7.45983C7.57227 7.2255 7.66535 7.00077 7.83105 6.83508C7.99674 6.66938 8.22148 6.57629 8.4558 6.57629C8.69013 6.57629 8.91486 6.66938 9.08056 6.83508C9.24626 7.00077 9.33934 7.2255 9.33934 7.45983V13.1145C9.33934 13.3488 9.24626 13.5735 9.08056 13.7392C8.91486 13.9049 8.69013 13.998 8.4558 13.998ZM11.6365 13.998C11.4022 13.998 11.1775 13.9049 11.0118 13.7392C10.8461 13.5735 10.753 13.3488 10.753 13.1145V7.45983C10.753 7.2255 10.8461 7.00077 11.0118 6.83508C11.1775 6.66938 11.4022 6.57629 11.6365 6.57629C11.8709 6.57629 12.0956 6.66938 12.2613 6.83508C12.427 7.00077 12.5201 7.2255 12.5201 7.45983V13.1145C12.5201 13.3488 12.427 13.5735 12.2613 13.7392C12.0956 13.9049 11.8709 13.998 11.6365 13.998Z" fill="currentColor" />
    </svg>

  )
}

export default IconHold