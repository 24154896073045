import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { X } from 'lucide-react';
import React, { useState } from 'react';
import { cn } from '../../lib/utils';
import { Input } from '../Input';
import { Button } from './Button';

const EstimatedTimePopover = ({ className, time, id, addEstimatedTime, removeEstimatedTime, hoverId, setHoverId, task }) => {
    const [hours, setHours] = useState('');
    const [estHours, setEstHours] = useState(time);
    // useEffect(() => {
    //     setEstHours(time)
    // }, [time])
    const handleKeyPress = (e, close) => {
        if (e.key === 'Enter') {
            if (hours > 0) {
                addEstimatedTime(hours);
                close();
                setEstHours(hours)
                setHours('')
            }
        }
    };
    return (
        <div className={cn('w-full relative', className)}>
            <Popover>
                {({ close }) => (
                    <>
                        <PopoverButton className="w-full flex items-center justify-start text-sm/6 font-semibold text-dark/60 focus:outline-none data-[focus]:outline-1">
                            {!!estHours ? `${estHours} hr` : '-'}
                        </PopoverButton>
                        <PopoverPanel
                            transition
                            anchor="bottom"
                            className="min-w-[220px] divide-y shadow-custom divide-white/5 rounded-xl bg-white text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0 overflow-hidden"
                        >
                            <div className="p-3 max-h-[200px]">
                                <div className="flex items-center gap-4">
                                    <p>Time Estimate </p>
                                    <Input
                                        className="py-1.5"
                                        value={hours}
                                        onChange={(e) => { setHours(e.target.value); }}
                                        onKeyPress={(e) => handleKeyPress(e, close)}
                                        // prefix={
                                        //     hours && <Button className='bg-transparent h-6 w-6 p-1 hover:bg-border shadow-none' onClick={() => { removeEstimatedTime(); close(); setHours('') }}><X className='text-dark/50' /></Button>
                                        // }
                                        prefixClass='right-2 flex items-center'
                                    />
                                </div>
                            </div>
                        </PopoverPanel>
                    </>
                )}
            </Popover>
            {(hoverId === task?._id && estHours > 0) && (
                <div className="absolute right-0 top-1/2 -translate-y-1/2 z-[9] flex items-center gap-1.5">
                    <Button
                        className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeEstimatedTime();
                            setHours('');
                            setEstHours(null);
                        }}
                    >
                        <X className='text-dark/50 h-4 w-4' />
                    </Button>
                </div >
            )
            }
        </div >
    );
};

export default EstimatedTimePopover;
