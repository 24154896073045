import { useState } from "react";
import { Input } from "./Input";
import { Button } from "./ui/Button";
import { CornerDownLeft } from "lucide-react";

const BugItem = ({ task, isEditing, onSave, onCancel, addBugs }) => {
    const [editValue, setEditValue] = useState(task.title || '');

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            addBugs(editValue);
            setEditValue('')
        }
    };

    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center w-full">
                <div className="flex-grow">
                    {isEditing && (
                        <>
                            <Input
                                placeholder={`Task name or type '/' commands`}
                                className="w-full px-3 pe-[135px] bg-white py-2.5 border-0 border-b rounded-none"
                                value={editValue}
                                onChange={(e) => setEditValue(e.target.value)}
                                onKeyPress={handleKeyPress}
                                autoFocus
                                prefix={
                                    <div className="flex items-center gap-2">
                                        <Button className='px-1 py-[2px] h-auto text-sm rounded-md bg-transparent shadow-none text-gray-400 border border-gray-100 hover:bg-gray-100' onClick={() => onCancel()}>Cancel</Button>
                                        <Button className='px-1 py-[2px] h-auto text-sm rounded-md inline-flex items-center gap-[2px] border border-primary hover:border-primary/90' onClick={() => { addBugs(editValue); setEditValue('') }}>
                                            Save
                                            <span><CornerDownLeft className="h-3 w-3" /></span>
                                        </Button>
                                    </div>
                                }
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BugItem;
