import { Dialog, DialogPanel, DialogTitle, Select } from '@headlessui/react'
import { ChevronRightIcon, X } from 'lucide-react'
import moment from 'moment'
import React, { useState } from 'react'
import { Input } from '../../common/Input'
import { Button } from '../../common/ui/Button'
import { getIcon } from '../../lib/utils'
import Buttonloader from '../../common/ui/ButtonLoader'
import { useForm } from 'react-hook-form'

const AddTaskModal = ({ isOpen, setIsOpen, newTask, setNewTask, handleAddTask, selectedProject, activeSprint, loader }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: newTask
    })

    console.log("selecteProject" , selectedProject);

    const onSubmit = (data) => {
        const updatedData = {
            ...data,
            assignedTo: data.assignedTo ? [data.assignedTo] : [],
        };
        console.log("data" , updatedData);
        setNewTask({ ...newTask, ...updatedData });
        handleAddTask(updatedData);
        // reset();
    };

    return (
        <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(false)}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel
                        transition
                        className="w-full max-w-md rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 overflow-hidden"
                    >
                        <DialogTitle as="h3" className="text-base/7 font-medium text-dark bg-forground px-4 py-2 flex justify-between border-b border-border">
                            <div className="">
                                <p className='text-xl font-bold'>Add Task</p>
                                <h2 className='py-1 text-[#98A1B2] flex items-center gap-1 text-sm'>{selectedProject?.name}  <span><ChevronRightIcon className='w4 h-4' /></span> <span>{getIcon(activeSprint?.status)}</span> <span className='truncate'>{activeSprint?.name} - {moment(activeSprint?.startDate).format('DD/MM/YYYY')} - {moment(activeSprint?.endDate).format('DD/MM/YYYY')}</span></h2>
                            </div>
                            <span className='cursor-pointer' onClick={() => setIsOpen(false)}><X className='text-dark/50' /></span>
                        </DialogTitle>
                        <div className="">
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="p-8 py-5">
                                    <div className="mb-3">
                                        <Input placeholder='Task Title'
                                            {...register('title', { required: 'Task Title is required' })}
                                            className={`${errors.title ? 'border-red-500' : ''}`}
                                        />
                                        {errors.title && <p className="text-red-500 text-xs mt-1">{errors.title.message}</p>}
                                    </div>
                                    <div className="mb-3">
                                        {/* <label className="block text-sm font-medium text-dark mb-1">
                                            Description
                                        </label> */}
                                        <textarea
                                            rows={3}
                                            placeholder='Description'
                                            className={`block w-full rounded-md border border-border bg-[#FBFBFF] px-4 py-3.5 text-sm transition-colors placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 `}
                                            {...register('description')}    
                                        />
                                    </div>
                                    {/* <div className="mb-3">
                                        <label className="block text-sm font-medium text-dark mb-1">
                                            Select Project
                                        </label>
                                        <Select
                                            className='w-full rounded-md border border-border bg-[#FBFBFF] px-4 py-3.5 text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50'
                                            value={newTask.projectId}
                                            onChange={(e) => { setNewTask({ ...newTask, projectId: e.target.value }); fetchMember(e.target.value); }}
                                        >
                                            <option>Select Project</option>
                                            {myProjects.map((project) => (
                                                <option key={project._id} value={project._id}>
                                                    {project.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </div> */}
                                    <div className="mb-3">
                                        {/* <label className="block text-sm font-medium text-dark mb-1">
                                            Select Assignee
                                        </label> */}
                                        <Select
                                            className={`w-full rounded-md border border-border bg-[#FBFBFF] px-4 py-3.5 text-sm transition-colors focus-visible:outline-none focus-visible:ring-1 ${errors.assignedTo ? 'border-red-500' : ''}`}
                                            {...register('assignedTo', { required: 'Please select an assignee' })}
                                        >
                                            <option value="">Select Assignee</option>
                                            {selectedProject?.team?.map((user) => (
                                                <option key={user.userId?._id} value={user.userId?._id}>
                                                    {user.userId?.name}
                                                </option>
                                            ))}
                                        </Select>
                                        {errors.assignedTo && <p className="text-red-500 text-xs mt-1">{errors.assignedTo.message}</p>}
                                    </div>
                                    {/* <div className="mb-3">
                                        <label className="block text-sm font-medium text-dark mb-1">
                                            Select Sprint
                                        </label>
                                        <Select
                                            className='w-full rounded-md border border-border bg-[#FBFBFF] px-4 py-3.5 text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50'
                                            value={newTask.sprintId}
                                            onChange={(e) => {
                                                const userId = e.target.value;
                                                setNewTask({ ...newTask, sprintId: e.target.value });
                                            }}
                                        >
                                            <option>Select Sprint</option>
                                            {filterUser?.sprints?.map((sprint) => (
                                                <option key={sprint._id} value={sprint._id}>
                                                    {sprint.name} ({moment(sprint?.startDate).format('DD MMM YYYY')} - {moment(sprint?.endDate).format('DD MMM YYYY')})
                                                </option>
                                            ))}
                                        </Select>
                                    </div> */}
                                    <div className="mb-3">
                                        {/* <label className="block text-sm font-medium text-dark mb-1">
                                            Select Date
                                        </label> */}
                                        <input
                                            type='date'
                                            className={`w-full rounded-md border border-border bg-[#FBFBFF] px-4 py-3.5 text-sm transition-colors focus-visible:outline-none focus-visible:ring-1`}
                                        />
                                    </div>
                                    <div className="">
                                        {/* <label className="block text-sm font-medium text-dark mb-1">
                                            Select Priority
                                        </label> */}
                                        <Select
                                            className={`w-full rounded-md border border-border bg-[#FBFBFF] px-4 py-3.5 text-sm transition-colors focus-visible:outline-none focus-visible:ring-1`}
                                        >
                                            <option value="">Select Priority</option>
                                            <option value="Urgent">Urgent</option>
                                            <option value="High">High</option>
                                            <option value="Medium">Medium</option>
                                            <option value="Low">Low</option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="py-4 px-6 border-t border-border flex items-center justify-end gap-2">
                                    <Button variant='outline' type="button" onClick={() => setIsOpen(false)}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" disabled={loader}>
                                        {loader ? <Buttonloader /> : 'Create Task'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export default AddTaskModal