import React from 'react'

const IconDevelopment = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10.4445" r="8.25" stroke="currentColor" stroke-width="1.5" />
        </svg>

    )
}

export default IconDevelopment