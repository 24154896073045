import React from 'react'

const IconDashboard = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" d="M8.75 4.73871V8.48871C8.75 8.65447 8.68415 8.81344 8.56694 8.93065C8.44973 9.04786 8.29076 9.11371 8.125 9.11371H4.375C4.20924 9.11371 4.05027 9.04786 3.93306 8.93065C3.81585 8.81344 3.75 8.65447 3.75 8.48871V4.73871C3.75 4.57295 3.81585 4.41398 3.93306 4.29677C4.05027 4.17956 4.20924 4.11371 4.375 4.11371H8.125C8.29076 4.11371 8.44973 4.17956 8.56694 4.29677C8.68415 4.41398 8.75 4.57295 8.75 4.73871ZM15.625 4.11371H11.875C11.7092 4.11371 11.5503 4.17956 11.4331 4.29677C11.3158 4.41398 11.25 4.57295 11.25 4.73871V8.48871C11.25 8.65447 11.3158 8.81344 11.4331 8.93065C11.5503 9.04786 11.7092 9.11371 11.875 9.11371H15.625C15.7908 9.11371 15.9497 9.04786 16.0669 8.93065C16.1842 8.81344 16.25 8.65447 16.25 8.48871V4.73871C16.25 4.57295 16.1842 4.41398 16.0669 4.29677C15.9497 4.17956 15.7908 4.11371 15.625 4.11371ZM8.125 11.6137H4.375C4.20924 11.6137 4.05027 11.6796 3.93306 11.7968C3.81585 11.914 3.75 12.0729 3.75 12.2387V15.9887C3.75 16.1545 3.81585 16.3134 3.93306 16.4307C4.05027 16.5479 4.20924 16.6137 4.375 16.6137H8.125C8.29076 16.6137 8.44973 16.5479 8.56694 16.4307C8.68415 16.3134 8.75 16.1545 8.75 15.9887V12.2387C8.75 12.0729 8.68415 11.914 8.56694 11.7968C8.44973 11.6796 8.29076 11.6137 8.125 11.6137ZM15.625 11.6137H11.875C11.7092 11.6137 11.5503 11.6796 11.4331 11.7968C11.3158 11.914 11.25 12.0729 11.25 12.2387V15.9887C11.25 16.1545 11.3158 16.3134 11.4331 16.4307C11.5503 16.5479 11.7092 16.6137 11.875 16.6137H15.625C15.7908 16.6137 15.9497 16.5479 16.0669 16.4307C16.1842 16.3134 16.25 16.1545 16.25 15.9887V12.2387C16.25 12.0729 16.1842 11.914 16.0669 11.7968C15.9497 11.6796 15.7908 11.6137 15.625 11.6137Z" fill="currentColor" />
            <path d="M15.625 10.9887H11.875C11.5435 10.9887 11.2255 11.1204 10.9911 11.3548C10.7567 11.5892 10.625 11.9072 10.625 12.2387V15.9887C10.625 16.3202 10.7567 16.6382 10.9911 16.8726C11.2255 17.107 11.5435 17.2387 11.875 17.2387H15.625C15.9565 17.2387 16.2745 17.107 16.5089 16.8726C16.7433 16.6382 16.875 16.3202 16.875 15.9887V12.2387C16.875 11.9072 16.7433 11.5892 16.5089 11.3548C16.2745 11.1204 15.9565 10.9887 15.625 10.9887ZM15.625 15.9887H11.875V12.2387H15.625V15.9887ZM8.125 3.48871H4.375C4.04348 3.48871 3.72554 3.6204 3.49112 3.85482C3.2567 4.08925 3.125 4.40719 3.125 4.73871V8.48871C3.125 8.82023 3.2567 9.13817 3.49112 9.37259C3.72554 9.60701 4.04348 9.73871 4.375 9.73871H8.125C8.45652 9.73871 8.77446 9.60701 9.00888 9.37259C9.2433 9.13817 9.375 8.82023 9.375 8.48871V4.73871C9.375 4.40719 9.2433 4.08925 9.00888 3.85482C8.77446 3.6204 8.45652 3.48871 8.125 3.48871ZM8.125 8.48871H4.375V4.73871H8.125V8.48871ZM15.625 3.48871H11.875C11.5435 3.48871 11.2255 3.6204 10.9911 3.85482C10.7567 4.08925 10.625 4.40719 10.625 4.73871V8.48871C10.625 8.82023 10.7567 9.13817 10.9911 9.37259C11.2255 9.60701 11.5435 9.73871 11.875 9.73871H15.625C15.9565 9.73871 16.2745 9.60701 16.5089 9.37259C16.7433 9.13817 16.875 8.82023 16.875 8.48871V4.73871C16.875 4.40719 16.7433 4.08925 16.5089 3.85482C16.2745 3.6204 15.9565 3.48871 15.625 3.48871ZM15.625 8.48871H11.875V4.73871H15.625V8.48871ZM8.125 10.9887H4.375C4.04348 10.9887 3.72554 11.1204 3.49112 11.3548C3.2567 11.5892 3.125 11.9072 3.125 12.2387V15.9887C3.125 16.3202 3.2567 16.6382 3.49112 16.8726C3.72554 17.107 4.04348 17.2387 4.375 17.2387H8.125C8.45652 17.2387 8.77446 17.107 9.00888 16.8726C9.2433 16.6382 9.375 16.3202 9.375 15.9887V12.2387C9.375 11.9072 9.2433 11.5892 9.00888 11.3548C8.77446 11.1204 8.45652 10.9887 8.125 10.9887ZM8.125 15.9887H4.375V12.2387H8.125V15.9887Z" fill="currentColor" />
        </svg>

    )
}

export default IconDashboard