import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { CheckIcon, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Title from './ui/Title';
import AssigneePopover from './ui/AssigneePopover';
import DueDate from './ui/DueDate';
import EstimatedTimePopover from './ui/EstimatedTimePopover';
import PriorityDropdown from './ui/PriorityDropdown';
import TaskDetailModal from '../components/modal/TaskDetailModal';
import DeleteModal from '../components/modal/DeleteModal';
import API from '../services/api';
import { statusType } from '../staticData/data';

const SubTaskListItem = ({ type, task, projectDetails, user, sprintDetails, fetchCurrentTask, bugId, subtaskId, tagList }) => {
    const queryClient = useQueryClient()
    const [taskOpen, setTaskOpen] = useState(false);
    const [hoverId, setHoverId] = useState();
    const [deleteOpen, setDeleteOpen] = useState(false)
    const color = statusType.find((status) => status?.name === task?.status);
    const [selectedTags, setSelectedTags] = useState([]);

    const showMenu = (id) => setHoverId(id);
    const hideMenu = () => setHoverId('');

    const { mutate: subTaskstatusChangeMutation } = useMutation({
        mutationFn: async (newStatus) => {
            const response = await API.patch(`/task/subtasks/${task?._id}`, { status: newStatus });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['subtaskList'] })
            toast.success('Status Changed successfully');
        },
        onError: (error) => {
            toast.error('Failed to Changed Stutus');
        },
    });

    const { mutate: updateTitleMutation } = useMutation({
        mutationFn: async (title) => {
            const response = await API.patch(`/task/subtasks/${task?._id}`, { title });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['subtaskList'] })
            toast.success('Subtask Updated successfully')
        },
        onError: (error) => {
            console.error('Task deletion failed:', error);
            toast.error('Failed to update Subtask');
        },
    });

    const { mutate: updateDueDateMutation } = useMutation({
        mutationFn: async (dueDate) => {
            const response = await API.patch(`/task/subtasks/${task?._id}`, { dueDate });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['subtaskList'] })
            toast.success('Due date changed successfully');
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to update due date');
        }
    });

    const { mutate: updatePriorityMutation } = useMutation({
        mutationFn: async (priority) => {

            const response = await API.patch(`/task/subtasks/${task?._id}`, { priority });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['subtaskList'] })
            toast.success('Priority changed successfully');
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to update priority');
        }
    });

    const { mutate: removePriorityMutation } = useMutation({
        mutationFn: async () => {
            const response = await API.patch(`/task/subtask/${task._id}/remove-priority`);
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['subtaskList'] });
            toast.success('Priority removed successfully');
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to remove priority');
        }
    });

    const { mutate: addEstimatedTimeMutation } = useMutation({
        mutationFn: async (estimatedHours) => {
            const response = await API.patch(`/task/${task._id}/estimated-hours`, { estimatedHours });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
            toast.success('Estimated time added successfully')
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to add Estimated time');
        }
    });

    const { mutate: removeEstimatedTimeMutation } = useMutation({
        mutationFn: async (estimatedHours) => {
            const response = await API.patch(`/task/${task._id}/clear-estimated-time`);
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
            toast.success('Estimated time removed successfully')
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to remove Estimated time');
        }
    });

    const { mutate: deleteTaskMutation } = useMutation({
        mutationFn: async () => {
            const response = await API.delete(`/task/subtasks/${task._id}`);
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['subtaskList'] });
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            toast.success('Subtask deleted successfully');
            setDeleteOpen(false)
        },
        onError: (error) => {
            toast.error('Failed to delete Subtask');
            setDeleteOpen(false)
        },
    });

    const { mutate: updateDescriptionMutation } = useMutation({
        mutationFn: async (description) => {
            const response = await API.patch(`/task/subtasks/${task?._id}`, { description });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['subtaskList'] });
            toast.success('Subtask Details Updated successfully');
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to update task details');
        },
    });

    const [taskTags, setTaskTags] = useState(task?.tags || []);
    const handleSelectTag = async (tag) => {


        try {
            setTaskTags((prev) => {
                if (prev.length >= 2) {
                    toast.error("You can only add up to 2 tags.");
                    return prev;
                }
                return [...prev, tag];
            });
            setSelectedTags((prev) => {
                if (prev.length >= 2) {
                    return prev;
                }
                return [...prev, tag];
            });
            if (taskTags.length < 2) {
                await API.patch(`/tag/add`, {
                    entityId: task?._id,
                    entityType: 'Subtask',
                    tags: [tag?._id],
                });
            }
        } catch (error) {
            toast.error("Failed to add tag");
        }
    };

    const handleRemoveTag = async (tag) => {
        try {
            setTaskTags((prev) => prev.filter((t) => t._id !== tag._id));
            setSelectedTags((prev) => prev.filter((t) => t._id !== tag._id));
            await API.delete(`/tag/subtask/${task._id}/delete`, {
                data: { tagIds: [tag._id] },
            });
            // toast.success("Tag Removed Successfully");
        } catch (error) {
            toast.error("Failed to remove tag");
        }
    };
 
    return (
        <>
            <div key={task._id} className="body w-full flex gap-2 text-muted border-b border-border cursor-pointer text-sm hover:bg-primary/5 lasst:border-0" onMouseEnter={() => showMenu(task?._id)} onMouseLeave={hideMenu}>
                <div className="w-full flex gap-2 items-center border-e border-border p-2" >
                    <div className=''>
                        <Listbox
                            value={task?.status}
                            onChange={(newStatus) => subTaskstatusChangeMutation(newStatus)}
                        >
                            <ListboxButton
                                className={clsx(
                                    `relative block w-full rounded-lg bg-white/5 text-left text-sm/6 `,
                                    'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                )}
                            >
                                {color?.icon}
                            </ListboxButton>
                            <ListboxOptions
                                anchor="bottom"
                                transition
                                className={clsx(
                                    'w-[210px] rounded-xl shadow-custom border border-white/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                                    'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                                )}
                            >
                                {statusType?.map((status) => (
                                    <ListboxOption
                                        key={status.id}
                                        value={status.value}
                                        className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-lg py-1.5 px-2 select-none hover:bg-dark/5"
                                    >
                                        <div className="flex items-center gap-1.5">
                                            {status?.icon}
                                            <div className="text-xs font-medium">{status.name}</div>
                                        </div>
                                        <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible" />
                                    </ListboxOption>
                                ))}
                            </ListboxOptions>
                        </Listbox>
                    </div>
                    <div className="w-full relative" >
                        <Title
                            tags={tagList}
                            taskTags={taskTags}
                            handleSelectTag={handleSelectTag}
                            handleRemoveTag={handleRemoveTag}
                            task={task}
                            handleOpenDetails={() => setTaskOpen(true)}
                            updateTitle={updateTitleMutation}
                            hoverId={hoverId}
                            setHoverId={setHoverId}
                            setDeleteOpen={setDeleteOpen}
                        />
                    </div>
                </div>
                {/* {
                    !type &&
                    <div className="w-[16%] flex items-center border-e border-border">
                        <AssigneePopover
                            assignedTo={task?.assignedTo}
                            members={projectDetails?.team}
                            taskId={task?._id}
                            bugId={bugId}
                        />
                    </div>
                } */}
                <div className="w-[20%] flex items-center border-e border-border"><DueDate taskDate={task?.dueDate} id={task._id} handleDueDate={updateDueDateMutation} taskStatus={type} /></div>

                {/* {estimateTime && <div className="w-[16%] flex items-center border-e border-border"><EstimatedTimePopover id={task?._id} time={task?.estimatedHours} addEstimatedTime={addEstimatedTimeMutation} removeEstimatedTime={removeEstimatedTimeMutation} /></div>} */}
                <div className="w-[20%] flex items-center">
                    <PriorityDropdown priority={task?.priority} id={task._id} handleRemovePriority={removePriorityMutation} handlePriority={updatePriorityMutation} />
                </div>
            </div >
            <TaskDetailModal modalType='Subtask' menu={false} projectDetails={projectDetails} sprintDetails={sprintDetails} fetchCurrentTask={fetchCurrentTask} open={taskOpen} handleRemovePriority={removePriorityMutation} setOpen={setTaskOpen} task={task} statusType={statusType} handleStatusChange={subTaskstatusChangeMutation} handlePriority={updatePriorityMutation} timers={'timers'} member={projectDetails?.team} handleDueDate={updateDueDateMutation} addEstimatedTime={addEstimatedTimeMutation} removeEstimatedTime={removeEstimatedTimeMutation} updateTitle={updateTitleMutation} updateDescription={updateDescriptionMutation} />
            <DeleteModal title={'Are you sure you want to delete this subTask?'} open={deleteOpen} setOpen={setDeleteOpen} id={task._id} handleDeleteTask={deleteTaskMutation} />
        </>
    )
}

export default SubTaskListItem