import React from 'react'

const IconReview = (props) => {
  return (
    <svg {...props} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.1111" r="8.25" stroke="currentColor" stroke-width="1.5" />
      <path d="M10 4.11108C11.0444 4.11108 12.0708 4.38372 12.9775 4.90203C13.8843 5.42033 14.64 6.16636 15.1701 7.06632C15.7001 7.96629 15.986 8.98901 15.9995 10.0334C16.013 11.0777 15.7537 12.1075 15.2472 13.0209C14.7407 13.9343 14.0045 14.6996 13.1115 15.2413C12.2185 15.7829 11.1995 16.082 10.1554 16.1091C9.11135 16.1361 8.07829 15.8902 7.15841 15.3955C6.23853 14.9009 5.46371 14.1747 4.91056 13.2888L10 10.1111V4.11108Z" fill="currentColor" />
    </svg>

  )
}

export default IconReview