import { Switch } from '@headlessui/react'
import React, { useState } from 'react'

const CustomSwitch = ({ id, isInProject, addUser, removeUser , role}) => {
    const [enabled, setEnabled] = useState(false)

    return (
        <Switch
            checked={isInProject}
            onChange={() => !isInProject ? addUser(id , role) : removeUser(id)}
            className="group relative flex h-5 w-8 cursor-pointer rounded-full bg-[#98A1B2] p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-primary"
        >
            <span
                aria-hidden="true"
                className="pointer-events-none inline-block size-3 translate-x-0 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-3"
            />
        </Switch>
    )
}

export default CustomSwitch