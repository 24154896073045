import { X } from 'lucide-react';
import React from 'react';
import { cn, getColorFromName } from '../lib/utils';

const TagItem = ({ tag, variant = 'list', onClick, className, tagClassName, isSelected, isListMode, isDisabled }) => {
  const backgroundColor = getColorFromName(tag?.name);
  return (
    <li className={cn('border-b first:border-t-0 flex items-center gap-2 justify-between border-[#ECEDFF] py-1 last:border-b-0 !leading-[1]', className)}>
      <div
        style={{ backgroundColor }}
        className={cn("items-center gap-1 cursor-default relative group ps-2 pe-1.5 py-1 rounded-md text-white text-xs inline-flex", tagClassName, {
          "opacity-50 pointer-events-none": isDisabled,
          'cursor-pointer': isListMode
        })}
        onClick={() => (!isDisabled && isListMode) && onClick(tag)}
      >
        <span className="flex items-center gap-1">{tag.name}</span>
        {(isSelected && !isListMode) && (
          <span
            onClick={() => onClick(tag)}
            className='w-3 h-3 rounded-full bg-white absolute top-[3px] right-1  group-hover:opacity-100 opacity-0 group-hover:visible invisible  items-center justify-center cursor-pointer flex'
          >
            <X className='w-3 h-3 text-dark' />
          </span>
        )}
      </div>
    </li>
  );
};

export default TagItem;
