import React from 'react'

const IconSprintActive = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_158_797)">
        <mask id="path-1-inside-1_158_797" fill="white">
          <path d="M6.86303 1.15512C4.52196 2.26403 2.60257 4.10072 1.39171 6.39071C0.180837 8.68071 -0.256499 11.3011 0.145052 13.8602L1.79404 13.6014C1.44834 11.3983 1.82484 9.14241 2.86728 7.17095C3.90972 5.19948 5.56213 3.61827 7.57756 2.6636L6.86303 1.15512Z" />
        </mask>
        <path d="M6.86303 1.15512C4.52196 2.26403 2.60257 4.10072 1.39171 6.39071C0.180837 8.68071 -0.256499 11.3011 0.145052 13.8602L1.79404 13.6014C1.44834 11.3983 1.82484 9.14241 2.86728 7.17095C3.90972 5.19948 5.56213 3.61827 7.57756 2.6636L6.86303 1.15512Z" stroke="#D7D9DD" stroke-width="3" mask="url(#path-1-inside-1_158_797)" />
        <mask id="path-2-inside-2_158_797" fill="white">
          <path d="M0.559919 15.6228C1.27644 17.8854 2.64782 19.8848 4.50063 21.368C6.35345 22.8512 8.60448 23.7517 10.9691 23.9556C13.3337 24.1595 15.7056 23.6577 17.785 22.5135C19.8644 21.3693 21.5578 19.6342 22.6511 17.5277C23.7443 15.4211 24.1884 13.0376 23.927 10.6787C23.6657 8.31973 22.7107 6.09126 21.1829 4.27505C19.655 2.45885 17.6229 1.1365 15.3435 0.47521C13.0641 -0.186079 10.6399 -0.1566 8.37722 0.559919L8.91543 2.25949C10.8419 1.64942 12.9061 1.62432 14.8468 2.18737C16.7876 2.75041 18.5178 3.87632 19.8186 5.4227C21.1195 6.96908 21.9326 8.86648 22.1551 10.875C22.3776 12.8835 21.9995 14.9128 21.0687 16.7065C20.1379 18.5001 18.696 19.9774 16.9256 20.9516C15.1551 21.9258 13.1356 22.3531 11.1222 22.1795C9.10893 22.0059 7.19232 21.2391 5.61476 19.9763C4.0372 18.7134 2.86956 17.0111 2.25949 15.0846L0.559919 15.6228Z" />
        </mask>
        <path d="M0.559919 15.6228C1.27644 17.8854 2.64782 19.8848 4.50063 21.368C6.35345 22.8512 8.60448 23.7517 10.9691 23.9556C13.3337 24.1595 15.7056 23.6577 17.785 22.5135C19.8644 21.3693 21.5578 19.6342 22.6511 17.5277C23.7443 15.4211 24.1884 13.0376 23.927 10.6787C23.6657 8.31973 22.7107 6.09126 21.1829 4.27505C19.655 2.45885 17.6229 1.1365 15.3435 0.47521C13.0641 -0.186079 10.6399 -0.1566 8.37722 0.559919L8.91543 2.25949C10.8419 1.64942 12.9061 1.62432 14.8468 2.18737C16.7876 2.75041 18.5178 3.87632 19.8186 5.4227C21.1195 6.96908 21.9326 8.86648 22.1551 10.875C22.3776 12.8835 21.9995 14.9128 21.0687 16.7065C20.1379 18.5001 18.696 19.9774 16.9256 20.9516C15.1551 21.9258 13.1356 22.3531 11.1222 22.1795C9.10893 22.0059 7.19232 21.2391 5.61476 19.9763C4.0372 18.7134 2.86956 17.0111 2.25949 15.0846L0.559919 15.6228Z" stroke="#1290E0" stroke-width="3" mask="url(#path-2-inside-2_158_797)" />
        <path d="M16.4067 12.673L16.387 12.6853C16.3526 12.7074 16.328 12.7221 16.3108 12.732L9.96292 16.6371L9.86953 16.6936L9.85233 16.7108L9.79581 16.7354C9.65818 16.7968 9.48861 16.8189 9.37556 16.8189C8.87667 16.8189 8.44168 16.4355 8.3999 15.9661V8.14858C8.3999 8.06502 8.42694 7.99866 8.45151 7.95197C8.5449 7.52435 8.94057 7.19995 9.39522 7.19995C9.52547 7.19995 9.66064 7.22698 9.77369 7.27614L9.79581 7.28597L9.81547 7.29825L16.242 11.0461C16.4264 11.1542 16.4264 11.1542 16.4485 11.1714L16.4509 11.1739C16.6279 11.3189 16.7458 11.5204 16.7852 11.744C16.795 11.8005 16.7999 11.8546 16.7999 11.9062C16.7999 12.2085 16.6524 12.496 16.4067 12.673Z" fill="#1290E0" />
      </g>
      <defs>
        <clipPath id="clip0_158_797">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSprintActive