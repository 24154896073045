import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDGTSFKcZqlnO4Ufxtswe5HP9xu-sH1CLI",
    authDomain: "elaunch-nexus.firebaseapp.com",
    projectId: "elaunch-nexus",
    storageBucket: "elaunch-nexus.firebasestorage.app",
    messagingSenderId: "974900353670",
    appId: "1:974900353670:web:7c42ebfe3a127e18c1cf07",
    measurementId: "G-NCGM2H2H1E"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };