import React, { useEffect, useState } from 'react';
import CustomSwitch from './ui/Switch';
import { getColorFromName } from '../lib/utils';
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/react';
import clsx from 'clsx';
import { ChevronDown } from 'lucide-react';
import * as Tooltip from "@radix-ui/react-tooltip";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../services/api';
import { toast } from 'react-toastify';

const rolesWithDescriptions = [
  {
    role: 'Admin',
    descriptions: [
      'Full access to all project settings, users, and data.',
      'Responsible for managing users, roles, permissions, and configurations.',
      'Can create, update, delete, and assign tasks, projects, and other resources.'
    ]
  },
  {
    role: 'Project Manager',
    descriptions: [
      'Manages the project’s progress and oversees the team.',
      'Can assign tasks, set priorities, manage sprints, and monitor deadlines.',
      'Has access to all tasks and reports for the project.'
    ]
  },
  {
    role: 'Team Lead',
    descriptions: [
      'Oversees the work of a specific team or group within the project.',
      'Can assign tasks to team members and monitor their progress.',
      'Limited permissions to manage certain project aspects (e.g., within their scope).'
    ]
  },
  {
    role: 'Developer',
    descriptions: [
      'Works on tasks assigned to them.',
      'Can update task statuses (e.g., mark as complete, in progress).',
      'Access to project boards and their assigned tasks.'
    ]
  },
  {
    role: 'Tester/QA',
    descriptions: [
      'Responsible for testing tasks, identifying bugs, and reporting issues.',
      'Can update the status of bugs and tasks related to testing.',
      'Limited permissions to edit tasks or bugs.'
    ]
  },
  {
    role: 'Designer',
    descriptions: [
      'Works on tasks related to design, such as UI/UX or graphic design.',
      'Can update the status of their tasks and provide deliverables.'
    ]
  },
  {
    role: 'Observer',
    descriptions: [
      'Read-only access to the project for monitoring purposes (e.g., external auditors or stakeholders).',
      'Cannot interact with tasks or project settings.'
    ]
  }
];

const ShareUserItem = ({ user, addUser, removeUser, activeRole, projectId }) => {
  const [role, setRole] = useState(activeRole ?? rolesWithDescriptions[0].role);

  useEffect(() => {
    let activeRole;
    if (user?.role == "None") {
      activeRole = rolesWithDescriptions.find(({ role }) => role === 'Developer');
    } else {
      activeRole = rolesWithDescriptions.find(({ role }) => user?.role === role);
    }
    setRole(activeRole?.role)
  }, [user])

  const queryClient = useQueryClient();

  const { mutate: updateRoleMutation } = useMutation({
    mutationFn: async (role) => {
      const response = await API.patch(`/project/${projectId}/team/${user?.userId}`, { role });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['roles'] })
      toast.success('Role Updated successfully')
    },
    onError: (error) => {
      console.error('Role update failed:', error);
      toast.error('Failed to update Roles');
    },
  });

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-1.5">
        <div
          className="h-6 w-6 text-white rounded-full flex items-center justify-center font-medium uppercase text-xs"
          style={{ background: `${getColorFromName(user.name)}` }}
        >
          {user.name
            .split(' ')
            .slice(0, 2)
            .map((name) => name.slice(0, 1).toUpperCase())
            .join('')}
        </div>
        <p className="text-[#667085] text-sm">{user.name}</p>
      </div>
      <div className='flex items-center gap-2'>
        <div>
          <Tooltip.Provider>
            <Listbox value={role} onChange={(newRole) => {
              setRole(newRole);
              if (user?.isInProject) {
                updateRoleMutation(newRole);
              }
            }}>
              <ListboxButton
                className={clsx(
                  `relative w-[120px] flex items-center gap-2 justify-between rounded-lg bg-white/5 text-left text-[10px] border border-border py-1.5 px-2`,
                  'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                )}
              >
                {role}
                <span><ChevronDown className='w-3 h-3' /></span>
              </ListboxButton>
              <ListboxOptions
                anchor="bottom end"
                transition
                className={clsx(
                  'rounded-xl w-[150px] z-10 shadow-custom border border-white/5 bg-white p-1',
                  'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                )}
              >
                {rolesWithDescriptions.map(({ role, descriptions }, index) => (
                  <Tooltip.Root key={index} delayDuration={0}>
                    <Tooltip.Trigger asChild>
                      <ListboxOption
                        value={role}
                        className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-lg py-1.5 px-2 select-none hover:bg-dark/5"
                      >
                        <div className="text-xs font-medium">{role}</div>
                        <svg
                          className="invisible w-4 h-4 group-data-[selected]:visible"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.704 5.296a1 1 0 00-1.414 0L8 12.586l-3.29-3.29a1 1 0 00-1.415 1.415l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.415z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </ListboxOption>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content align='center' side='left' className="bg-white border-border border shadow-xl max-w-[200px] rounded-md p-2 z-[999] text-dark" sideOffset={5}>
                        <ul className="list-disc pl-4">
                          {descriptions.map((desc, i) => (
                            <li key={i} className='text-xs font-medium mb-0.5'>{desc}</li>
                          ))}
                        </ul>
                        <Tooltip.Arrow className="TooltipArrow fill-gray-300" />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                ))}
              </ListboxOptions>
            </Listbox>
          </Tooltip.Provider>
        </div>
        <CustomSwitch
          id={user?.userId}
          role={role}
          isInProject={user?.isInProject}
          addUser={addUser}
          removeUser={removeUser}
        />
      </div>
    </div>
  );
};

export default ShareUserItem;
