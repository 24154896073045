import {
  Check,
  CheckSquare,
  ChevronDown,
  Code,
  Heading1,
  Heading2,
  Heading3,
  ListOrdered,
  TextIcon,
  TextQuote
} from 'lucide-react'
import { EditorBubbleItem, useEditor } from 'novel'

import { Button } from '../../../common/ui/Button'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'

const items = [
  {
    name: 'Text',
    icon: TextIcon,
    command: editor => editor?.chain().focus().clearNodes().run(),
    isActive: editor =>
      editor
        ? editor.isActive('paragraph') &&
        !editor.isActive('bulletList') &&
        !editor.isActive('orderedList')
        : false
  },
  {
    name: 'Heading 1',
    icon: Heading1,
    command: editor =>
      editor?.chain().focus().clearNodes().toggleHeading({ level: 1 }).run(),
    isActive: editor =>
      editor ? editor.isActive('heading', { level: 1 }) : false
  },
  {
    name: 'Heading 2',
    icon: Heading2,
    command: editor =>
      editor?.chain().focus().clearNodes().toggleHeading({ level: 2 }).run(),
    isActive: editor =>
      editor ? editor.isActive('heading', { level: 2 }) : false
  },
  {
    name: 'Heading 3',
    icon: Heading3,
    command: editor =>
      editor?.chain().focus().clearNodes().toggleHeading({ level: 3 }).run(),
    isActive: editor =>
      editor ? editor.isActive('heading', { level: 3 }) : false
  },
  {
    name: 'To-do List',
    icon: CheckSquare,
    command: editor =>
      editor?.chain().focus().clearNodes().toggleTaskList().run(),
    isActive: editor => (editor ? editor.isActive('taskItem') : false)
  },
  {
    name: 'Bullet List',
    icon: ListOrdered,
    command: editor =>
      editor?.chain().focus().clearNodes().toggleBulletList().run(),
    isActive: editor => (editor ? editor.isActive('bulletList') : false)
  },
  {
    name: 'Numbered List',
    icon: ListOrdered,
    command: editor =>
      editor?.chain().focus().clearNodes().toggleOrderedList().run(),
    isActive: editor => (editor ? editor.isActive('orderedList') : false)
  },
  {
    name: 'Quote',
    icon: TextQuote,
    command: editor =>
      editor?.chain().focus().clearNodes().toggleBlockquote().run(),
    isActive: editor => (editor ? editor.isActive('blockquote') : false)
  },
  {
    name: 'Code',
    icon: Code,
    command: editor =>
      editor?.chain().focus().clearNodes().toggleCodeBlock().run(),
    isActive: editor => (editor ? editor.isActive('codeBlock') : false)
  }
]

export const NodeSelector = ({ open, onOpenChange }) => {
  const { editor } = useEditor()
  if (!editor) return null
  const activeItem = items.filter(item => item.isActive(editor)).pop() ?? {
    name: 'Multiple'
  }

  return (
    <Popover>
      <PopoverButton className="block text-sm/6 font-semibold focus:outline-none">
        <Button size='sm' variant='ghost' className='gap-2'>
          <span className='whitespace-nowrap text-sm'>{activeItem.name}</span>
          <ChevronDown className='h-4 w-4' />
        </Button>
      </PopoverButton>
      <PopoverPanel
        transition
        anchor="bottom start"
        className="divide-y divide-white/5 border border-black/50 mt-2 rounded-md bg-white shadow-md text-xs transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
      >
        {items.map(item => (
          <EditorBubbleItem
            key={item.name}
            onSelect={editor => {
              item.command(editor)
              onOpenChange(false)
            }}
            className='flex cursor-pointer items-center justify-between rounded-sm px-2 py-1 text-xs font-semibold hover:bg-accent'
          >
            <div className='flex items-center space-x-2'>
              <div className='rounded-sm border p-1'>
                <item.icon className='h-3 w-3' />
              </div>
              <span>{item.name}</span>
            </div>
            {activeItem.name === item.name && <Check className='h-3 w-3' />}
          </EditorBubbleItem>
        ))}
      </PopoverPanel>
    </Popover>
  )
}

