import * as React from "react"

import { EyeClosedIcon, EyeOpenIcon } from "@radix-ui/react-icons"
import { cn } from "../lib/utils"

const PasswordInput = (
  ({ className, lable, type, ...props }) => {
    const [inputType, setInputType] = React.useState("password")
    return (
      <div className="">
        <label className="block text-base font-medium text-dark mb-2">
          {lable}
        </label>
        <div className="relative">
          <input
            type={inputType}
            autoComplete="new-password"
            className={cn(
              "flex w-full rounded-md border border-border bg-[#FBFBFF] px-4 py-3.5 text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
              className
            )}
            // ref={ref}
            {...props}
          />
          <span onClick={() => setInputType(inputType === "text" ? "password" : "text")} className="icon-wrapper absolute top-[50%] right-4 cursor-pointer -translate-y-1/2">
            {
              inputType === "text" ? <EyeClosedIcon /> : <EyeOpenIcon />
            }
          </span>
        </div>
      </div>
    )
  }
)
PasswordInput.displayName = "Input"

export default PasswordInput
