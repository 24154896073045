import { getMessaging, getToken, onMessage } from "firebase/messaging";
import app, { messaging } from "./firebase-config"; // Your Firebase configuration

// const messaging = getMessaging(app);

// Request user permission and get token
export const requestNotificationPermission = async () => {
    try {
        const permission = await Notification.requestPermission();
        
        if (permission === "granted") {
            const token = await getToken(messaging, {
                vapidKey: "BD1KQTMYaSfVVxiL-I-cjAenvSqDBopmFFHGRpbhz_rMgHb4O7KP9XrX7yVedBqJtHDa1zDJ2zPU1cWQuMYVz-Q",
            });
            
            return token;
        } else {
            console.error("Notification permission not granted.");
        }
    } catch (error) {
        console.error("Error in getting token:", error);
    }
};

// Listener for foreground messages
export const onForegroundMessage = (callback) => {
    onMessage(messaging, (payload) => {
        if (callback) callback(payload);
    });
};
