import { useEffect, useRef } from 'react'
import { Check, Trash } from 'lucide-react'
import { useEditor } from 'novel'
import { cn } from '../../../lib/utils'
import { Button } from '../../../common/ui/Button'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'

export function isValidUrl(url) {
  try {
    new URL(url)
    return true
  } catch (_e) {
    return false
  }
}
export function getUrlFromString(str) {
  if (isValidUrl(str)) return str
  try {
    if (str.includes('.') && !str.includes(' ')) {
      return new URL(`https://${str}`).toString()
    }
  } catch (_e) {
    return null
  }
}


export const LinkSelector = ({ open, onOpenChange }) => {
  const inputRef = useRef(null)
  const { editor } = useEditor()

  // Autofocus on input by default
  useEffect(() => {
    inputRef.current?.focus()
  })
  if (!editor) return null

  return (
    <Popover>
      <PopoverButton className="block text-sm/6 font-semibold focus:outline-none">
        <Button
          size='sm'
          variant='ghost'
          className='gap-2 rounded-none border-none'
        >
          <p className='text-base'>↗</p>
          <p
            className={cn('underline decoration-stone-400 underline-offset-4', {
              'text-blue-500': editor.isActive('link')
            })}
          >
            Link
          </p>
        </Button>
      </PopoverButton>
      <PopoverPanel
        transition
        anchor="bottom start"
        className="divide-y divide-white/5 border border-black/50 mt-2 rounded-md bg-white shadow-md text-xs transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
      >
        <form
          onSubmit={e => {
            const target = e.currentTarget
            e.preventDefault()
            const input = target[0]
            const url = getUrlFromString(input.value)
            if (url) {
              editor.chain().focus().setLink({ href: url }).run()
              onOpenChange(false)
            }
          }}
          className='flex p-1'
        >
          <input
            ref={inputRef}
            type='text'
            placeholder='Paste a link'
            className='flex-1 bg-background p-1 text-sm outline-none'
            defaultValue={editor.getAttributes('link').href || ''}
          />
          {editor.getAttributes('link').href ? (
            <Button
              size='icon'
              variant='outline'
              type='button'
              className='flex h-8 items-center rounded-sm p-1 text-red-600 transition-all hover:bg-red-100 dark:hover:bg-red-800'
              onClick={() => {
                editor.chain().focus().unsetLink().run()
                if (inputRef.current) {
                  inputRef.current.value = ''
                }
                onOpenChange(false)
              }}
            >
              <Trash className='h-4 w-4' />
            </Button>
          ) : (
            <Button size='icon' className='h-8'>
              <Check className='h-4 w-4' />
            </Button>
          )}
        </form>
      </PopoverPanel>
    </Popover>
  )
}


