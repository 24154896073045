import { Dialog, DialogPanel, DialogTitle, Select } from '@headlessui/react'
import { ChevronRightIcon, X } from 'lucide-react'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Input } from '../../common/Input'
import { Button } from '../../common/ui/Button'
import { roleTypes } from '../../staticData/data'
import Buttonloader from '../../common/ui/ButtonLoader'
import { useForm } from 'react-hook-form'

const AddUserModal = ({ isOpen, setIsOpen, newUser, setNewUser, handleSubmit, loader }) => {
    const { register, handleSubmit: handleFormSubmit, formState: { errors }, reset, } = useForm();

    const close = () => {
        setIsOpen(false);
        setNewUser({ name: '', email: '', role: '' });
        reset();
    };

    const onSubmit = (data) => {
        setNewUser(data);
        handleSubmit(data);
    };

    return (
        <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => close()}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel
                        transition
                        className="w-full max-w-md rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 overflow-hidden"
                    >
                        <DialogTitle
                            as="h3"
                            className="text-base/7 font-medium text-dark bg-forground px-4 py-2 flex justify-between border-b border-border"
                        >
                            <div className="">
                                <p className="text-xl font-bold">Add Employee</p>
                            </div>
                            <span className="cursor-pointer" onClick={() => close()}>
                                <X className="text-dark/50" />
                            </span>
                        </DialogTitle>
                        <div className="">
                            <form onSubmit={handleFormSubmit(onSubmit)}>
                                <div className="p-8 py-5">
                                    <div className="mb-3">
                                        <Input
                                            placeholder="User Name"
                                            {...register('name', { required: 'User Name is required' })}
                                            className={`${errors.name ? 'border-red-500' : ''
                                                }`}
                                        />
                                        {errors.name && (
                                            <p className="text-red-500 text-xs mt-1">{errors.name.message}</p>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <Input
                                            placeholder="User Email"
                                            {...register('email', {
                                                required: 'Email is required',
                                                pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                    message: 'Invalid email address',
                                                },
                                            })}
                                            className={`${errors.email ? 'border-red-500' : ''
                                                }`}
                                        />
                                        {errors.email && (
                                            <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <Input
                                            placeholder="User Password"
                                            type="password"
                                            {...register('password', { required: 'Password is required' })}
                                            className={`${errors.password ? 'border-red-500' : ''
                                                }`}
                                        />
                                        {errors.password && (
                                            <p className="text-red-500 text-xs mt-1">{errors.password.message}</p>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <Select
                                            className={`w-full rounded-md border border-border bg-[#FBFBFF] px-4 py-3.5 text-sm transition-colors ${errors.role ? 'border-red-500' : ''
                                                }`}
                                            {...register('role', { required: 'Role is required' })}
                                        >
                                            <option value="">Select Role</option>
                                            {roleTypes?.map((user) => (
                                                <option key={user.id} value={user.value}>
                                                    {user.name}
                                                </option>
                                            ))}
                                        </Select>
                                        {errors.role && (
                                            <p className="text-red-500 text-xs mt-1">{errors.role.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="py-4 px-6 border-t border-border flex items-center justify-end gap-2">
                                    <Button variant="outline" type="button" onClick={() => close()}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" disabled={loader}>
                                        {loader ? <Buttonloader /> : 'Add Employee'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
};

export default AddUserModal;