import React from 'react'

const IconColse = (props) => {
  return (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="8.25" stroke="currentColor" stroke-width="1.5" />
      <path d="M10 4C8.81331 4 7.65328 4.35189 6.66658 5.01118C5.67989 5.67047 4.91085 6.60754 4.45673 7.7039C4.0026 8.80026 3.88378 10.0067 4.11529 11.1705C4.3468 12.3344 4.91825 13.4035 5.75736 14.2426C6.59648 15.0818 7.66557 15.6532 8.82946 15.8847C9.99335 16.1162 11.1997 15.9974 12.2961 15.5433C13.3925 15.0891 14.3295 14.3201 14.9888 13.3334C15.6481 12.3467 16 11.1867 16 10C16 8.4087 15.3679 6.88258 14.2426 5.75736C13.1174 4.63214 11.5913 4 10 4ZM12.32 8.885L9.32 11.385C9.22413 11.4653 9.10163 11.5068 8.97667 11.5013C8.85171 11.4957 8.73337 11.4435 8.645 11.355L7.645 10.355C7.55085 10.2608 7.49796 10.1331 7.49796 10C7.49796 9.86685 7.55085 9.73915 7.645 9.645C7.73915 9.55085 7.86685 9.49795 8 9.49795C8.13315 9.49795 8.26085 9.55085 8.355 9.645L9.03 10.325L11.68 8.115C11.7306 8.07298 11.7889 8.04132 11.8517 8.02185C11.9145 8.00237 11.9805 7.99545 12.046 8.00149C12.1114 8.00752 12.1751 8.0264 12.2332 8.05702C12.2914 8.08765 12.343 8.12944 12.385 8.18C12.427 8.23056 12.4587 8.2889 12.4782 8.35169C12.4976 8.41448 12.5045 8.4805 12.4985 8.54596C12.4925 8.61143 12.4736 8.67506 12.443 8.73323C12.4123 8.79141 12.3706 8.84298 12.32 8.885Z" fill="currentColor" />
    </svg>

  )
}

export default IconColse