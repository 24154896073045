import React from 'react'

const IconComplete = (props) => {
  return (
    <svg {...props} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.5555" r="8.25" stroke="currentColor" stroke-width="1.5" />
      <circle cx="10" cy="10.5555" r="6" fill="currentColor" />
    </svg>

  )
}

export default IconComplete