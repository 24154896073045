import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import newLogingif from '../assets/gif/Newlogin.gif';
import bgImg from '../assets/images/bg.jpg';
import newLogo from '../assets/images/New_Logo.png';
import { Input } from '../common/Input';
import PasswordInput from '../common/PasswordInput';
import { Button } from '../common/ui/Button';
import { useAuth } from '../context/AuthContext';
import API from '../services/api';
import Buttonloader from '../common/ui/ButtonLoader';
import { requestNotificationPermission } from '../firebase-notifications';
import { useForm, Controller } from 'react-hook-form';

const Login = ({ onLogin }) => {
  const { setUser } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loader, setLoader] = useState(false);

  const { control, handleSubmit, formState: { errors }, reset, watch } = useForm()

  const navigate = useNavigate();

  const getToken = async () => {
    const tokens = await requestNotificationPermission()
    await API.post('/auth/users/save-push-token', { pushToken: tokens })
  }

  const onSubmit = async (datas) => {
    setLoader(true);
    try {
      const { data } = await API.post('/auth/login', { email: datas.email, password: datas.password });
      localStorage.setItem('token', data?.token);
      localStorage.setItem('user', JSON.stringify(data?.data));
      setUser(data?.data);
      toast.success('Login successful!');
      onLogin();
      navigate('/home');
      await getToken()
    } catch (err) {
      toast.error("Invalid login details");
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="auth-wrapper">
      <main>
        <section className="h-screen overflow-hidden">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-12 h-full">
            <div className={`flex flex-col items-center justify-center bg-cover bg-center xl:col-span-6 bg-blend-overlay max-lg:hidden`} style={{ backgroundImage: `url(${bgImg})` }}>
              <div className="pt-16">
                <img src={newLogo} alt="Logo" />
              </div>
              <img src={newLogingif} alt="Login gif" className="max-h-[85%] max-w-[85%]" />
            </div>
            <div className="h-screen xl:col-span-6 overflow-auto">
              <div className="flex items-center justify-center px-6 lg:px-10 2xl:px-[100px] py-6 lg:py-10 min-h-screen">
                <div className="w-full max-w-[600px]">
                  <div className="mb-10">
                    <h1 className="text-dark text-4xl font-semibold">Welcome back !!!</h1>
                    <p className="text-dark/80 py-1.5 text-lg font-normal">Login your Account</p>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                    <div className="mb-3">
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: 'Email is Required',
                          pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: 'Invalid Email Address'
                          }
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            lable="Your Email"
                            placeholder="Enter your Email Address"
                          />
                        )}
                      />
                      {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
                    </div>
                    <div className="mb-3">
                      <Controller
                        name="password"
                        control={control}
                        rules={{
                          required: 'Password is Required',
                          // minLength: {
                          //   value: 6,
                          //   message: 'Password must be at least 6 characters'
                          // }
                        }}
                        render={({ field }) => (
                          <PasswordInput
                            {...field}
                            lable="Your Password"
                            placeholder="Enter your password"
                            type="password"
                          />
                        )}
                      />
                      {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password.message}</p>}
                    </div>

                    <Button type="submit" className="w-full p-4 h-auto font-semibold mt-10" disabled={loader}>
                      {loader ? <Buttonloader /> : 'Login'}
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Login;