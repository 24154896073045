import React, { useState } from 'react'
import { getColorFromName } from '../lib/utils';
import moment from 'moment';
import { Pencil, Trash2 } from 'lucide-react';
import { Button } from './ui/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../services/api';
import { toast } from 'react-toastify';
import { Input } from './Input';
import { useAuth } from '../context/AuthContext';

const ReplyCard = ({ activity, taskId }) => {
    const queryClient = useQueryClient()
    const { user } = useAuth();
    const [hoverId, setHoverId] = useState();
    const [editMode, setEditMode] = useState(false);
    const [newComment, setNewComment] = useState(activity?.content);

    const showMenu = (id) => setHoverId(id);
    const hideMenu = () => setHoverId('');

    const { mutate: deleteReplyMutation } = useMutation({
        mutationFn: async () => {
            const response = await API.delete(`/task/activities-comments/${activity._id}`);
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['fetchActivityComments', taskId] });
            toast.success('Reply deleted successfully');
        },
        onError: (error) => {
            console.error('Task deletion failed:', error);
            toast.error('Failed to delete Reply');
        },
    });

    const { mutate: editReplyMutation } = useMutation({
        mutationFn: async () => {
            const response = await API.patch(`/task/activities-comments/${activity._id}`, { content: newComment });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['fetchActivityComments', taskId] });
            setEditMode(false);
            toast.success('reply edited successfully');
        },
        onError: (error) => {
            console.error('Task deletion failed:', error);
            toast.error('Failed to edit reply');
        },
    });

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            editReplyMutation();
            // setEditMode(false)
        }
    };
    return (
        <div className="bg-white border border-secondary rounded-lg" onMouseEnter={() => showMenu(activity?._id)} onMouseLeave={hideMenu}>
            <div className="p-2.5 flex flex-col gap-2">
                <div className="flex item-center gap-2 justify-between">
                    <div className="flex item-center gap-2">
                        <div
                            className={`h-6 w-6 text-white flex items-center justify-center rounded-full text-[10px] p-1 font-medium border border-border`}
                            style={{ background: getColorFromName(activity?.userId?.name) }}
                        >
                            {activity?.userId?.name
                                ?.split(' ')
                                .slice(0, 2)
                                .map((name) => name.slice(0, 1).toUpperCase())}
                        </div>
                        <p className='text-sm/6'>{activity?.userId?.name}</p>
                    </div>
                    <div className="">
                        {
                            (hoverId && activity?.userId?._id === user?._id) ?
                                <div className="flex items-center gap-1.5">
                                    <Button
                                        className="border border-border p-0 h-6 w-6 rounded-md bg-white text-dark/50 hover:bg-white"
                                        onClick={(e) => {
                                            setEditMode(true);
                                        }}
                                    >
                                        <Pencil className="h-4 w-4" />
                                    </Button>
                                    <Button
                                        className="border border-border p-0 h-6 w-6 rounded-md bg-white text-dark/50 hover:bg-white"
                                        onClick={(e) => {
                                            deleteReplyMutation()
                                        }}
                                    >
                                        <Trash2 className='h-4 w-4 text-red-500' />
                                    </Button>
                                </div> :
                                <span className='text-xs text-dark/50'>{moment(activity?.createdAt).format('ddd, DD MMM hh:mm:ss A')}</span>
                        }
                    </div>
                </div>
                {
                    editMode ?
                        <Input
                            className=" rounded-md px-4 py-2 focus:outline-none focus:ring-dark/10 pe-16"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            onKeyPress={handleKeyPress}
                        /> :
                        <p className='text-sm'>{activity.content}</p>
                }
            </div>
            {
                editMode &&
                <div className="p-4 py-2 flex items-center justify-end gap-2 border-t border-secondary">
                    <Button className='h-auto p-2 px-4 bg-gray-50 shadow-none text-dark hover:bg-gray-100' onClick={() => setEditMode(false)}>Cancel</Button>
                    <Button className='h-auto p-2 px-4' onClick={() => editReplyMutation()}>Send</Button>
                </div>
            }
        </div>
    )
}

export default ReplyCard