import { Dialog, DialogPanel, DialogTitle, Select, Switch } from '@headlessui/react'
import { ChevronRightIcon, Search, X } from 'lucide-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Input } from '../../common/Input'
import { Button } from '../../common/ui/Button'
import { roleTypes } from '../../staticData/data'
import ShareUserItem from '../../common/ShareUserItem'
import API from '../../services/api'
import { useQuery, useQueryClient } from '@tanstack/react-query'

const ShareModal = ({ isOpen, setIsOpen, project }) => {
    const queryClient = useQueryClient()
    const [searchQuery, setSearchQuery] = useState('');

    const close = () => {
        setIsOpen(false);
    }

    const fetchAllMember = async () => {
        try {
            const { data } = await API.get(`/project/${project?._id}/users`);
            return data
        } catch (err) {
            console.error(err.message);
            return [];
        }
    };

    // const addUser = async (id, role) => {
    //     try {
    //         const { data } = await API.post(`/project/${project?._id}/users`, { userId: id, role });
    //         queryClient.invalidateQueries({ queryKey: ['fetchAllMember'] });
    //         return data
    //     } catch (err) {
    //         console.error(err.message);
    //         return [];
    //     }
    // }

    const addUser = async (id, role) => {
        try {
            const { data } = await API.post(`/project/${project?._id}/team`, { userId: id, role });
            queryClient.invalidateQueries({ queryKey: ['fetchAllMember'] });
            return data
        } catch (err) {
            console.error(err.message);
            return [];
        }
    }

    const removeUser = async (id) => {
        try {
            const { data } = await API.delete(`/project/${project?._id}/team/${id}`);
            queryClient.invalidateQueries({ queryKey: ['fetchAllMember'] });
            return data
        } catch (err) {
            console.error(err.message);
            return [];
        }
    }

    const { data: allMembers } = useQuery({
        queryKey: ['fetchAllMember', project?._id],
        queryFn: fetchAllMember,
        enabled: !!project?._id,
    });

    const filteredMembers = allMembers?.filter((member) =>
        member.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(false)}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel
                        transition
                        className="w-full max-w-lg rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 overflow-hidden"
                    >
                        <DialogTitle as="h3" className="text-base/7 font-medium text-dark  px-4 py-2 flex justify-between">
                            <div className="">
                                <p className='text-xl font-semibold'>Share this Project</p>
                            </div>
                            <span className='cursor-pointer' onClick={() => close()}><X className='text-dark/50' /></span>
                        </DialogTitle>
                        <div className="px-4 pb-4">
                            <p className='text-sm pb-2.5'>Sharing <span className='text-primary font-semibold'>{project?.name}</span> project with all views</p>
                            <div className="py-5 bg-[#F9FAFB] rounded-lg">
                                <div className="px-5 mb-2">
                                    <div className="">
                                        <Input
                                            placeholder="Invite by name or email"
                                            sufix={<Search className='h-4 w-4 text-gray-400' />}
                                            className='bg-white'
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                    {/* <p className='text-base text-[#98A1B2] py-1.5'>All Member</p> */}
                                </div>
                                <div className="px-5 flex flex-col gap-3 h-[250px] overflow-auto pt-2.5">
                                    {
                                        filteredMembers?.map((member) => (
                                            <ShareUserItem key={member._id} user={member} addUser={addUser} removeUser={removeUser} projectId={project?._id} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export default ShareModal