import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const DueDate = ({ taskDate, handleDueDate, className , taskStatus }) => {
    const [dueDate, setDueDate] = useState(null);
    const [relativeDate, setRelativeDate] = useState('');
    const [dateClass, setDateClass] = useState('text-black');

    useEffect(() => {
        if (taskDate) {
            const date = moment(taskDate, 'YYYY-MM-DD').toDate();
            setDueDate(date);
            updateRelativeDate(date);
        }
    }, [taskDate]);


    useEffect(() => {
        if (taskStatus == 'Done' || taskStatus == 'Complete' || taskStatus == 'Closed') {
            setDateClass('text-black'); 
        }
    }, [taskStatus]);

    const handleDateChange = (date) => {
        setDueDate(date);
        updateRelativeDate(date);
        if (handleDueDate) {
            handleDueDate(moment(date).format('YYYY-MM-DD'));
        }
    };

    const updateRelativeDate = (date) => {
        const today = moment().startOf('day');
        const selectedDate = moment(date).startOf('day');

        if (selectedDate.isSame(today, 'day')) {
            setRelativeDate('Today');
            setDateClass('text-[#cf6e18]');
        } else if (selectedDate.isSame(today.clone().add(1, 'day'), 'day')) {
            setRelativeDate('Tomorrow');
            setDateClass('text-black'); // Adding green text for tomorrow
        } else if (selectedDate.isSame(today.clone().subtract(1, 'day'), 'day')) {
            setRelativeDate('Yesterday');
            setDateClass('text-red-600');
        } else if (selectedDate.isBefore(today, 'day')) {
            setRelativeDate(moment(date).format('DD MMM YYYY'));
            setDateClass('text-red-600');
        } else {
            setRelativeDate(moment(date).format('DD MMM YYYY'));
            setDateClass('text-black');
        }
    };

    return (
        <div className={`w-full ${className}`}>
            <DatePicker
                value={relativeDate}
                selected={dueDate}
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
                onChange={handleDateChange}
                dateFormat="dd MMM yyyy"
                minDate={moment(Date.now()).format("yyyy-MM-DD")}
                className={`w-full ${dateClass}`} // Apply the dynamic class here
            />
        </div>
    );
};

export default DueDate;
