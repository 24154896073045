import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TaskList from '../components/dashboard/TaskList';
import AddProjectModal from '../components/modal/AddProjectModal';
import Sidebar from '../components/Sidebar';
import API from '../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import ChangePasswordModal from '../components/modal/ChangePasswordModal';

const Dashboard = ({ setIsAuthenticated }) => {
  const { logout } = useAuth()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [passModal, setPassModal] = useState(false)
  const [role, setRole] = useState();
  const [activeSprint, setActiveSprint] = useState();
  const [projects, setProjects] = useState([]);
  const [newProject, setNewProject] = useState({ name: '', description: '', startDate: '', endDate: '', createdBy: '', team: [] });
  const fetchProjects = async () => {
    try {
      const { data } = await API.get('/project');
      setProjects(data);
    } catch (err) {
      console.error(err.message);
      logout()
    }
  };

  useEffect(() => {
    setRole(localStorage.getItem('role'));
    fetchProjects();
  }, []);

  const handleAddProject = async (data) => {
    try {
      await API.post('/project', data);
      setProjects([...projects, data]);
      setNewProject({ name: '', description: '', startDate: '', endDate: '', createdBy: '', team: [] });
      setOpen(false);
      toast.success('Project added successfully')
    } catch (err) {
      if (err.status === 401) {
        logout()
      } else {
        const errorMessage = Object.entries(err.response.data.errors)[0][1]
        toast.error(errorMessage)
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false)
  };

  const [projectsList, setProjectsList] = useState([]);
  const [myProjects, setMyProjects] = useState();

  const fetchProjectsList = async () => {
    try {
      const { data } = await API.get('/project/my-project-list');
      setProjectsList(data);
      setMyProjects(data[0])
      setActiveSprint(data[0]?.sprints[0])
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    fetchProjectsList();
  }, []);
  return (
    <>
      <TaskList myProjects={myProjects} activeSprint={activeSprint} open={open} setOpen={setOpen} projectsList={projectsList} setPassModal={setPassModal} />
      <AddProjectModal open={open} setOpen={setOpen} newProject={newProject} setNewProject={setNewProject} handleAddProject={handleAddProject} />
      <ChangePasswordModal passModal={passModal} setPassModal={setPassModal} />
    </>
  )
}

export default Dashboard