import React from 'react'
import { useState } from 'react';
import { getColorFromName } from '../../lib/utils'
import RoleDropdown from '../../common/ui/RoleDropdown';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import API from '../../services/api';
import DeleteModal from '../modal/DeleteModal';
import { Button } from '@headlessui/react';
import { Pencil, Trash, Trash2 } from 'lucide-react';
import Title from '../../common/ui/Title';
import AddEditModal from '../modal/AddUserModal';
import EditUserModal from '../modal/EditUserModal';


const UserListItem = ({ userData, fetchUsers, newUser, setNewUser }) => {
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState();
    const [loader, setLoader] = useState(false);

    const { mutate: updateRoleMutation } = useMutation({
        mutationFn: async (role) => {

            const response = await API.patch(`/auth/user/${userData?._id}/role`, { role });
            return response.data;
        },
        onSuccess: (data) => {
            toast.success(data?.message);
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to update priority');
        }
    });

    const { mutate: deleteUserMutation } = useMutation({

        mutationFn: async (userId) => {

            const response = await API.delete(`/auth/user/${userId}/delete`);
            return response.data;
        },
        onSuccess: () => {
            //   QueryClient.invalidateQueries({ queryKey: ['userData', userData._id] })
            toast.success('User deleted successfully');
            setDeleteOpen(false)
            fetchUsers();
        },
        onError: (error) => {
            console.error('User deletion failed:', error);
            toast.error('Failed to delete User');
            setDeleteOpen(false)
        },
    });

    const handleEdit = async (e) => {
        e.preventDefault();
        setLoader(true)
        try {
            await API.patch(`/auth/user/${userData._id}/update`, newUser);
            setShowAddEditModal(false);
            fetchUsers();
            toast.success('User updated successfully');
        } catch (err) {
            // console.error(err.message);
            toast.error(err.response.data.message);
        }finally{
            setLoader(false)
        }
    }

    return (
        <>
            <div className="body w-full flex gap-2 text-muted border-b border-border cursor-pointer text-xs hover:bg-primary/5">
                <div className="w-1/2 flex gap-2 items-center border-e border-border p-1" >
                    <div className="flex items-center gap-2">
                        <div className="w-6 h-6 rounded-full flex items-center capitalize justify-center text-white text-xs" style={{ backgroundColor: `${getColorFromName(userData?.name)}` }}>
                            {userData?.name
                                ?.split(' ')
                                .slice(0, 2)
                                .map((name) => name.slice(0, 1).toUpperCase())}
                        </div>
                        {userData?.name}
                    </div>
                </div>
                <div className="w-[40%] flex items-center font-medium border-e border-border">
                    {userData?.email}
                </div>
                <div className="w-[30%] flex items-center border-e border-border capitalize">
                    <RoleDropdown priority={userData?.role} id={userData?._id} handlePriority={updateRoleMutation} />
                </div>
                <div className="w-[20%] flex items-center gap-1.5">
                    <Button
                        className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
                        onClick={(e) => {
                            setShowAddEditModal(true);
                            setSelectedUser(userData);
                        }}
                    >
                        <Pencil className="h-4 w-4" />
                    </Button>
                    <Button
                        className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
                        onClick={(e) => {
                            setDeleteOpen(true);
                        }}
                    >
                        <Trash2 className='h-4 w-4 text-red-500' />
                    </Button>
                </div>
            </div>
            <EditUserModal isOpen={showAddEditModal} setIsOpen={setShowAddEditModal} newUser={newUser} setNewUser={setNewUser} userData={selectedUser} setSelectedUser={setSelectedUser} handleSubmit={handleEdit} loader={loader} />
            <DeleteModal title={'Are you sure you want to delete this Employee?'} open={deleteOpen} setOpen={setDeleteOpen} id={userData?._id} handleDeleteTask={deleteUserMutation} />
        </>
    )
}

export default UserListItem