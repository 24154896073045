import React from 'react'

const IconSprintNotStarted = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_158_798)">
        <circle cx="12" cy="12" r="11.25" stroke="#D7D9DD" stroke-width="1.5" stroke-linejoin="round" stroke-dasharray="4 4" />
        <path d="M16.4067 12.673L16.387 12.6853C16.3526 12.7074 16.328 12.7221 16.3108 12.732L9.96292 16.6371L9.86953 16.6936L9.85233 16.7108L9.79581 16.7354C9.65818 16.7968 9.48861 16.8189 9.37556 16.8189C8.87667 16.8189 8.44168 16.4355 8.3999 15.9661V8.14858C8.3999 8.06502 8.42694 7.99866 8.45151 7.95197C8.5449 7.52435 8.94057 7.19995 9.39522 7.19995C9.52547 7.19995 9.66064 7.22698 9.77369 7.27614L9.79581 7.28597L9.81547 7.29825L16.242 11.0461C16.4264 11.1542 16.4264 11.1542 16.4485 11.1714L16.4509 11.1739C16.6279 11.3189 16.7458 11.5204 16.7852 11.744C16.795 11.8005 16.7999 11.8546 16.7999 11.9062C16.7999 12.2085 16.6524 12.496 16.4067 12.673Z" fill="#D7D9DD" />
        <path d="M16.4067 12.673L16.387 12.6853C16.3526 12.7074 16.328 12.7221 16.3108 12.732L9.96292 16.6371L9.86953 16.6936L9.85233 16.7108L9.79581 16.7354C9.65818 16.7968 9.48861 16.8189 9.37556 16.8189C8.87667 16.8189 8.44168 16.4355 8.3999 15.9661V8.14858C8.3999 8.06502 8.42694 7.99866 8.45151 7.95197C8.5449 7.52435 8.94057 7.19995 9.39522 7.19995C9.52547 7.19995 9.66064 7.22698 9.77369 7.27614L9.79581 7.28597L9.81547 7.29825L16.242 11.0461C16.4264 11.1542 16.4264 11.1542 16.4485 11.1714L16.4509 11.1739C16.6279 11.3189 16.7458 11.5204 16.7852 11.744C16.795 11.8005 16.7999 11.8546 16.7999 11.9062C16.7999 12.2085 16.6524 12.496 16.4067 12.673Z" fill="#ACAEB1" />
      </g>
      <defs>
        <clipPath id="clip0_158_798">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconSprintNotStarted