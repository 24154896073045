import * as Tooltip from "@radix-ui/react-tooltip";
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import NoProjectGif from "../assets/gif/no-projects.gif";
import logo from '../assets/images/New_Logo_1.png';
import ListItem from '../common/ListItem';
import NoDataUI from '../common/NoDataUI';
import IconArchive from '../common/svgIcons/IconArchive';
import IconBuilding from '../common/svgIcons/IconBuilding';
import IconDashboard from '../common/svgIcons/IconDashboard';
import IconPeoples from '../common/svgIcons/IconPeoples';
import IconTag from '../common/svgIcons/IconTag';
import IconWatch from '../common/svgIcons/IconWatch';
import { useAuth } from '../context/AuthContext';
import { cn } from '../lib/utils';
import API from '../services/api';
import IconAnalytics from "../common/svgIcons/IconAnalytics";
import IconTimesheet from "../common/svgIcons/IconTimesheet";


const Sidebar = ({ single = true, openSidebar, setOpenSidebar }) => {
    const navigate = useNavigate()
    const { sprintId } = useParams();
    const [expandedProjects, setExpandedProjects] = useState([]);
    // const [projects, setProjects] = useState([]);
    const [loader, setLoader] = useState(false);
    const { pathname } = useLocation();
    const { user } = useAuth()

    const toggleProject = (projectId) => {
        if (single) {
            setExpandedProjects(expandedProjects[0] === projectId ? [] : [projectId]);
        } else {
            setExpandedProjects((prev) =>
                prev.includes(projectId)
                    ? prev.filter((id) => id !== projectId)
                    : [...prev, projectId]
            );
        }
    };

    const handleParentClick = (project) => {
        const firstSprint = project.sprints[0];
        toggleProject(project._id)
        if (project?.sprints?.length > 0) navigate(`/project/${project._id}/${firstSprint._id}`)
    };


    const fetchProjectsList = async () => {
        setLoader(true)
        try {
            const { data } = await API.get('/project/my-project-list');
            setLoader(false)
            return data
        } catch (err) {
            console.error(err.message);
        }
    };

    const { data: projects } = useQuery({
        queryKey: ['all-project'], queryFn: fetchProjectsList
    });

    useEffect(() => {
        if (sprintId && projects?.length > 0) {
            const matchingProject = projects?.find((project) =>
                project.sprints.some((sprint) => sprint._id === sprintId)
            );
            if (matchingProject && !expandedProjects.includes(matchingProject._id)) {
                setExpandedProjects((prev) => [...prev, matchingProject._id]);
            }
        }
    }, [sprintId, projects]);

    return (
        <>
            {openSidebar && <div className="lg:hidden fixed inset-0 bg-black/50 z-10" onClick={() => setOpenSidebar(false)}></div>}
            <div className={`bg-[#F3F3F8] w-[250px] p-4 px-0 flex flex-col fixed top-0 max-lg:z-[999] transition-all duration-500 lg:!left-0 ${openSidebar ? "left-0" : "-left-full"} h-screen`}>
                <div className="lg:py-5 py-4 px-4 pt-1">
                    <Link to={"/"}>
                        <img src={logo} alt="" className='lg:w-[80%] w-[60%] lg:mx-auto' />
                    </Link>
                </div>
                <div className="flex-grow flex flex-col overflow-auto">
                    <div className="px-3 pb-3 border-b border-secondary flex flex-col gap-1">
                        <Link
                            to={`/home`}
                            className={cn(
                                `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/home' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                { "text-primary": '' }
                            )}
                        >
                            <IconDashboard className={`h-5 w-5 group-hover:text-primary ${pathname === '/home' ? 'text-primary ' : 'text-[#475467]'}`} />
                            Dashboard
                        </Link>
                        {
                            user?.role === 'admin' &&
                            <>
                                <Link
                                    to={`/task-analytics`}
                                    className={cn(
                                        `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/task-analytics' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                        { "text-primary": '' }
                                    )}
                                >
                                    <IconAnalytics className={`h-5 w-5 group-hover:text-primary ${pathname === '/task-analytics' ? 'text-primary bg-primary/10' : 'text-[#475467]'}`} />
                                    Task Analytics
                                </Link>
                                <Link
                                    to={`/task-tracker`}
                                    className={cn(
                                        `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/task-tracker' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                        { "text-primary": '' }
                                    )}
                                >
                                    <IconWatch className={`h-5 w-5 group-hover:text-primary ${pathname === '/task-tracker' ? 'text-primary bg-primary/10' : 'text-[#475467]'}`} />
                                    Task Tracker
                                </Link>
                                <Link
                                    to={`/timesheet`}
                                    className={cn(
                                        `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/timesheet' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                        { "text-primary": '' }
                                    )}
                                >
                                    <IconTimesheet className={`h-5 w-5 group-hover:text-primary ${pathname === '/timesheet' ? 'text-primary bg-primary/10' : 'text-[#475467]'}`} />
                                    Timesheets
                                </Link>
                                <Link
                                    to={`/user`}
                                    className={cn(
                                        `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/user' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                        { "text-primary": '' }
                                    )}
                                >
                                    <IconPeoples className={`h-5 w-5 group-hover:text-primary ${pathname === '/user' ? 'text-primary bg-primary/10' : 'text-[#475467]'}`} />
                                    Team
                                </Link>
                                <Link
                                    to={`/tags`}
                                    className={cn(
                                        `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/tags' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                        { "text-primary": '' }
                                    )}
                                >
                                    <IconTag className={`h-5 w-5 group-hover:text-primary ${pathname === '/user' ? 'text-primary bg-primary/10' : 'text-[#475467]'}`} />
                                    Tags
                                </Link>
                                <Link
                                    to={`/archive`}
                                    className={cn(
                                        `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/archive' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                        { "text-primary": '' }
                                    )}
                                >
                                    <IconArchive className={`h-5 w-5 group-hover:text-primary ${pathname === '/user' ? 'text-primary bg-primary/10' : 'text-[#475467]'}`} />
                                    Archive
                                </Link>
                            </>
                        }
                    </div>
                    <div className="px-4 pt-3 pe-0">
                        <p className='text-dark/40 pb-3 text-sm'>Project List</p>
                        <h3 className='flex items-center gap-2'>
                            <IconBuilding className='w-5 h-5 text-[#475467]' />
                            <p className='text-sm font-medium text-[#25272D]'>Elaunch Projects</p>
                        </h3>
                        {projects?.length ? (
                            <ul className="flex flex-col mt-3 pe-4">
                                <Tooltip.Provider>
                                    {projects.map((project) => (
                                        <ListItem expandedProjects={expandedProjects} handleParentClick={handleParentClick} project={project} sprintId={sprintId} toggleProject={toggleProject} key={project._id} />
                                    ))}
                                </Tooltip.Provider>
                            </ul>
                        ) : (
                            <div className="flex-grow flex items-center justify-center">
                                <NoDataUI img={NoProjectGif} subtitle={"Project assigned to you will appear here."} subtitleClassName="italic" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
