import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import Loader from '../../common/ui/Loader';

const LabelChart = ({ data, loading }) => {
  const labelColorMapping = {
    'To Do': '#D18101',
    'Ready for Development': '#01ABD1',
    'In Progress': '#004AEB',
    'Hold': '#FA9600',
    'Done': '#32ADE6',
    'Ready for Test': '#AF52DE',
    'Re Open': '#FF5D7C',
    'In Review': '#00C7BE',
    'Complete': '#34C759',
    'Closed': '#008748',
  };

  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      width: 380,
      type: 'donut',
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    title: {
      text: 'Task Stats',
      align: 'left',
      style: {
        fontSize: "16px",
        fontWeight: 600,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  });

  useEffect(() => {
    if (data?.data) {
      const sortedData = data.data.sort((a, b) => a._id.localeCompare(b._id));

      const mappedLabels = [];
      const mappedSeries = [];
      const mappedColors = [];

      sortedData.forEach(item => {
        const label = item._id;
        const color = labelColorMapping[label];
        mappedLabels.push(label);
        mappedSeries.push(item.total);
        mappedColors.push(color);
      });

      setSeries(mappedSeries);

      setOptions(prevOptions => ({
        ...prevOptions,
        labels: mappedLabels,
        colors: mappedColors,
        tooltip: {
          y: {
            formatter: function (val) {
              return `${val}`;
            },
          },
        },
      }));
    }
  }, [data]);

  return (
    loading ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px' }}>
        <Loader />
      </div>) : (
      <ReactApexChart options={options} series={series} type="donut" width={280} />
    )
  );
};

export default LabelChart;
