import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import clsx from 'clsx';
import { ArrowBigDown, Ban, CheckIcon, ChevronDown } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { priorityTypes, roleTypes } from '../../staticData/data';

const RoleDropdown = ({ id, priority, handlePriority }) => {
    const [priorityType, setPriorityType] = useState();
    useEffect(() => {
        setPriorityType(priority);
    }, [priority])
    const priorityColor = priorityTypes?.find((status) => status.name === priorityType);
    return (
        <div className=''>
            <Listbox
                value={priority}
                onChange={(newStatus) => {
                    handlePriority(newStatus);
                    setPriorityType(newStatus)
                }
                }
            >
                <ListboxButton
                    className={clsx(
                        `w-[180px] rounded-lg py-1 text-xs font-medium flex items-center justify-between `,
                        'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                    )}
                // style={{ background: `${priorityColor?.bgColor}`, color: `${priorityColor?.color}`, border: `1px solid ${priorityColor?.color}` }}
                >
                    {/* {priorityType} */}
                    {priorityType
                        ?.split('_')
                        .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
                        .join(' ')}
                    <ChevronDown className='text-dark/70 h-4 w-4' />
                </ListboxButton>
                <ListboxOptions
                    anchor="bottom"
                    transition
                    className={clsx(
                        'w-[210px] rounded-xl shadow-custom border border-white/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                        'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                    )}
                >
                    <div className="p-1 border-b border-border">
                        {roleTypes.map((status) => (
                            <ListboxOption
                                key={status.id}
                                value={status.value}
                                className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-lg py-1.5 px-2 select-none hover:bg-dark/5"
                            >
                                <div className="flex items-center gap-1.5">
                                    <div className="text-sm font-medium">{status.name}</div>
                                </div>
                            </ListboxOption>
                        ))}
                    </div>
                    {/* <div className="p-1">
                        <ListboxOption
                            key="clear"
                            value={'clear'}
                            className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-lg py-1.5 px-2 select-none hover:bg-dark/5"
                        >
                            <div className="flex items-center gap-1.5">
                                <Ban className='text-dark/70 h-5 w-5' />
                                <p className='text-sm font-medium'>Clear</p>
                            </div>
                        </ListboxOption>
                    </div> */}
                </ListboxOptions>
            </Listbox>
        </div>
    )
}

export default RoleDropdown