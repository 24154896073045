import React from 'react'
import RecentList from './RecentList'
import WelcomeBanner from './WelcomeBanner'

const HomePage = () => {
    return (
        <div className="">
            <WelcomeBanner />
            <RecentList />
        </div>
    )
}

export default HomePage