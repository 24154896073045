import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Button } from "./Button";
import { ChevronDown, ChevronLeft, ChevronRight } from "lucide-react";

moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});

const WeekPicker = ({ selectedWeek, setSelectedWeek }) => {
    const [hoveredWeek, setHoveredWeek] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const changeWeek = (offset) => {
        const newWeek = moment(selectedWeek).add(offset, "week").startOf("week").toDate();
        setSelectedWeek(newWeek);
    };

    const getWeekRange = () => {
        const start = moment(selectedWeek).format("MMM DD");
        const end = moment(selectedWeek).endOf("week").format("MMM DD");
        return `${start} - ${end}`;
    };

    const getWeekDays = (date) => {
        const startOfWeek = moment(date).startOf("week");
        return Array.from({ length: 7 }, (_, i) =>
            startOfWeek.clone().add(i, "days").toDate()
        );
    };

    return (
        <div className="flex items-center gap-2.5">
            <div className="flex gap-3">
                <Button
                    className="bg-transparent hover:bg-primary/5 shadow-none h-8 w-8 p-0"
                    onClick={() => changeWeek(-1)}
                >
                    <ChevronLeft className="h-5 w-5 text-[#667085]" />
                </Button>
                <Button
                    className="bg-transparent hover:bg-primary/5 shadow-none h-8 w-8 p-0"
                    onClick={() => changeWeek(1)}
                >
                    <ChevronRight className="h-5 w-5 text-[#667085]" />
                </Button>
            </div>
            <div style={{ position: "relative" }}>
                <div
                    className="week-picker-container"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <span className="week-picker-label text-dark text-sm font-semibold">
                        {getWeekRange()}
                    </span>
                    <span className="week-picker-icon">
                        <ChevronDown />
                    </span>
                </div>

                {isOpen && (
                    <div className="datepicker-container">
                        <DatePicker
                            selected={selectedWeek}
                            onChange={(date) => {
                                setSelectedWeek(moment(date).startOf("week").toDate());
                                setIsOpen(false);
                            }}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            dateFormat={getWeekRange()}
                            inline
                            highlightDates={[
                                ...(selectedWeek ? getWeekDays(selectedWeek) : []),
                                ...(hoveredWeek ? getWeekDays(hoveredWeek) : []),
                            ]}
                            calendarStartDay={1}
                            dayClassName={(date) => {
                                const isHovered =
                                    hoveredWeek &&
                                    getWeekDays(hoveredWeek).some((d) =>
                                        moment(d).isSame(date, "day")
                                    );
                                const isSelected =
                                    selectedWeek &&
                                    getWeekDays(selectedWeek).some((d) =>
                                        moment(d).isSame(date, "day")
                                    );
                                return isHovered || isSelected ? "hover-highlight" : "";
                            }}
                            onDayMouseEnter={(date) =>
                                setHoveredWeek(moment(date).startOf("week").toDate())
                            }
                            onDayMouseLeave={() => setHoveredWeek(null)}
                        />
                    </div>
                )}
            </div>
            <Button
                className="shadow-none bg-[#FCFCFD] hover:bg-primary/5 text-[#667085] border border-[#EAECF0] h-auto py-1 px-4"
                onClick={() => setSelectedWeek(moment().startOf("week").toDate())}
            >
                This Week
            </Button>
        </div>
    );
};

export default WeekPicker;
