import React from 'react'

const IconReOpen = (props) => {
  return (
    <svg {...props} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.6666" r="8.25" stroke="currentColor" stroke-width="1.5" />
      <path d="M10 4.66663C11.0038 4.66663 11.9916 4.91849 12.8729 5.39914L10 10.6666V4.66663Z" fill="currentColor" />
    </svg>

  )
}

export default IconReOpen