import React from 'react'

const IconFlag = (props) => {
    return (
        // <svg {...props} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path d="M22.7203 6.67241C23.254 6.01961 22.7894 5.03961 21.946 5.03961H8.05465V17.0396H21.946C22.7894 17.0396 23.254 16.0596 22.7203 15.4068L19.6743 11.6797C19.3653 11.3091 19.3653 10.7701 19.6743 10.3995L22.7203 6.67241ZM5.05225 25.0245C5.60225 25.0245 6.05225 24.5743 6.05225 24.0245V4.70461C6.65225 4.36431 7.05225 3.71441 7.05225 2.97461C7.05225 1.87461 6.16225 0.974609 5.05225 0.974609C3.95225 0.974609 3.05225 1.87461 3.05225 2.97461C3.05225 3.71441 3.45225 4.36431 4.05225 4.70461V24.0245C4.05225 24.5743 4.50225 25.0245 5.05225 25.0245Z" stroke="currentColor" />
        // </svg>
        <svg {...props} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.7203 6.67241C23.254 6.01961 22.7894 5.03961 21.946 5.03961H8.05465V17.0396H21.946C22.7894 17.0396 23.254 16.0596 22.7203 15.4068L19.6743 11.6797C19.3653 11.3091 19.3653 10.7701 19.6743 10.3995L22.7203 6.67241ZM5.05225 25.0245C5.60225 25.0245 6.05225 24.5743 6.05225 24.0245V4.70461C6.65225 4.36431 7.05225 3.71441 7.05225 2.97461C7.05225 1.87461 6.16225 0.974609 5.05225 0.974609C3.95225 0.974609 3.05225 1.87461 3.05225 2.97461C3.05225 3.71441 3.45225 4.36431 4.05225 4.70461V24.0245C4.05225 24.5743 4.50225 25.0245 5.05225 25.0245Z" fill="currentColor" />
        </svg>

    )
}

export default IconFlag