import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Lock, LogOut, Plus } from 'lucide-react';
import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Button } from './ui/Button';


export default function ProfileDropdown({ setOpen, setPassModal }) {
  const { logout, user } = useAuth();
  return (
    <Menu>
      <MenuButton className="w-8 h-8 bg-primary rounded-full text-white">
        {user?.name?.split(' ').slice(0, 2).map(name => name.slice(0, 1).toUpperCase())}
      </MenuButton>
      <MenuItems
        transition
        anchor="bottom end"
        // className="w-[200px] origin-top-right rounded-xl border-2 border-border mt-2 bg-white p-1 text-sm/6 transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
        className="w-[180px] flex flex-col gap-1 origin-top-right rounded-xl border-2 border-border  font-semibold bg-white shadow-lg p-1 text-xs transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
      >

        {
          user?.role === "admin" &&
          <MenuItem>
            <button className="group flex w-full items-center gap-2 text-xs text-dark/60 rounded-lg py-1.5 px-2.5 data-[focus]:bg-primary data-[focus]:text-white " onClick={() => setOpen(true)}>
              <Plus className="h-3.5 w-3.5" />
              Add Project
            </button>
          </MenuItem>
        }
        <MenuItem>
          <button className="group flex w-full items-center gap-2 text-xs text-dark/60 rounded-lg py-1.5 px-2.5 data-[focus]:bg-primary data-[focus]:text-white " onClick={() => setPassModal(true)}>
            <Lock className="h-3.5 w-3.5" />
            Change Password
          </button>
        </MenuItem>
        <MenuItem>
          <button className="group flex w-full items-center gap-2 text-xs text-dark/60 rounded-lg py-1.5 px-2.5 data-[focus]:bg-primary data-[focus]:text-white " onClick={logout}>
            <LogOut className="h-3.5 w-3.5" /> Logout
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  )
}
