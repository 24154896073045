import { ChevronDownIcon, Search } from 'lucide-react';
import React, { useState } from 'react';
import { Button } from './Button';
import { Input } from '../Input';

const MultiSelect = ({ selected, setSelected, people }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const toggleSelection = (person) => {
        const isSelected = selected.some((p) => p._id === person._id);
        if (isSelected) {
            setSelected(selected.filter((p) => p._id !== person._id));
        } else {
            setSelected([...selected, person]);
        }
        setMenuOpen(false);
    };

    const handleRemove = (e, person) => {
        e.stopPropagation();
        setSelected(selected.filter((item) => item._id !== person._id));
    };

    const filteredMembers = people?.filter((member) =>
        member.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="relative">
            <div
                onClick={() => setMenuOpen(!menuOpen)}
                className="w-full flex justify-between rounded-md border border-border bg-[#FBFBFF] px-4 py-3.5 text-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring cursor-pointer"
            >
                {!selected?.length ? (
                    <p className="text-gray-700">Select Team Member</p>
                ) : (
                    <div className="flex items-center flex-wrap gap-1.5">
                        {selected.map((person) => (
                            <span
                                key={person._id}
                                className="bg-gray-400 text-white px-2 pe-0.5 py-0.5 rounded-full text-xs flex items-center gap-1"
                            >
                                {person.name}
                                <Button
                                    onClick={(e) => handleRemove(e, person)}
                                    className="text-white p-0 text-[10px] h-4 w-4 rounded-full"
                                >
                                    ✕
                                </Button>
                            </span>
                        ))}
                    </div>
                )}
                <ChevronDownIcon className="size-4 fill-gray-500" />
            </div>
            {menuOpen && (
                <div className="absolute top-12 left-0 w-full bg-white rounded-md shadow-md overflow-hidden">
                    <div className="p-2">
                        <Input
                            placeholder="Invite by name or email"
                            sufix={<Search className='h-4 w-4 text-gray-400' />}
                            className='bg-white py-2'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div className="max-h-40 overflow-auto p-2 flex flex-col gap-0.5">
                        {filteredMembers.map((person) => (
                            <div
                                key={person.id}
                                onClick={() => toggleSelection(person)}
                                className={`py-1.5 px-2 text-sm cursor-pointer rounded-md ${selected.some((p) => p._id === person._id) ? 'bg-gray-100 font-medium' : 'hover:bg-gray-100'
                                    }`}
                            >
                                {person.name}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiSelect;
