import React, { useState } from 'react'
import { cn } from '../../lib/utils';

const Tooltip = ({ text, children, bgColor, borderColor, toolTipClass, detailClass, arrowClass }) => {
    const [visible, setVisible] = useState(false);

    const showTooltip = () => setVisible(true);
    const hideTooltip = () => setVisible(false);

    return (
        <div
            className={cn('relative inline-block w-fit', toolTipClass)}
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
        >
            {children}
            {
                visible && (
                    <div className={cn('absolute -top-8 left-1/2 transform -translate-x-1/2 w-max bg-primary text-white text-xs rounded px-2 py-1 shadow-md z-10', bgColor, detailClass)}>
                        {text}
                        <div className={cn('absolute top-full left-1/2 transform -translate-x-1/2 w-0 h-0 border-t-4 border-l-transparent border-l-4 border-r-transparent border-r-4', borderColor, arrowClass)}></div>
                    </div>
                )
            }
        </div >
    );

}

export default Tooltip