import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { cn } from '../lib/utils'
import API from '../services/api'
import IconSprintActive from './svgIcons/IconSprintActive'
import IconSprintComplete from './svgIcons/IconSprintComplete'
import IconSprintNotStarted from './svgIcons/IconSprintNotStarted'

const statuses = [
  { status: 'Active', icon: <IconSprintActive className="w-4 h-4" /> },
  { status: 'Completed', icon: <IconSprintComplete className="w-4 h-4" /> },
  { status: 'Planned', icon: <IconSprintNotStarted className="w-4 h-4" /> },
]

const SelectSprintStatus = ({ sprint }) => {
  const queryClient = useQueryClient()
  const [selected, setSelected] = useState();
  useEffect(() => {
    const active = statuses.find((stutusEle) => stutusEle.status === sprint?.status)
    setSelected(active)
  }, [sprint]);

  const { mutate: updateSprintStatus } = useMutation({
    mutationFn: async (status) => {
      const response = await API.patch(`/sprint/${sprint._id}/status`, {
        status
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sprint'] });
      toast.success('Sprint status updated successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Failed to update sprint status');
    }
  });

  const handleOnChange = (statusObj) => {
    setSelected(statusObj)
    updateSprintStatus(statusObj.status);
  }
  return (
    <Listbox value={selected} onChange={handleOnChange}>
      <ListboxButton
        className={cn(
          'relative w-[110px] rounded-lg flex items-center gap-2 bg-primary/10 py-1.5 px-3  font-semibold text-dark  text-xs',
          'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
        )}
      >
        <span>{selected?.icon}</span>  {selected?.status}
      </ListboxButton>
      <ListboxOptions
        anchor="bottom"
        transition
        className={cn(
          'w-[var(--button-width)] shadow-lg rounded-xl border border-border bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
          'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
        )}
      >
        {statuses.map((status) => (
          <ListboxOption
            key={status.status}
            value={status}
            className="group flex cursor-default items-center text-xs gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-primary/10"
          >
            <span>{status.icon}</span>
            {status.status}
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  )
}

export default SelectSprintStatus