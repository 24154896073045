import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import BugItem from './BugItem';
import IconCicularPlus from './svgIcons/IconCicularPlus';

const Bugs = ({ task, addBugs, newBug, setNewBug, title }) => {
    const [tasks, setTasks] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    const handleAddTask = () => {
        const newTask = {
            id: tasks.length + 1,
            title: '',
            image: 'https://example.com/image.jpg',
            timeAgo: 'Just now',
        };
        setTasks([...tasks, newTask]);
        setIsEditing(true); // Enable editing for the newly added task

    };

    const handleSaveTask = (index, newTitle) => {
        const updatedTasks = [...tasks];
        updatedTasks[index].title = newTitle; // Update the title of the task
        setTasks(updatedTasks); // Save the updated tasks
        // setIsEditing(false); // End editing mode
        handleAddTask()
    };

    const handleCancel = (index) => {
        const updatedTasks = [...tasks];
        updatedTasks.splice(index, 1); // Remove the task if canceled
        setTasks(updatedTasks);
        setIsEditing(false); // End editing mode
    };

    return (
        <div>
            {/* Render Bug Items */}
            {tasks.map((task, index) => (
                <BugItem
                    key={task.id}
                    task={task}
                    isEditing={isEditing && index === tasks.length - 1} // Only the latest task is editable
                    // onSave={(newTitle) => handleSaveTask(index, newTitle)} // Save task
                    onCancel={() => handleCancel(index)} // Cancel task
                    setIsEditing={setIsEditing}
                    addBugs={addBugs}
                    newBug={newBug}
                    setNewBug={setNewBug}
                />
            ))}

            {/* Add New Task Button */}
            {!isEditing && (
                <div
                    className="border-b border-border py-1.5 px-2 ps-2.5 hover:bg-gray-100 cursor-pointer flex items-center gap-2 text-sm"
                    onClick={handleAddTask}
                >
                    <IconCicularPlus className="h-4 w-4" />
                    <p className="text-gray-400">{title}</p>
                </div>
            )}
        </div>
    );
};

export default Bugs;
