import { Check, ChevronDown } from 'lucide-react'
import { EditorBubbleItem, useEditor } from 'novel'
import { Button } from '../../../common/ui/Button'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'



const TEXT_COLORS = [
  {
    name: 'Default',
    color: 'var(--novel-black)'
  },
  {
    name: 'Purple',
    color: '#9333EA'
  },
  {
    name: 'Red',
    color: '#E00000'
  },
  {
    name: 'Yellow',
    color: '#EAB308'
  },
  {
    name: 'Blue',
    color: '#2563EB'
  },
  {
    name: 'Green',
    color: '#008A00'
  },
  {
    name: 'Orange',
    color: '#FFA500'
  },
  {
    name: 'Pink',
    color: '#BA4081'
  },
  {
    name: 'Gray',
    color: '#A8A29E'
  }
]

const HIGHLIGHT_COLORS = [
  {
    name: 'Default',
    color: 'var(--novel-highlight-default)'
  },
  {
    name: 'Purple',
    color: 'var(--novel-highlight-purple)'
  },
  {
    name: 'Red',
    color: 'var(--novel-highlight-red)'
  },
  {
    name: 'Yellow',
    color: 'var(--novel-highlight-yellow)'
  },
  {
    name: 'Blue',
    color: 'var(--novel-highlight-blue)'
  },
  {
    name: 'Green',
    color: 'var(--novel-highlight-green)'
  },
  {
    name: 'Orange',
    color: 'var(--novel-highlight-orange)'
  },
  {
    name: 'Pink',
    color: 'var(--novel-highlight-pink)'
  },
  {
    name: 'Gray',
    color: 'var(--novel-highlight-gray)'
  }
]

export const ColorSelector = ({ open, onOpenChange }) => {
  const { editor } = useEditor()

  if (!editor) return null
  const activeColorItem = TEXT_COLORS.find(({ color }) =>
    editor.isActive('textStyle', { color })
  )

  const activeHighlightItem = HIGHLIGHT_COLORS.find(({ color }) =>
    editor.isActive('highlight', { color })
  )

  return (
    <Popover>
      <PopoverButton className="block text-sm/6 font-semibold focus:outline-none">
        <Button size='sm' className='gap-2 rounded-none' variant='ghost'>
          <span
            className='rounded-sm px-1'
            style={{
              color: activeColorItem?.color,
              backgroundColor: activeHighlightItem?.color
            }}
          >
            A
          </span>
          <ChevronDown className='h-4 w-4' />
        </Button>
      </PopoverButton>
      <PopoverPanel
        transition
        anchor="bottom end"
        className="divide-y h-[300px] divide-white/5 border border-black/50 mt-2  rounded-md bg-white shadow-md text-xs transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
      >
        <div className='flex flex-col'>
          <div className='my-1 px-2 text-sm font-semibold text-muted-foreground'>
            Color
          </div>
          {TEXT_COLORS.map(({ name, color }) => (
            <EditorBubbleItem
              key={name}
              onSelect={() => {
                editor.commands.unsetColor()
                name !== 'Default' &&
                  editor
                    .chain()
                    .focus()
                    .setColor(color || '')
                    .run()
                onOpenChange(false)
              }}
              className='flex cursor-pointer items-center justify-between px-2 py-1 text-sm hover:bg-accent'
            >
              <div className='flex items-center gap-2'>
                <div
                  className='rounded-sm border px-2 py-px font-medium'
                  style={{ color }}
                >
                  A
                </div>
                <span>{name}</span>
              </div>
            </EditorBubbleItem>
          ))}
        </div>
        <div>
          <div className='my-1 px-2 text-sm font-semibold text-muted-foreground'>
            Background
          </div>
          {HIGHLIGHT_COLORS.map(({ name, color }) => (
            <EditorBubbleItem
              key={name}
              onSelect={() => {
                editor.commands.unsetHighlight()
                name !== 'Default' &&
                  editor.chain().focus().setHighlight({ color }).run()
                onOpenChange(false)
              }}
              className='flex cursor-pointer items-center justify-between px-2 py-1 text-sm hover:bg-accent'
            >
              <div className='flex items-center gap-2'>
                <div
                  className='rounded-sm border px-2 py-px font-medium'
                  style={{ backgroundColor: color }}
                >
                  A
                </div>
                <span>{name}</span>
              </div>
              {editor.isActive('highlight', { color }) && (
                <Check className='h-4 w-4' />
              )}
            </EditorBubbleItem>
          ))}
        </div>
      </PopoverPanel>
    </Popover>
  )
}


