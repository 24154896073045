import { Dialog, DialogPanel, Listbox, ListboxButton, ListboxOption, ListboxOptions, Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { AlarmClock, ArrowLeft, Calendar, Check, CheckIcon, ChevronDown, CircleStop, Flag, Hourglass, Play, Square, Timer, UserRound, X } from 'lucide-react';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BugListItem from '../../common/BugListItem';
import Bugs from '../../common/Bugs';
import CommentCard from '../../common/CommentCard';
import { Input } from '../../common/Input';
import ReplyCard from '../../common/ReplyCard';
import SubTaskListItem from '../../common/SubTaskListItem';
import AssigneePopover from '../../common/ui/AssigneePopover';
import { Button } from '../../common/ui/Button';
import DueDate from '../../common/ui/DueDate';
import EstimatedTimePopover from '../../common/ui/EstimatedTimePopover';
import PriorityDropdown from '../../common/ui/PriorityDropdown';
import { useAuth } from '../../context/AuthContext';
import { getColorFromName } from '../../lib/utils';
import API from '../../services/api';
import Editor from '../editor/editor';

const TaskDetailModal = forwardRef(({ open, setOpen, task, isArchive, isReport, statusType, projectDetails, sprintDetails, handleStatusChange, fetchCurrentTask, handleRemovePriority, handlePriority, handleDueDate, addEstimatedTime, removeEstimatedTime, member, updateTitle, updateDescription, menu, modalType, tagList }, ref) => {
    const queryClient = useQueryClient()
    const scrollableDivRef = useRef(null);
    const [localTitle, setLocalTitle] = useState();
    const { user } = useAuth()
    const [content, setContent] = useState();
    const [savingStatus, setSavingStatus] = useState('Saved');
    const { logout } = useAuth();
    const [newType, setNewType] = useState();
    const [timeList, setTimeList] = useState();
    const [userTimer, setUserTimer] = useState();
    const [entries, setEntries] = useState(false);
    const [allTime, setAllTime] = useState();
    const [groupSet, setGroupSet] = useState();
    const [timerStatus, setTimerStatus] = useState();
    const [trackBtn, setTrackBtn] = useState(true);
    const [editTitle, setEditTitle] = useState(false);
    const [editDescription, setEditDescription] = useState(false);
    const [commentValue, setCommentValue] = useState();
    const [replyValue, setReplyValue] = useState();
    const [replyMode, setReplyMode] = useState();
    const [commentDetails, setCommentDetails] = useState();
    const [send, setSend] = useState(false);
    const descriptionRef = useRef(null);
    const [sprintList, setSprintList] = useState([]);
    const [toggleSections, setToggleSections] = useState({});
    const { projectId } = useParams();
    const groupedActivities = [];
    let currentGroup = [];

    useEffect(() => {
        setNewType(task?.status);
        setLocalTitle(task?.title);
    }, [task])


    const color = statusType.find((status) => status.name === newType);

    const fetchTimersAndTasks = async () => {
        try {
            const [timersRes] = await Promise.all([API.get('/timer')]);
            setTimeList(timersRes.data);
            setUserTimer(timersRes?.data?.filter((time) => time.taskId._id == task._id))
        } catch (err) {
            if (err.status === 401) {
                logout()
            }
        }
    };

    useImperativeHandle(ref, () => ({
        fetchTimersAndTasks,
    }));

    useEffect(() => {
        if (open) {
            fetchTimersAndTasks();
            timmerStatus()
        }
    }, [open]);

    useEffect(() => {
        setUserTimer(timeList?.filter((time) => time?.taskId?._id == task?._id))
    }, [timeList]);

    const handleToggleTimer = async (id) => {
        setTrackBtn(false)
        try {
            await API.post('/timer/start', { taskId: id });
            timmerStatus();
            setTrackBtn(true)
            toast.success('tracking started');
            fetchTimersAndTasks();
            fetchCurrentTask()
            queryClient.invalidateQueries({ queryKey: ['todays-hour'], refetchType: 'all' });
            queryClient.invalidateQueries({ queryKey: ['todays-hour'] });
            setUserTimer(timeList.filter((time) => time.taskId._id == task._id))

        } catch (err) {
            console.error(err.message);
            toast.error(err?.response?.data?.message);
        } finally {
            setTrackBtn(true)
        }
    };

    const handleStopTimer = async (timerId) => {
        setTrackBtn(false)
        try {
            await API.post('/timer/stop', { timerId });
            timmerStatus();
            setTrackBtn(true)
            toast.success('tracking stoped')
            fetchTimersAndTasks();
            fetchCurrentTask()
            queryClient.invalidateQueries('todays-hour', { refetchType: 'all' });
            queryClient.invalidateQueries({ queryKey: ['todays-hour'] });
            setUserTimer(timeList.filter((time) => time.taskId._id == task._id))
        } catch (err) {
            console.error(err.message);
        } finally {
            setTrackBtn(true)
        }
    };

    const totalSeconds = userTimer?.reduce((sum, timer) => sum + (timer?.duration || 0), 0);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    const formattedDuration = `${hours.toString().padStart(2, '0')}h:${minutes.toString().padStart(2, '0')}m:${seconds.toString().padStart(2, '0')}s`;

    const userTiming = async () => {
        try {
            const data = await API.get(`/timer/tasks/${task._id}/timers`);
            setAllTime(data?.data);

        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        if (task && task?._id && open) {
            userTiming();
            timmerStatus();
        } else {
            console.warn("Task is not available yet.");
        }
    }, [task, timerStatus]);

    useEffect(() => {
        if (allTime) {

            const groupedArray = allTime.reduce((acc, curr) => {
                const userId = curr.userId._id;

                if (!curr.endTime) {
                    return acc;
                }

                let userGroup = acc.find(group => group.userInfo._id === userId);

                if (!userGroup) {
                    userGroup = {
                        userInfo: curr.userId,
                        tasks: [],
                        totalDuration: 0
                    };
                    acc.push(userGroup);
                }

                userGroup.tasks.push(curr);

                userGroup.totalDuration += curr.duration;

                return acc;
            }, []);

            setGroupSet(groupedArray);
        }
    }, [allTime, task?._Id, open]);


    const timmerStatus = async () => {
        try {
            const data = await API.get(`/timer/tasks/${task?._id}/timer/status`);
            setTimerStatus(data?.data?.isActive)
        } catch (err) {
            if (err.status === 401) {
                logout()
            }
        }
    };


    const inputTitleRef = useRef();
    const inputDisRef = useRef();


    // useEffect(() => {
    //     setLocalTitle(task?.title);
    //     setLocalDisc(task?.description);
    // }, [task]);

    useEffect(() => {
        if (editTitle && inputTitleRef.current) {
            inputTitleRef.current.focus();
        }
        if (editDescription && inputDisRef.current) {
            inputDisRef.current.focus();
        }
    }, [setEditTitle]);

    const handleTitleChange = (e) => {
        setLocalTitle(e.target.value);
    };
    const handleDiscChange = (e) => {
        // setLocalDisc(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            task.title = localTitle;
            updateTitle(localTitle);
            setEditTitle(null);
        }
        if (e.key === 'Escape') {
            setLocalTitle(task.title);
            setEditTitle(null);
        }
    };

    useEffect(() => {
        if (!content) return;
        setSavingStatus('Unsaved');
        const debounceTimeout = setTimeout(async () => {
            try {
                await updateDescription(content);
                setSavingStatus('Saved');
            } catch (error) {
                setSavingStatus('Error Saving');
            }
        }, 1500);
        return () => clearTimeout(debounceTimeout);
    }, [content]);

    // const handleUpdateDisc = async (e) => {
    //     if (e.key === 'Enter' && !e.shiftKey) {
    //         e.preventDefault();
    //         try {
    //             await updateDescription(localDisc.trim());
    //             setEditDescription(false);
    //         } catch (err) {
    //             console.error("Error updating description:", err);
    //         }
    //     }
    //     if (e.key === 'Escape') {
    //         setEditDescription(false);
    //     }
    // };
    const autoResizeTextarea = () => {
        if (descriptionRef.current) {
            descriptionRef.current.style.height = 'auto';
            descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        autoResizeTextarea();
    }, [task?.title, editDescription]);

    const fetchBugs = async () => {
        try {
            const { data } = await API.get(`/task/${task?._id}/bugs`);
            return data.bugs;
        } catch (err) {
            console.error(err.message);
            return [];
        }
    };

    const { data: bugData } = useQuery(
        { queryKey: ['fetchBugs', task?._id], queryFn: fetchBugs, enabled: !!task?._id, }
    );

    const fetchSubtask = async () => {
        try {
            const { data } = await API.get(`/task/${task?._id}/subtasks`);
            return data?.subtasks;
        } catch (err) {
            console.error(err.message);
            return [];
        }
    };

    const { data: subTaskList } = useQuery(
        { queryKey: ['subtaskList', task?._id], queryFn: fetchSubtask, enabled: !!task?._id, }
    );

    const addBugs = async (title) => {
        try {
            const objBug = { title: title, assignedTo: task?.assignedTo, priority: task?.priority || 'Low', dueDate: task?.dueDate };
            const { data } = await API.post(`/task/${task?._id}/bugs`, objBug);
            queryClient.invalidateQueries({ queryKey: ['fetchBugs', task?._id] })
            toast.success('Bug Added');
            return data;
        } catch (err) {
            console.error(err.message);
            return [];
        }
    }
    const addSubTask = async (title) => {
        try {
            const objSubTask = { title: title, parentTask: task?._id, assignedTo: task?.assignedTo, priority: 'Low', dueDate: task?.dueDate };
            const { data } = await API.post(`/task/${task?._id}/subtasks`, objSubTask);
            queryClient.invalidateQueries({ queryKey: ['subtaskList', task?._id] })
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            toast.success('Subtask Added');
            return data;
        } catch (err) {
            console.error(err.message);
            return [];
        }
    }

    const fetchActivityComments = async () => {
        try {
            const { data } = await API.get(`/task/activities-comments?relatedTo=${task?._id}&relatedModel=${modalType}`);
            return data.records;
        } catch (err) {
            console.error(err.message);
            return [];
        }
    };

    const { data: activityCommentsList, refetch } = useQuery(
        { queryKey: ['fetchActivityComments', task?._id], queryFn: fetchActivityComments, enabled: !!task?._id }
    );

    useEffect(() => {
        if (task?._id) {
            refetch();
        }
    }, [task?._id]);

    const { mutate: addCommentMutation } = useMutation({
        mutationFn: async () => {
            const commentObj = {
                type: "comment",
                relatedTo: task?._id,
                relatedModel: modalType,
                content: commentValue,
                action: "comment added in task"
            }

            const response = await API.post(`/task/activities-comments`, commentObj);
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['fetchActivityComments', task?._id] });
            setCommentValue('')
            toast.success('comment Added');
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to add comment');
        }
    });

    const handleKeyPressToComment = (e) => {
        if (e.key === 'Enter') {
            addCommentMutation();
        }
    };

    const { mutate: addReplyMutation } = useMutation({
        mutationFn: async () => {
            const commentObj = {
                type: "reply",
                parentId: commentDetails?._id,
                relatedTo: task?._id,
                relatedModel: modalType,
                content: replyValue,
                action: "reply added in task"
            }

            const response = await API.post(`/task/activities-comments`, commentObj);
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['fetchActivityComments', task?._id] });
            setReplyValue('')
            setSend(true);
            setTimeout(() => {
                setSend(false);
            }, 1000)
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to add comment');
        }
    });

    const handleKeyPressToReply = (e) => {
        if (e.key === 'Enter') {
            addReplyMutation();
        }
    };

    const fetchProjectsList = async () => {
        try {
            const { data } = await API.get('/project/my-project-list');
            const matchingProject = data?.find((project) => project._id === projectId);
            const filteredSprints = matchingProject?.sprints.filter((sprint) => sprint._id !== sprintDetails._id)
            setSprintList(filteredSprints)
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        if (open) {
            fetchProjectsList();
        }
    }, [open]);

    const handleSprintChange = async (sprintId) => {
        try {
            await API.patch(`/task/${task?._id}/sprint`, { sprintId: sprintId })
            toast.success("Task shifted successfully");
            // fetchProjectsList();
            queryClient.invalidateQueries({ queryKey: ['tasks', sprintDetails._id] })
            setOpen(false)
        } catch (error) {
            toast.error("cannot shift task")
        }
    }

    activityCommentsList?.forEach((activity) => {
        if (activity.type === "comment") {
            if (currentGroup.length > 0) {
                groupedActivities.push([...currentGroup]); // Push current group of "activity"
                currentGroup = [];
            }
            groupedActivities.push([activity]); // Push the "comment" as its own group
        } else {
            currentGroup.push(activity); // Add "activity" to the current group
        }
    });

    if (currentGroup.length > 0) {
        groupedActivities.push([...currentGroup]); // Push the remaining "activity" group
    }

    // Toggle visibility of a specific section
    const toggleSection = (index) => {
        setToggleSections((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    useEffect(() => {
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
        }
    }, [activityCommentsList, groupedActivities, replyMode]);


    return (
        <Dialog open={open} as="div" className="relative z-10 focus:outline-none" onClose={() => { setOpen(false); setGroupSet([]); setReplyMode(false) }}>
            <div className="fixed inset-0 w-screen overflow-y-auto bg-black/30">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel
                        transition
                        className="w-full max-w-[1500px] overflow-hidden h-[90vh] rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                    >
                        <div className="h-full min-h-[inherit] flex flex-col">
                            {/* <div className="bg-[#F3F3F8] pt-7 pb-4 px-8">
                                {editTitle ?
                                    <Input
                                        ref={inputTitleRef}
                                        id={`edit-title-${task?._id}`}
                                        lableClass="hidden"
                                        className="border-0 bg-transparent p-0 outline-none !ring-0 text-2xl font-bold mb-4"
                                        value={localTitle}
                                        onChange={handleTitleChange}
                                        onKeyPress={handleKeyPress}
                                    />
                                    : <h4 className='text-2xl font-bold mb-4' onClick={() => setEditTitle(true)}>{task?.title}</h4>}
                                {
                                    editDescription ?
                                        <Input
                                            ref={inputDisRef}
                                            id={`edit-title-${task?._id}`}
                                            lableClass="hidden"
                                            className="border-0 bg-transparent p-0 outline-none !ring-0 text-sm font-normal text-dark/70 leading-[1.8] tracking-wide"
                                            value={localDisc}
                                            onChange={handleDiscChange}
                                            onKeyPress={handleUpdateDisc}
                                        /> :
                                        <p className='text-sm font-normal text-dark/70 leading-[1.8] tracking-wide' onClick={() => setEditDescription(true)}>{task?.description}</p>
                                }
                            </div> */}
                            {/* <div className="dialog-header bg-primary/10 px-6 py-4 flex items-center justify-between">
                                <h4 className='text-sm font-medium text-[#25272D]'>
                                    {projectDetails?.name} / <span> {sprintDetails?.name} - {moment(sprintDetails?.startDate).format('DD/MM/YYYY')} - {moment(sprintDetails?.endDate).format('DD/MM/YYYY')}</span>
                                </h4>
                                <div className="action-part flex items-center gap-3">
                                    <h4 className='text-sm font-medium text-[#25272D]'>Created {moment(task.createdAt).format("DD MMM YYYY")}</h4>
                                    <Button onClick={() => setOpen(false)} size={"icon"} variant={"outline"} className={"w-6 h-6 -mt-0.5 rounded-full border-none  text-[#25272D] shadow-none"}>
                                        <X className='w-5 h-5' />
                                    </Button>
                                </div>
                            </div> */}
                            <div className="dialog-header bg-primary/10 px-5 py-3.5 flex items-center justify-between">
                                <div className="flex items-center">
                                    <h4 className="text-sm font-medium text-[#25272D]">
                                        {projectDetails?.name} /
                                        {
                                            (
                                                (user?.role !== 'admin' && user?.role !== 'project_manager') ||
                                                (sprintList?.length === 0 && (user?.role === 'admin' || user?.role === 'project_manager')) || isArchive || isReport
                                            ) && (
                                                <span className='ms-1'>
                                                    {sprintDetails?.name} - {moment(sprintDetails?.startDate).format('DD/MM/YYYY')} - {moment(sprintDetails?.endDate).format('DD/MM/YYYY')}
                                                </span>
                                            )
                                        }
                                    </h4>
                                    {
                                        (sprintList?.length > 0 && (user?.role === 'admin' || user?.role === 'project_manager') && !isArchive) && (
                                            <Listbox
                                                value={newType}
                                                onChange={(selectedSprint) => handleSprintChange(selectedSprint?._id)}
                                            >
                                                <ListboxButton
                                                    className={clsx(
                                                        `relative text-white capitalize font-semibold inline-block text-xs px-2 py-0.5`
                                                    )}
                                                >
                                                    <h4 className="text-sm font-medium text-[#25272D] flex items-center">
                                                        <span>{sprintDetails?.name} - {moment(sprintDetails?.startDate).format('DD/MM/YYYY')} - {moment(sprintDetails?.endDate).format('DD/MM/YYYY')}</span>
                                                        <span><ChevronDown className="w-4 h-4" /></span>
                                                    </h4>
                                                </ListboxButton>
                                                <ListboxOptions
                                                    anchor="bottom start"
                                                    transition
                                                    className={clsx(
                                                        'w-[240px] rounded-xl shadow-custom border border-white/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                                                        'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                                                    )}
                                                >
                                                    {
                                                        sprintList?.map((sprintDetailsItem) => (
                                                            <ListboxOption
                                                                key={sprintDetailsItem?._id}
                                                                value={sprintDetailsItem}
                                                                className="group text-dark bg-white flex cursor-pointer gap-2 rounded-lg py-1.5 px-2.5 select-none hover:bg-dark/5"
                                                            >
                                                                <h4 className="text-xs font-medium text-[#25272D]">
                                                                    <span>{sprintDetailsItem?.name} - {moment(sprintDetailsItem?.startDate).format('DD/MM/YYYY')} - {moment(sprintDetailsItem?.endDate).format('DD/MM/YYYY')}</span>
                                                                </h4>
                                                                <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible" />
                                                            </ListboxOption>
                                                        ))
                                                    }
                                                </ListboxOptions>
                                            </Listbox>
                                        )
                                    }
                                </div>
                                <div className="action-part flex items-center gap-3">
                                    <div className="items-center justify-end flex">
                                        <div className='p-1.5 px-4 bg-primary/20 text-xs font-semibold rounded-md inline-block '>{savingStatus}</div>
                                    </div>
                                    <h4 className="text-sm font-medium text-[#25272D]">Created {moment(task.createdAt).format("DD MMM YYYY")}</h4>
                                    <Button onClick={() => { setOpen(false); setReplyMode(false) }} size={"icon"} variant={"outline"} className={"w-6 h-6 -mt-0.5 rounded-full border-none text-[#25272D] shadow-none"}>
                                        <X className="w-5 h-5" />
                                    </Button>
                                </div>
                            </div>


                            <div className="grid grid-cols-12 flex-grow">
                                <div className="col-span-8 px-10 py-6 h-[calc(90vh-56px)]">
                                    <div className="h-full flex flex-col">
                                        <div className="heading-part">
                                            {editTitle ?
                                                <Input
                                                    ref={inputTitleRef}
                                                    id={`edit-title-${task?._id}`}
                                                    lableClass="hidden"
                                                    className="border-0 bg-transparent p-0 outline-none !ring-0 text-2xl font-bold mb-4"
                                                    value={localTitle}
                                                    onChange={handleTitleChange}
                                                    onKeyPress={handleKeyPress}
                                                />
                                                : <h4 className='text-2xl font-bold mb-4 leading-1' onClick={() => setEditTitle(true)}>{localTitle}</h4>}
                                        </div>
                                        <div className="h-full overflow-auto">
                                            <div className="grid grid-cols-2 gap-2">
                                                <div className="">
                                                    <ul className='flex flex-col gap-2.5'>
                                                        <li className='grid grid-cols-2 text-sm'>
                                                            <div className="flex items-center gap-1.5">
                                                                <CircleStop className="h-4 w-4" />
                                                                <h4 className='text-[#667085]'>Status</h4>
                                                            </div>
                                                            <div className="h-[26px]">
                                                                <Listbox
                                                                    value={newType}
                                                                    onChange={(newStatus) => { handleStatusChange(newStatus); setNewType(newStatus) }}
                                                                >
                                                                    <ListboxButton
                                                                        className={clsx(
                                                                            `relative text-white capitalize font-semibold  inline-block text-xs px-1 py-0.5 rounded-md border`,
                                                                            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                                        )}
                                                                        style={{ background: `${color?.bgColor}`, color: `${color?.color}`, borderColor: `${color?.color}` }}
                                                                    >
                                                                        <div className='flex items-center gap-1 [&>svg]:w-4'>
                                                                            {color?.icon}
                                                                            <span className='font-medium -mb-0.5'>{newType}</span>
                                                                        </div>
                                                                    </ListboxButton>
                                                                    <ListboxOptions
                                                                        anchor="bottom"
                                                                        transition
                                                                        className={clsx(
                                                                            'w-[210px] rounded-xl shadow-custom border border-white/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                                                                            'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                                                                        )}
                                                                    >
                                                                        {statusType?.map((status) => (
                                                                            <ListboxOption
                                                                                key={status?.id}
                                                                                value={status?.value}
                                                                                className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-lg py-1.5 px-2.5 select-none hover:bg-dark/5"
                                                                            >
                                                                                <div className="flex items-center gap-1.5">
                                                                                    {status?.icon}
                                                                                    <div className="text-xs ">{status?.name}</div>
                                                                                </div>
                                                                                <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible" />
                                                                            </ListboxOption>
                                                                        ))}
                                                                    </ListboxOptions>
                                                                </Listbox>
                                                            </div>
                                                        </li>
                                                        <li className='grid grid-cols-2 text-sm mb-0.5'>
                                                            <div className="flex items-center gap-1.5">
                                                                <Calendar className="h-4 w-4" />
                                                                <h4 className='text-[#667085]'>Dates</h4>
                                                            </div>
                                                            <DueDate taskDate={task?.dueDate} id={task?._id} handleDueDate={handleDueDate} className="py-0" />
                                                        </li>

                                                        {
                                                            menu &&
                                                            <li className='grid grid-cols-2 text-sm'>
                                                                <div className="flex items-center gap-1.5">
                                                                    <AlarmClock className="h-4 w-4" />
                                                                    <h4 className='text-[#667085]'>Track Time</h4>
                                                                </div>
                                                                <div className="flex items-center gap-1.5">
                                                                    {timerStatus ?
                                                                        <>
                                                                            <div className={`h-6 w-6 rounded-full bg-red-500 flex items-center justify-center ${trackBtn ? 'cursor-pointer' : 'cursor-none opacity-10'}`} onClick={() => handleStopTimer(userTimer[userTimer?.length - 1]?._id)}>
                                                                                <Square className='text-white fill-white h-3 w-3' />
                                                                            </div>
                                                                            <h4 className='text-gray-400 font-medium' onClick={() => setEntries(true)}>Stop Time</h4>
                                                                        </> :
                                                                        <>
                                                                            <div className={`h-5 w-5 rounded-full bg-primary flex items-center justify-center cursor-pointer ${trackBtn ? 'cursor-pointer' : 'cursor-none opacity-10'}`} onClick={() => handleToggleTimer(task?._id)}>
                                                                                <Play className='text-white fill-white h-2.5 w-2.5' />
                                                                            </div>
                                                                            <h4 className='text-gray-400 font-medium' onClick={() => setEntries(true)}>{userTimer ? formattedDuration : 'Start Time'}</h4>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="">
                                                    <ul className='flex flex-col gap-2.5'>
                                                        <li className="grid grid-cols-2 text-sm">
                                                            <div className="flex items-center gap-1.5">
                                                                <UserRound className="h-4 w-4" />
                                                                <h4 className="text-[#667085]">Assignees</h4>
                                                            </div>
                                                            <AssigneePopover
                                                                assignedTo={task?.assignedTo}
                                                                members={member}
                                                                taskId={task?._id}
                                                            />
                                                        </li>
                                                        <li className='grid grid-cols-2 text-sm'>
                                                            <div className="flex items-center gap-1.5">
                                                                <Flag className="h-4 w-4" />
                                                                <h4 className='text-[#667085]'>Priority</h4>
                                                            </div>
                                                            <div>
                                                                <PriorityDropdown listBoxButtonClassName="py-0" handleRemovePriority={handleRemovePriority} priority={task?.priority} id={task?._id} handlePriority={handlePriority} />
                                                            </div>
                                                        </li>
                                                        {
                                                            menu &&
                                                            <li className='grid grid-cols-2 text-sm'>
                                                                <div className="flex items-center gap-1.5">
                                                                    <Hourglass className="h-4 w-4" />
                                                                    <h4 className='text-[#667085]'>Time Estimate</h4>
                                                                </div>
                                                                <div className=""><EstimatedTimePopover className='bg-gray-50 p-1 rounded-md' id={task?._id} time={task?.estimatedHours ?? 0} addEstimatedTime={addEstimatedTime} removeEstimatedTime={removeEstimatedTime} /></div>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <TabGroup className={"h-full"}>
                                                    <TabList className="flex gap-4 border-b border-border w-fit">
                                                        <Tab
                                                            key={'details'}
                                                            className="outline-none px-4 py-1.5 text-sm whitespace-nowrap w-max flex-grow font-medium  data-[selected]:text-primary data-[selected]:!border-primary border-b-2 border-transparent"
                                                        >
                                                            Details
                                                        </Tab>
                                                        {
                                                            menu &&
                                                            <>
                                                                <Tab
                                                                    key={'sub-tasks'}
                                                                    className="outline-none px-4 py-1.5 text-sm whitespace-nowrap w-max flex-grow font-medium  data-[selected]:text-primary data-[selected]:!border-primary border-b-2 border-transparent"
                                                                >
                                                                    Sub Tasks
                                                                    {(task?.subtaskCount && task?.subtaskCount !== 0) ? <> ({task?.subtaskCount})</> : ''}
                                                                </Tab>
                                                                <Tab
                                                                    key={'bugs'}
                                                                    className="outline-none px-4 py-1.5 text-sm whitespace-nowrap w-max flex-grow font-medium  data-[selected]:text-primary data-[selected]:!border-primary border-b-2 border-transparent"
                                                                >
                                                                    Bugs
                                                                    {bugData?.length ? <> ({bugData?.length})</> : ''}
                                                                </Tab>
                                                                <Tab
                                                                    key={'time-entries'}
                                                                    className="outline-none px-4 py-1.5 text-sm whitespace-nowrap w-max flex-grow font-medium  data-[selected]:text-primary data-[selected]:!border-primary border-b-2 border-transparent"
                                                                >
                                                                    Time Entries
                                                                </Tab>
                                                            </>
                                                        }
                                                        {/* {
                                                            groupSet?.length > 0 && (
                                                                <Tab
                                                                    key={'time-entries'}
                                                                    className="outline-none px-4 py-1.5 text-sm whitespace-nowrap w-max flex-grow font-medium  data-[selected]:text-primary data-[selected]:!border-primary border-b-2 border-transparent"
                                                                >
                                                                    Time Entries
                                                                </Tab>
                                                            )
                                                        } */}
                                                    </TabList>
                                                    <TabPanels className="mt-3 p-0 flex-grow">
                                                        <TabPanel key={'details'} className="rounded-xl bg-white/5 h-full">
                                                            <div className="border rounded-lg">
                                                                <div className="relative h-full">
                                                                    <Editor initialValue={task?.description ?? ''} onChange={setContent} />
                                                                </div>
                                                            </div>
                                                        </TabPanel>
                                                        {
                                                            menu &&
                                                            <>
                                                                <TabPanel key={'sub-tasks'} className="rounded-xl bg-white/5">
                                                                    {subTaskList?.length > 0 ? (
                                                                        <>
                                                                            <div className="mt-2">
                                                                                <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                                                                                    <div className="w-full px-3.5">Name</div>
                                                                                    <div className="w-[20%]">Due Date</div>
                                                                                    <div className="w-[20%]">Priority</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="overflow-auto">
                                                                                {subTaskList?.map((subtask) => (
                                                                                    <SubTaskListItem
                                                                                        estimateTime={false}
                                                                                        tagList={tagList}
                                                                                        subtaskId={subtask?._id}
                                                                                        // fetchCurrentTask={fetchSubtask}
                                                                                        user="user"
                                                                                        task={subtask}
                                                                                        key={subtask._id}
                                                                                        sprintDetails={sprintDetails}
                                                                                        projectDetails={projectDetails}
                                                                                        type={task?.status}
                                                                                    />
                                                                                ))}
                                                                            </div>
                                                                        </>
                                                                    ) : ""}
                                                                    <Bugs title='New Subtask' task={task} addBugs={addSubTask} />
                                                                    {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur error atque deserunt? Quis eius vero doloremque, corporis, perspiciatis sit animi cumque odio nesciunt aperiam error sunt culpa? Odit, quia magni. */}
                                                                </TabPanel>
                                                                <TabPanel key={'bugs'} className="rounded-xl bg-white/5">
                                                                    {bugData?.length > 0 ? (
                                                                        <>
                                                                            <div className="mt-2">
                                                                                <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                                                                                    <div className="w-full px-3.5">Name</div>
                                                                                    <div className="w-[20%]">Due Date</div>
                                                                                    <div className="w-[20%]">Priority</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="">
                                                                                {bugData.map((bug) => (
                                                                                    <BugListItem
                                                                                        estimateTime={false}
                                                                                        bugId={bug?._id}
                                                                                        tagList={tagList}
                                                                                        fetchCurrentTask={fetchBugs}
                                                                                        user="user"
                                                                                        task={bug}
                                                                                        key={bug._id}
                                                                                        sprintDetails={sprintDetails}
                                                                                        projectDetails={projectDetails}
                                                                                        type={task?.status}
                                                                                    />
                                                                                ))}
                                                                            </div>
                                                                        </>
                                                                    ) : ""}
                                                                    <Bugs title='New Bug' task={task} addBugs={addBugs} />
                                                                    {/* 'bug' */}
                                                                </TabPanel>
                                                                <TabPanel key={'time-entries'} className="rounded-xl bg-white/5">
                                                                    {groupSet?.length > 0 ?
                                                                        <div className="">
                                                                            {
                                                                                groupSet?.map((user) => {
                                                                                    const hours = Math.floor(user?.totalDuration / 3600);
                                                                                    const minutes = Math.floor((user?.totalDuration % 3600) / 60);
                                                                                    const seconds = Math.floor(user?.totalDuration % 60);

                                                                                    const formattedTime = `${hours.toString().padStart(2, '0')}h:${minutes.toString().padStart(2, '0')}m:${seconds.toString().padStart(2, '0')}s`;
                                                                                    return (
                                                                                        <div>
                                                                                            <div className="flex items-center justify-between py-2 px-2.5">
                                                                                                <div className="flex items-center gap-2">
                                                                                                    <span className='font-medium uppercase h-6 w-6 text-white rounded-full flex items-center justify-center text-xs' style={{ background: `${getColorFromName(user?.userInfo?.name)}` }}>{user?.userInfo?.name?.split(' ').slice(0, 2).map(name => name.slice(0, 1).toUpperCase())}</span>
                                                                                                    <h4 className='text-sm text-dark'>{user?.userInfo?.name}</h4>
                                                                                                </div>
                                                                                                <p className='text-sm text-dark font-semibold'>{formattedTime}</p>
                                                                                            </div>
                                                                                            <ul className='border border-border'>
                                                                                                {user?.tasks?.map((task) => {

                                                                                                    const hours = Math.floor(task?.duration / 3600);
                                                                                                    const minutes = Math.floor((task?.duration % 3600) / 60);
                                                                                                    const seconds = Math.floor(task?.duration % 60);

                                                                                                    const taskTime = `${hours.toString().padStart(2, '0')}h:${minutes.toString().padStart(2, '0')}m:${seconds.toString().padStart(2, '0')}s`;
                                                                                                    return (
                                                                                                        <li className='flex items-center justify-between text-muted border-b last:border-0 border-[#F1F2FB] rounded-md p-2'>
                                                                                                            <div className="flex items-center gap-2">
                                                                                                                <span><Timer className='w-5 h-5 text-dark/50' /></span>
                                                                                                                <h4 className='text-sm'>{moment(task?.startTime).format('ddd, DD MMM YYYY')} {moment(task?.startTime).format('h:mm a')} - {moment(task?.endTime).format('h:mm a')}</h4>
                                                                                                            </div>
                                                                                                            <p className='text-sm text-dark/50'>{taskTime}</p>
                                                                                                        </li>
                                                                                                    )
                                                                                                }
                                                                                                )}
                                                                                            </ul>
                                                                                        </div>
                                                                                    )

                                                                                })
                                                                            }
                                                                        </div> : ''
                                                                    }
                                                                </TabPanel>
                                                            </>
                                                        }

                                                    </TabPanels>
                                                </TabGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-4 border-s border-border">
                                    <div className="activity-header p-3 border-b border-border">
                                        <h4 className="text-xl leading-[1.45] text-dark font-semibold">Activity</h4>
                                    </div>
                                    <div ref={scrollableDivRef} className="activity-body p-3 border-b border-border h-[calc(90vh-167px)] overflow-auto bg-gray-100">
                                        {!replyMode ? <div className="flex flex-col gap-2">
                                            {
                                                activityCommentsList?.length > 0 &&
                                                groupedActivities.map((group, index) => {
                                                    const isCommentGroup = group.length === 1 && group[0].type === "comment";
                                                    const showMore = toggleSections[index] || group.length <= 2;

                                                    return (
                                                        <div key={index} className="group mb-4">
                                                            {/* If it's a comment group, render the CommentCard */}
                                                            {isCommentGroup ? (
                                                                <CommentCard
                                                                    reply={true}
                                                                    activity={group[0]}
                                                                    replyCount={group[0]?.replies?.length}
                                                                    taskId={task?._id}
                                                                    setReplyMode={setReplyMode}
                                                                    setCommentDetails={setCommentDetails}
                                                                />
                                                            ) : (
                                                                <div className='flex flex-col gap-1'>
                                                                    {/* Render activity group with "Show More" toggle */}
                                                                    <ul className="flex flex-col gap-1">
                                                                        {group
                                                                            .slice(showMore ? 0 : -1) // Show all if `showMore`, otherwise show only the last one
                                                                            .map((activity, activityIndex) =>
                                                                                activity.type === 'activity' ?
                                                                                    <li key={activityIndex} className="flex items-center justify-between gap-3 ps-4 relative after:absolute after:h-1.5 after:w-1.5 after:rounded-full after:bg-dark/50 after:start-0">
                                                                                        <p className="text-sm truncate">{activity?.content?.replace(new RegExp(user?.name, 'gi'), "You")}</p>
                                                                                        <span className="text-xs text-dark/50 flex-shrink-0">
                                                                                            {moment(activity?.createdAt).format("ddd, DD MMM hh:mm:ss A")}
                                                                                        </span>
                                                                                    </li> : ''
                                                                            )}
                                                                    </ul>
                                                                    {group.length > 2 && (
                                                                        <button
                                                                            onClick={() => toggleSection(index)}
                                                                            className="text-dark/50 text-sm flex items-center gap-1"
                                                                        >
                                                                            <span><ChevronDown className={`w-4 h-4 ${showMore ? 'rotate-180' : 'rotate-0'}`} /></span>
                                                                            {showMore ? "Hide" : "Show More"}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div> :
                                            <div className="">
                                                <div className="border-b border-secondary pb-1.5 mb-2">
                                                    <Button className='h-6 w-6 p-0 bg-gray-50 hover:bg-gray-100 text-dark' onClick={() => setReplyMode(false)}><ArrowLeft className='h-4 w-4' /></Button>
                                                </div>
                                                <CommentCard reply={false} activity={commentDetails} taskId={task?._id} setReplyMode={setReplyMode} setCommentDetails={setCommentDetails} />
                                                {(() => {
                                                    const replyData = activityCommentsList?.filter(
                                                        (activity) => activity.parentId?._id === commentDetails._id
                                                    );

                                                    return (
                                                        <>
                                                            {
                                                                replyData?.length ?
                                                                    <>
                                                                        <div className="">
                                                                            <p className="text-gray-400 py-1">{replyData?.length} reply</p>
                                                                        </div>
                                                                        <div className="flex flex-col gap-2">
                                                                            {replyData.map((reply) => (
                                                                                <ReplyCard key={reply._id} activity={reply} taskId={task?._id} />
                                                                            ))}
                                                                        </div>
                                                                    </> : ''
                                                            }
                                                        </>
                                                    );
                                                })()}

                                                {/* <ReplyCard activity={commentDetails} taskId={task?._id} /> */}
                                            </div>
                                        }
                                    </div>
                                    <div className="activity-footer p-3">
                                        {
                                            replyMode ?
                                                <Input
                                                    placeholder="Write a reply..."
                                                    className=" rounded-md px-4 py-2 focus:outline-none focus:ring-dark/10 pe-16"
                                                    value={replyValue}
                                                    onChange={(e) => setReplyValue(e.target.value)}
                                                    onKeyPress={handleKeyPressToReply}
                                                    prefix={
                                                        <Button
                                                            className='h-auto p-1 px-2'
                                                            disabled={replyValue ? false : true}
                                                            onClick={() => addReplyMutation()}
                                                        >{send ? <Check /> : 'Send'}</Button>
                                                    }
                                                    prefixClass='right-1'
                                                /> :
                                                <Input
                                                    placeholder="Write a comment..."
                                                    className=" rounded-md px-4 py-2 focus:outline-none focus:ring-dark/10 pe-16"
                                                    value={commentValue}
                                                    onChange={(e) => setCommentValue(e.target.value)}
                                                    onKeyPress={handleKeyPressToComment}
                                                    prefix={
                                                        <Button
                                                            className='h-auto p-1 px-2'
                                                            disabled={commentValue ? false : true}
                                                            onClick={() => { addCommentMutation(); }}
                                                        >Send</Button>
                                                    }
                                                    prefixClass='right-1'
                                                />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </div >
            </div >
        </Dialog >
    )
})

export default TaskDetailModal