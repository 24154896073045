import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ChevronRightIcon, MenuIcon, Square } from 'lucide-react'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import LiveTimer from '../common/LiveTimer'
import ProfileDropdown from '../common/ProfileDropdown'
import { Button } from '../common/ui/Button'
import Tooltip from '../common/ui/Tooltip'
import { useAuth } from '../context/AuthContext'
import { getIcon } from '../lib/utils'
import API from '../services/api'
import AddProjectModal from './modal/AddProjectModal'
import ChangePasswordModal from './modal/ChangePasswordModal'
import SelectSprintStatus from '../common/SelectSprintStatus'

const Header = ({ openSidebar, setOpenSidebar }) => {
    const queryClient = useQueryClient()
    const { logout } = useAuth()
    const { pathname } = useLocation()
    const { user } = useAuth()
    const { projectId, sprintId } = useParams();
    const childRef = useRef();
    const [open, setOpen] = useState(false)
    const [activeTask, setActiveTask] = useState()
    const [timers, setTimers] = useState([]);
    const [passModal, setPassModal] = useState(false);
    const [newProject, setNewProject] = useState({ name: '', description: '', startDate: '', endDate: '', createdBy: '', team: [] });

    const handleAddProject = async (data) => {
        try {
            await API.post('/project', data);
            setNewProject({ name: '', description: '', startDate: '', endDate: '', createdBy: '', team: [] });
            setOpen(false);
            toast.success('Project added successfully')
            queryClient.invalidateQueries({ queryKey: ['projectList'] });
        } catch (err) {
            if (err.status === 401) {
                logout()
            } else {
                const errorMessage = Object.entries(err.response?.data?.errors)[0][1]
                toast.error(errorMessage)
            }
        }
    };

    let headerTitle;

    console.log(openSidebar)

    switch (true) {
        case pathname.includes('/home'):
            headerTitle = <span className='flex items-center gap-1 text-[#475467]'>Home</span>;
            break;
        case pathname.includes('/task-analytics'):
            headerTitle = <span className='flex items-center gap-1 text-[#475467]'>Task Analytics</span>;
            break;
        case pathname.includes('/task-tracker'):
            headerTitle = <span className='flex items-center gap-1 text-[#475467]'>Task Tracker</span>;
            break;
        case pathname.includes('/user'):
            headerTitle = <span className='flex items-center gap-1 text-[#475467]'>Team</span>;
            break;
        case pathname.includes('/timesheet'):
            headerTitle = <span className='flex items-center gap-1 text-[#475467]'>Timesheet</span>;
            break;
        case pathname.includes('/tags'):
            headerTitle = <span className='flex items-center gap-1 text-[#475467]'>Tags</span>;
            break;
        case pathname.includes('/archive'):
            headerTitle = <span className='flex items-center gap-1 text-[#475467]'>Archive</span>;
            break;
        default:
            headerTitle = null;
            break;
    }

    const fetchProjectDetails = async () => {
        const { data } = await API.get(`project/${projectId}`);
        return data;
    };
    const fetchSprintDetails = async () => {
        const { data } = await API.get(`sprint/${sprintId}`);
        return data;
    };
    const { data: projectDetails } = useQuery({
        queryKey: ['project', projectId], queryFn: fetchProjectDetails, enabled: !!projectId
    });
    const { data: sprintDetails } = useQuery(
        { queryKey: ['sprint', sprintId], queryFn: fetchSprintDetails, enabled: !!sprintId }
    );

    const handleStopTimer = async (timerId) => {
        try {
            let data = await API.post('/timer/stop', { timerId });
            fetchTimersAndTasks();
            // fetchCurrentTask();
            if (childRef.current) {
                childRef.current.fetchTimersAndTasks();
            }
            queryClient.invalidateQueries({ queryKey: ['todays-hour'] });
            setActiveTask(null)
        } catch (err) {
            if (err.status === 401) {
                logout()
            }
        }
    };

    const fetchTodaysHour = async () => {
        try {
            const { data } = await API.get('/auth/user/today-hours');
            return data
        } catch (err) {
            console.error(err.message);
        }
    };

    const { data: activeTaskData } = useQuery({
        queryKey: ['todays-hour'], queryFn: fetchTodaysHour
    });

    const fetchTimersAndTasks = async () => {
        try {
            const [timersRes] = await Promise.all([API.get('/timer')]);
            setTimers(timersRes.data);
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        fetchTimersAndTasks()
    }, []);

    const isActiveTimer = activeTaskData?.data.activeTimer

    return (
        <>
            <div className="px-5 border-b border-border py-1.5">
                <div className="flex items-center justify-between">
                    <h2 className='py-3 text-[#98A1B2] flex items-center gap-1 text-sm'>
                        {
                            pathname.includes('/project') ?
                                <div className='flex items-center gap-2'>
                                    <span className='flex items-center gap-1 text-[#475467]'>Project <span><ChevronRightIcon className='w4 h-4' /></span></span>  {projectDetails?.name}  <span><ChevronRightIcon className='w4 h-4' /></span> <span>{getIcon(sprintDetails?.status)}</span> <span className='truncate'>{sprintDetails?.name} - {moment(sprintDetails?.startDate).format('DD/MM/YYYY')} - {moment(sprintDetails?.endDate).format('DD/MM/YYYY')}</span>
                                    {
                                        user?.role === 'admin' && (
                                            <div className="border-s border-[#ECEDFF] ps-2">
                                                <SelectSprintStatus sprint={sprintDetails} />
                                            </div>
                                        )
                                    }
                                </div> :
                                headerTitle
                        }
                    </h2>
                    <div className="flex items-center gap-4">
                        {activeTaskData?.data?.activeTimer &&
                            <Tooltip
                                detailClass='top-12 right-0 left-[unset] translate-x-0 bg-[#eeeffb] text-[#475467] p-2 cursor-pointer'
                                arrowClass='rotate-180 -top-1 left-[unset] translate-x-0 right-20'
                                borderColor='border-t-[#eeeffb]'
                                text={
                                    <div className='flex items-center'><span className='flex items-center gap-1 text-[#475467]'>{activeTaskData?.data.activeTimer.taskId.projectId.name} <span><ChevronRightIcon className='w4 h-4' /></span></span>  <span className='truncate'>{activeTaskData?.data.activeTimer.taskId?.sprintId?.name} - {moment(activeTaskData?.data.activeTimer.taskId?.sprintId?.startDate).format('DD/MM/YYYY')} - {moment(activeTaskData?.data.activeTimer.taskId?.sprintId?.endDate).format('DD/MM/YYYY')}</span> <span><ChevronRightIcon className='w4 h-4' /></span> <span className='font-semibold text-primary'>{activeTaskData?.data.activeTimer.taskId?.title} </span></div>
                                }
                            >
                                <div className="flex items-center gap-2.5 cursor-pointer bg-[#FCFCFD] border border-[#EAECF0] p-2 rounded-lg">
                                    {isActiveTimer && <LiveTimer initialElapsedSeconds={activeTaskData?.data?.activeTimer?.elapsedTime} />}
                                    <Button size={"icon"} className={"h-6 w-6 rounded-full bg-red-500 flex items-center justify-center"} onClick={() => handleStopTimer(activeTaskData?.data.activeTimer._id)}>
                                        <Square className='text-white fill-white h-3 w-3' />
                                    </Button>
                                </div>
                            </Tooltip>
                        }
                        <ProfileDropdown setOpen={setOpen} setPassModal={setPassModal} />
                        <MenuIcon className='lg:hidden' onClick={() => setOpenSidebar((prev) => !prev)} />
                    </div>
                </div>
            </div>
            <AddProjectModal open={open} setOpen={setOpen} newProject={newProject} setNewProject={setNewProject} handleAddProject={handleAddProject} />
            <ChangePasswordModal passModal={passModal} setPassModal={setPassModal} />
        </>
    )
}

export default Header