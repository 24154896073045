import IconColse from '../common/svgIcons/IconClose';
import IconComplete from '../common/svgIcons/IconComplete';
import IconDevelopment from '../common/svgIcons/IconDevelopment';
import IconDone from '../common/svgIcons/IconDone';
import IconFlag from '../common/svgIcons/IconFlag';
import IconHold from '../common/svgIcons/IconHold';
import IconInProgress from '../common/svgIcons/IconInProgress';
import IconReadyTesting from '../common/svgIcons/IconReadyTesting';
import IconReOpen from '../common/svgIcons/IconReOpen';
import IconReview from '../common/svgIcons/IconReview';
import IconTodo from '../common/svgIcons/IconTodo';

export const colorMap = {
    a: "#FF6F61",
    b: "#6B9F67",
    c: "#5A6F91",
    d: "#D8A02A",
    e: "#D76C9E",
    f: "#62C8C2",
    g: "#9933ff",
    h: "#5BFF82",
    i: "#9B7FCD",
    j: "#D96BE7",
    k: "#A3A600",
    l: "#70CFAE",
    m: "#68B9D6",
    n: "#7A9FD1",
    o: "#FF7D3A",
    p: "#67CC57",
    q: "#A4E37A",
    r: "#6DB4D2",
    s: "#F27D71",
    t: "#72F34F",
    u: "#F18BC2",
    v: "#F1E15D",
    w: "#F27A8F",
    x: "#6AE3E5",
    y: "#F1BC5D",
    z: "#7AEB71",
};

export const priorityTypes = [
    { id: 1, value: 'Urgent', name: 'Urgent', icon: <IconFlag className="text-[#B13A41] h-5 w-5" />, color: '#FF4D4D', bgColor: '#FF4D4D33' },
    { id: 2, value: 'High', name: 'High', icon: <IconFlag className="text-[#CF9409] h-5 w-5" />, color: '#FA9600', bgColor: '#FA960033' },
    { id: 3, value: 'Medium', name: 'Medium', icon: <IconFlag className="text-[#4366FF] h-5 w-5" />, color: '#FFC711', bgColor: '#FFCD2926' },
    { id: 4, value: 'Low', name: 'Low', icon: <IconFlag className="text-[#87909E] h-5 w-5" />, color: '#3B82F6', bgColor: '#3B82F633' },
]

export const statusType = [
    { id: 1, value: 'To Do', name: 'To Do', color: '#D18101', bgColor: '#D1810126', icon: <IconTodo className="h-5 w-5 text-[#D18101]" /> },
    { id: 1, value: 'Ready for Development', name: 'Ready for Development', color: '#01ABD1', bgColor: '#01ABD126', icon: <IconDevelopment className="h-5 w-5 text-[#01ABD1]" /> },
    { id: 1, value: 'In Progress', name: 'In Progress', color: '#004AEB', bgColor: '#004AEB26', icon: <IconInProgress className="h-5 w-5 text-[#004AEB]" /> },
    { id: 1, value: 'Hold', name: 'Hold', color: '#FA9600', bgColor: '#FA960026', icon: <IconHold className="h-5 w-5 text-[#FA9600]" /> },
    { id: 1, value: 'Done', name: 'Done', color: '#32ADE6', bgColor: '#32ADE626', icon: <IconDone className="h-5 w-5 text-[#32ADE6]" /> },
    { id: 1, value: 'Ready for Test', name: 'Ready for Test', color: '#AF52DE', bgColor: '#AF52DE26', icon: <IconReadyTesting className="h-5 w-5 text-[#AF52DE]" /> },
    { id: 1, value: 'Re Open', name: 'Re Open', color: '#FF5D7C', bgColor: '#FFE7EC', icon: <IconReOpen className="h-5 w-5 text-[#FF5D7C]" /> },
    { id: 1, value: 'In Review', name: 'In Review', color: '#00C7BE', bgColor: '#00C7BE26', icon: <IconReview className="h-5 w-5 text-[#00C7BE]" /> },
    { id: 1, value: 'Complete', name: 'Complete', color: '#34C759', bgColor: '#34C75926', icon: <IconComplete className="h-5 w-5 text-[#34C759]" /> },
    { id: 1, value: 'Closed', name: 'Closed', color: '#008748', bgColor: '#00AC4826', icon: <IconColse className="h-5 w-5 text-[#008748]" /> },
]

export const roleTypes = [
    { id: 1, value: 'admin', name: 'Admin' },
    { id: 2, value: 'project_manager', name: 'Project Manager' },
    { id: 3, value: 'developer', name: 'Developer' },
    { id: 4, value: 'quality_assurance', name: 'Quality Assurance' },
]