import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

API.interceptors.request.use((req) => {
  const token = localStorage.getItem("token");
  if (token) req.headers.Authorization = `Bearer ${token}`;
  return req;
});

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let prevRequest = error.config;
    if (
      error.status === 401 &&
      (prevRequest.retry == undefined || prevRequest.retry)
    ) {
      prevRequest.retry = true;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.pathname = "/login";
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default API;
