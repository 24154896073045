import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Login from "./components/Login";
import { useAuth } from "./context/AuthContext";
import Dashboard from "./pages/Dashboard";
import TimeSheet from "./pages/TimeSheet";
import DashboardLayout from "./layoutes/DashboardLayout";
import Home from "./pages/Home";
import Users from "./pages/Users";
import Tags from "./pages/Tags";
import Archive from "./pages/Archive";
import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase-config";
import { requestNotificationPermission } from "./firebase-notifications";
import TaskAnalytics from "./pages/TaskAnalytics";
import TaskTracker from "./pages/TaskTracker";

function App() {
  const { isAuthenticated, setIsAuthenticated } = useAuth();

  // // Create the navigate function inside App, where it is properly inside the Router context
  // const navigate = useNavigate();

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to="/home" replace />
              ) : (
                <Login onLogin={handleLogin} />
              )
            }
          />
          {isAuthenticated ? (
            <>
              <Route path="/" element={<Navigate to="/home" replace />} />
              <Route path="/" element={<DashboardLayout />}>
                <Route
                  path="home"
                  element={<Home setIsAuthenticated={setIsAuthenticated} />}
                />
                <Route
                  path="tags"
                  element={<Tags setIsAuthenticated={setIsAuthenticated} />}
                />
                <Route
                  path="archive"
                  element={<Archive setIsAuthenticated={setIsAuthenticated} />}
                />
                <Route
                  path="task-analytics"
                  element={<TaskAnalytics setIsAuthenticated={setIsAuthenticated} />}
                />
                <Route
                  path="task-tracker"
                  element={<TaskTracker setIsAuthenticated={setIsAuthenticated} />}
                />
                <Route
                  path="tags"
                  element={<Tags setIsAuthenticated={setIsAuthenticated} />}
                />
                <Route
                  path="project/:projectId/:sprintId"
                  element={
                    <Dashboard setIsAuthenticated={setIsAuthenticated} />
                  }
                />
                <Route
                  path="timesheet"
                  element={
                    <TimeSheet setIsAuthenticated={setIsAuthenticated} />
                  }
                />
                <Route
                  path="user"
                  element={<Users setIsAuthenticated={setIsAuthenticated} />}
                />
              </Route>
            </>
          ) : (
            <Route path="*" element={<Navigate to="/login" replace />} />
          )}
        </Routes>
      </div>
      <ToastContainer autoClose={2000} pauseOnHover={false} />
    </Router>
  );
}

export default App;
