import React from 'react'
import NoDataUI from '../common/NoDataUI'
import HomePage from '../components/home'

const Home = () => {
    return (
        // <div className='px-5 bg-white rounded-lg h-full overflow-hidden flex items-center justify-center'>
        //     <NoDataUI title={"Coming Soon!"} />
        // </div>
        <HomePage />
    )
}

export default Home