import { useQuery } from '@tanstack/react-query';
import React from 'react';
import API from '../services/api';
import TaskReportCard from '../common/TaskReportCard';
import Loader from '../common/ui/Loader';

const TaskTracker = () => {
  const categorizeTasks = (data) => {
    const activeTasks = [];
    const inactiveTasks = [];
    data.forEach(item => {
      if (item.hasActiveTimer) {
        activeTasks.push(item);
      } else {
        inactiveTasks.push(item);
      }
    });
    return [
      {
        title: 'Free',
        data: inactiveTasks
      },
      {
        title: 'Active',
        data: activeTasks
      }
    ];
  };

  const fetchTasksTrackersData = async () => {
    try {
      const { data } = await API.get(`/report/user-tasks`);
      return categorizeTasks(data.data);
    } catch (err) {
      console.error(err.message);
      return [];
    }
  };

  const { data: reportData, isLoading } = useQuery({
    queryKey: ['task-tracker'],
    queryFn: fetchTasksTrackersData
  });
  return (
    isLoading ? <Loader /> :
      (
        <section className='p-5'>
          <div className="">
            <div className={`overflow-auto mt-2`}>
              <div className='flex flex-col gap-2.5'>
                {
                  reportData?.map((reportCategory) => {
                    return (
                      <div className="">
                        <h2 className='header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5 px-2.5 mb-2'>{reportCategory.title} ({reportCategory.data.length})</h2>
                        {
                          reportCategory.data.length === 0 &&
                          <div className="bg-gray-50 border border-border text-muted p-2 py-2.5 rounded-md text-center">No Active User found</div>
                        }
                        <div className="grid grid-cols-3 gap-2">
                          {
                            reportCategory?.data?.map((report, index) => {
                              return <TaskReportCard key={index} report={report} title={reportCategory.title} />
                            })
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </section>
      )
  );
};

export default TaskTracker;
