import { useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { motion } from 'framer-motion';
import { ChevronDown, ChevronUp, CirclePlus, Copy, Ellipsis, Share2 } from 'lucide-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import IconFolder from '../common/svgIcons/IconFolder';
import { Button, buttonVariants } from '../common/ui/Button';
import { cn, getIcon } from '../lib/utils';
import ShareModal from '../components/modal/ShareModal';
import { useAuth } from '../context/AuthContext';
import CreateSprintConfirmationModal from '../components/modal/CreateSprintConfirmationModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../services/api';
import { toast } from 'react-toastify';
import IconCicularPlus from './svgIcons/IconCicularPlus';
import * as Tooltip from "@radix-ui/react-tooltip";

const ListItem = ({ project, expandedProjects, handleParentClick, toggleProject, sprintId }) => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const [openCreateSprintConfirmationModal, setOpenCreateSprintConfirmationModal] = useState(false)
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const [projectItem, setProjectItem] = useState(false);
  const { mutate: createSprintMutation } = useMutation({
    mutationFn: async () => {
      const response = await API.post(`/sprint/projects/${project._id}/sprints`);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['all-project'] });
      toast.success('Sprint Created Successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Failed to Create Strint');
    }
  });

  const handleConfirmation = () => {
    createSprintMutation()
  }
  return (
    <>
      <li key={project._id}>
        <div
          className={cn("hover:text-primary rounded-lg hover:bg-primary/10 group font-semibold text-[#475467] flex items-center py-2 ps-9 pe-[36px] gap-1.5 text-[13px] relative", {
            "text-primary": expandedProjects.includes(project._id)
          })}
          onClick={() => handleParentClick(project)}
        >
          <div
            className="toogle-action absolute top-1/2 -translate-y-1/2 left-2"
            onClick={(e) => {
              e.stopPropagation();
              toggleProject(project._id);
            }}
          >
            <Button size={"icon"} className={"w-5 h-5 items-center justify-center p-0 ml-auto flex-shrink-0 group-hover:flex hidden"}>
              {expandedProjects.includes(project._id) ? (
                <ChevronUp className='w-4 h-4' />
              ) : (
                <ChevronDown className='w-4 h-4' />
              )}
            </Button>
            <span className='group-hover:hidden flex'><IconFolder className='w-5 h-5 text-[##475467]' /></span>
          </div>
          <div className="text-content cursor-default">
            <p className='truncate' style={{ lineHeight: 'normal' }}>{project.name}</p>
          </div>
          {
            user?.role === 'admin' &&
            <div
              className="menu-action absolute top-1/2 -translate-y-1/2 right-2 mt-[2.5px]"
            >
              <Menu>
                <MenuButton onClick={(e) => e.stopPropagation()} className={cn(buttonVariants({ className: "w-5 h-5 items-center justify-center p-0 ml-auto flex-shrink-0 border-none shadow-none", variant: 'outline', size: "icon" }))}>
                  <Ellipsis className='w-4 h-4' />
                </MenuButton>
                <MenuItems
                  transition
                  anchor="bottom end"
                  className="w-[150px] origin-top-right rounded-xl border-2 border-border bg-white shadow-lg p-1 text-xs transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                >
                  <MenuItem className="mb-1">
                    <button className="group flex w-full items-center gap-1.5 rounded-lg py-1.5 px-2 data-[focus]:bg-primary data-[focus]:text-white ">
                      <Copy className="size-3.5 " />
                      Copy Link
                    </button>
                  </MenuItem>
                  {
                    user?.role === 'admin' && (
                      <MenuItem className="mb-1">
                        <button className="group flex w-full items-center gap-1.5 rounded-lg py-1.5 px-2 data-[focus]:bg-primary data-[focus]:text-white " onClick={(e) => { e.stopPropagation(); setOpenCreateSprintConfirmationModal(true) }}>
                          <CirclePlus className="size-3.5 " />
                          Create Sprint
                        </button>
                      </MenuItem>
                    )
                  }
                  <MenuItem className="">
                    <button className="group flex w-full items-center gap-1.5 rounded-lg py-1.5 px-2 data-[focus]:bg-primary data-[focus]:text-white " onClick={(e) => { e.stopPropagation(); setShareModalOpen(true); setProjectItem(project) }}>
                      <Share2 className="size-3.5 " />
                      Share
                    </button>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          }
        </div>
        <motion.div
          initial={{ height: 0, overflow: 'hidden' }}
          animate={{
            height: expandedProjects.includes(project._id) ? 'auto' : 0,
            overflow: 'hidden',
          }}
          exit={{ height: 0, overflow: 'hidden' }}
          transition={{ duration: 0.15 }}
        >
          <div className="">
            <ul>
              {project?.sprints?.map((sprint, index) => (
                <Tooltip.Root delayDuration={0}>
                  <Tooltip.Trigger asChild>
                    <li key={index} className='group'>
                      <Link
                        to={`/project/${project._id}/${sprint._id}`}
                        className={cn(
                          `text-xs ps-6 pe-3 py-1.5 group-hover:bg-primary/10 rounded-lg cursor-pointer font-semibold overflow-hidden whitespace-nowrap !flex items-center gap-2 text-ellipsis hover:text-primary ${sprintId === sprint._id ? 'text-primary' : 'text-[#475467]'}`,
                          { "text-primary": '' }
                        )}
                      >
                        <span>{getIcon(sprint.status)}</span>
                        <span className="truncate">
                          <span className='me-1'> {sprint.name}</span>
                          <span className=''>- {moment(sprint.startDate).format("DD/MM/YYYY")}-{moment(sprint.endDate).format("DD/MM/YYYY")}</span>
                        </span>
                      </Link>
                    </li>
                  </Tooltip.Trigger>
                  <Tooltip.Portal>
                    <Tooltip.Content align='center' side='right' className="bg-muted  rounded-md p-1 text-xs font-semibold text-white" sideOffset={5}>
                      <span className='me-1'> {sprint.name}</span>
                      <span className=''>- {moment(sprint.startDate).format("DD/MM/YYYY")}-{moment(sprint.endDate).format("DD/MM/YYYY")}</span>
                      <Tooltip.Arrow className="TooltipArrow fill-muted" />
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              ))}
              {
                user.role === 'admin' && <li className='group'>
                  <button
                    onClick={() => setOpenCreateSprintConfirmationModal(true)}
                    className={cn(
                      `text-xs ps-6 pe-3 py-1.5 w-full group-hover:bg-primary/10 rounded-lg  text-[#475467] cursor-pointer font-semibold overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:text-primary`,
                      { "text-primary": '' }
                    )}>
                    <IconCicularPlus className='w-4 h-4' /> Create Sprint
                  </button>
                </li>
              }
            </ul>
          </div>
        </motion.div>
      </li >
      <CreateSprintConfirmationModal open={openCreateSprintConfirmationModal} onConfirmation={handleConfirmation} close={() => setOpenCreateSprintConfirmationModal(false)} />
      <ShareModal isOpen={shareModalOpen} setIsOpen={setShareModalOpen} project={projectItem} />
    </>
  )
}

export default ListItem