import React from 'react'

const IconFolder = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" d="M16.25 6.875V8.75H11.4391C11.3157 8.7502 11.1951 8.7866 11.0922 8.85469L9.52969 9.89531C9.4268 9.9634 9.30619 9.9998 9.18281 10H5.42344C5.29889 9.99994 5.17716 10.0371 5.07388 10.1067C4.97059 10.1763 4.89046 10.2752 4.84375 10.3906L2.5 16.25V5C2.5 4.83424 2.56585 4.67527 2.68306 4.55806C2.80027 4.44085 2.95924 4.375 3.125 4.375H7.29141C7.42664 4.375 7.55822 4.41886 7.66641 4.5L9.83359 6.125C9.94178 6.20614 10.0734 6.25 10.2086 6.25H15.625C15.7908 6.25 15.9497 6.31585 16.0669 6.43306C16.1842 6.55027 16.25 6.70924 16.25 6.875Z" fill="currentColor" />
            <path d="M19.1406 8.64375C19.0246 8.48292 18.872 8.352 18.6954 8.2618C18.5188 8.1716 18.3233 8.12471 18.125 8.125H16.875V6.875C16.875 6.54348 16.7433 6.22554 16.5089 5.99112C16.2745 5.7567 15.9565 5.625 15.625 5.625H10.2086L8.04219 4C7.82545 3.83842 7.56252 3.75078 7.29219 3.75H3.125C2.79348 3.75 2.47554 3.8817 2.24112 4.11612C2.0067 4.35054 1.875 4.66848 1.875 5V16.25C1.875 16.4158 1.94085 16.5747 2.05806 16.6919C2.17527 16.8092 2.33424 16.875 2.5 16.875H16.4922C16.6234 16.875 16.7512 16.8337 16.8577 16.757C16.9641 16.6803 17.0437 16.5721 17.0852 16.4477L19.3109 9.77031C19.3735 9.58243 19.3907 9.38238 19.3611 9.18657C19.3315 8.99076 19.256 8.80474 19.1406 8.64375ZM7.29219 5L9.45859 6.625C9.67528 6.78668 9.93824 6.87433 10.2086 6.875H15.625V8.125H11.4398C11.1929 8.12499 10.9515 8.19812 10.7461 8.33516L9.18359 9.375H5.42344C5.17339 9.37431 4.92893 9.44891 4.72187 9.58908C4.5148 9.72926 4.35474 9.92852 4.2625 10.1609L3.125 13.0047V5H7.29219ZM16.0422 15.625H3.42344L5.42344 10.625H9.18594C9.43287 10.625 9.67427 10.5519 9.87969 10.4148L11.4422 9.375H18.125L16.0422 15.625Z" fill="currentColor" />
        </svg>
    )
}

export default IconFolder