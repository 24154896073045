import React, { useEffect, useState } from 'react';
import { cn } from '../lib/utils';

const LiveTimer = ({ initialElapsedSeconds, className }) => {
  const [elapsedTime, setElapsedTime] = useState('0h : 00m : 00s');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (initialElapsedSeconds == null) return;

    let secondsElapsed = initialElapsedSeconds;

    const timerId = setInterval(() => {
      secondsElapsed += 1;

      const hours = Math.floor(secondsElapsed / 3600).toString()
      const minutes = Math.floor((secondsElapsed % 3600) / 60).toString().padStart(2, '0');
      const seconds = (secondsElapsed % 60).toString().padStart(2, '0');

      setElapsedTime(`${hours}h : ${minutes}m : ${seconds}s`);
    }, 1000);

    setLoading(false);

    return () => clearInterval(timerId);
  }, [initialElapsedSeconds]);

  return (
    <h6 className={cn('font-semibold text-primary', className)}>
      {elapsedTime}
    </h6>
  );
};

export default LiveTimer;
