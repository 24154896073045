import React from 'react'

const IconCicularPlus = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2002_51)">
        <path opacity="0.2" d="M21 12C21 13.78 20.4722 15.5201 19.4832 17.0001C18.4943 18.4802 17.0887 19.6337 15.4442 20.3149C13.7996 20.9961 11.99 21.1743 10.2442 20.8271C8.49836 20.4798 6.89472 19.6226 5.63604 18.364C4.37737 17.1053 3.5202 15.5016 3.17294 13.7558C2.82567 12.01 3.0039 10.2004 3.68509 8.55585C4.36628 6.91131 5.51983 5.50571 6.99987 4.51677C8.47991 3.52784 10.22 3 12 3C14.387 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" fill="#343330" />
        <path d="M12 0C9.62657 0 7.30653 0.70379 5.33315 2.02236C3.35977 3.34093 1.8217 5.21508 0.913452 7.40779C0.00519546 9.60047 -0.232441 12.0133 0.230586 14.341C0.6936 16.6688 1.83649 18.807 3.51471 20.4853C5.19295 22.1635 7.33114 23.3064 9.65894 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7848 22.1783 20.659 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C23.9966 8.81842 22.7313 5.76812 20.4816 3.51842C18.2318 1.26872 15.1815 0.00335999 12 0ZM12 22.1538C9.99174 22.1538 8.02861 21.5582 6.35882 20.4425C4.68903 19.3269 3.38759 17.741 2.61907 15.8856C1.85056 14.0302 1.64948 11.9887 2.04127 10.0191C2.43304 8.04941 3.40011 6.24017 4.82014 4.82014C6.24019 3.4001 8.04943 2.43304 10.0191 2.04125C11.9888 1.64946 14.0304 1.85054 15.8856 2.61906C17.741 3.38759 19.3269 4.68902 20.4425 6.35882C21.5582 8.0286 22.1538 9.99173 22.1538 12C22.1507 14.692 21.08 17.273 19.1765 19.1765C17.273 21.08 14.692 22.1507 12 22.1538ZM17.5384 12C17.5384 12.2448 17.4412 12.4796 17.268 12.6527C17.095 12.8258 16.8602 12.9231 16.6154 12.9231H12.9231V16.6154C12.9231 16.8602 12.8258 17.095 12.6527 17.268C12.4796 17.4412 12.2448 17.5384 12 17.5384C11.7552 17.5384 11.5203 17.4412 11.3473 17.268C11.1741 17.095 11.0769 16.8602 11.0769 16.6154V12.9231H7.3846C7.13979 12.9231 6.90501 12.8258 6.73189 12.6527C6.55878 12.4796 6.46153 12.2448 6.46153 12C6.46153 11.7552 6.55878 11.5203 6.73189 11.3473C6.90501 11.1741 7.13979 11.0769 7.3846 11.0769H11.0769V7.3846C11.0769 7.13979 11.1741 6.905 11.3473 6.73189C11.5203 6.55878 11.7552 6.46153 12 6.46153C12.2448 6.46153 12.4796 6.55878 12.6527 6.73189C12.8258 6.905 12.9231 7.13979 12.9231 7.3846V11.0769H16.6154C16.8602 11.0769 17.095 11.1741 17.268 11.3473C17.4412 11.5203 17.5384 11.7552 17.5384 12Z" fill="#343330" />
      </g>
      <defs>
        <clipPath id="clip0_2002_51">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconCicularPlus