import * as React from "react"
import { cn } from "../lib/utils"

const Input = React.forwardRef(
  (
    ({ className, lable, type, placeholder, lableClass, prefix, prefixClass, sufix, sufixClass, disabled, rootClass, ...props }, ref) => {
      return (
        <div className={cn("", rootClass)}>
          {
            lable && <label className={cn(`block text-base font-medium text-dark mb-2`, lableClass)}>
              {lable}
            </label>
          }
          <div className="relative">
            {
              sufix && <span className={cn('icon-wrapper absolute top-[50%] left-4 cursor-pointer -translate-y-1/2', sufixClass)}>{sufix}</span>
            }
            <input
              type={type}
              placeholder={placeholder}
              className={cn(
                `flex w-full rounded-md border border-border bg-[#FBFBFF] px-4 py-3.5 text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50`,
                className, { "ps-10": sufix }
              )}
              ref={ref}
              {...props}
            />
            {
              prefix && <span className={cn('icon-wrapper absolute top-[50%] right-4 cursor-pointer -translate-y-1/2', prefixClass)}>{prefix}</span>
            }
          </div>
        </div>
      )
    }
  )
)
Input.displayName = "Input"

export { Input }
