import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import NoDataUI from '../../common/NoDataUI';
import { Button } from '../../common/ui/Button';
import { useAuth } from '../../context/AuthContext';
import { groupTasksByStatus } from '../../lib/utils';
import API from '../../services/api';
import { statusType } from '../../staticData/data';
import AddTaskModal from '../modal/AddTaskModal';
import TaskListItem from './TaskListItem';
import { CheckIcon, X } from "lucide-react";
import IconColse from '../../common/svgIcons/IconClose';
import Tooltip from '../../common/ui/Tooltip';
import QuickAddTaks from '../../common/QuickAddTaks';
import Loader from '../../common/ui/Loader';

const TaskList = () => {
    const queryClient = useQueryClient()
    const { projectId, sprintId } = useParams();
    const childRef = useRef();
    const { user, logout } = useAuth();
    const [isOpen, setIsOpen] = useState(false)
    const [taskDetails, setTaskDetails] = useState();
    const [activeTask, setActiveTask] = useState();
    const [timers, setTimers] = useState([]);
    const [timer, setTimer] = useState(false);
    const [tags, setTags] = useState();
    const [isSelected, setIsSelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false)

    const [newTask, setNewTask] = useState({
        title: '',
        description: '',
        projectId: '',
        assignedTo: '',
        dueDate: new Date().toISOString().split('T')[0],
        priority: 'Medium',
        sprintId: ''
    });

    const fetchProjectDetails = async () => {
        const { data } = await API.get(`project/${projectId}`);
        return data;
    };
    const fetchSprintDetails = async () => {
        const { data } = await API.get(`sprint/${sprintId}`);
        return data;
    };

    const fetchTasks = async () => {
        if (sortedTasks?.length <= 0) {
            setLoader(true)
        }
        try {
            const { data } = await API.get(`/task/sprints/${sprintId}/tasks`);
            return groupTasksByStatus(data);
        } catch (err) {
            console.error(err.message);
            return [];
        } finally {
            setLoader(false)
        }
    };
    const { data: projectDetails } = useQuery({
        queryKey: ['project', projectId], queryFn: fetchProjectDetails, enabled: !!projectId
    });
    const { data: sprintDetails } = useQuery(
        { queryKey: ['sprint', sprintId], queryFn: fetchSprintDetails, enabled: !!sprintId }
    );
    const { data: sortedTasks } = useQuery(
        { queryKey: ['tasks', sprintId], queryFn: fetchTasks, enabled: !!sprintId }
    );

    const { mutate: addTaskMutation } = useMutation({
        mutationFn: async (data) => {
            setLoading(true);
            const response = await API.post('/task', data);
            return response.data;
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            toast.success('Task added successfully');
            setNewTask({
                title: '',
                description: '',
                projectId: '',
                assignedTo: '',
                dueDate: new Date().toISOString().split('T')[0],
                priority: 'Medium',
            });
            setIsOpen(false);
            setLoading(false);
            fetchTasks();
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message ||
                Object.entries(error.response?.data?.errors || {})[0]?.[1] ||
                'Failed to add task';
            toast.error(errorMessage);
            setLoading(false);
        },
    });

    const handleAddTask = (data) => {
        console.log("data in taskList", data);
        const obj = {
            ...data,
            projectId: projectId,
            sprintId: sprintId
        };
        addTaskMutation(obj);
    };

    const { mutate: addQuickTaskMutation } = useMutation({
        mutationFn: async (newTaskData) => {
            const response = await API.post('/task', newTaskData);
            return response.data;
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            toast.success('Task added successfully');
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message ||
                Object.entries(error.response?.data?.errors || {})[0]?.[1] ||
                'Failed to add task';
            toast.error(errorMessage);
        },
    });

    const handleQuickAdd = async (newTitle, newStatus) => {
        const obj = {
            title: newTitle,
            status: newStatus,
            projectId: projectId,
            sprintId: sprintId
        };
        addQuickTaskMutation(obj);

    }

    const [taskTimer, setTaskTimer] = useState();

    const fetchTimersAndTasks = async () => {
        try {
            const [timersRes] = await Promise.all([API.get('/timer')]);
            setTimers(timersRes.data);
        } catch (err) {
            console.error(err.message);
        }
    };

    const handleStopTimer = async (timerId) => {
        try {
            let data = await API.post('/timer/stop', { timerId });
            setTimer(false);
            fetchTimersAndTasks();
            fetchCurrentTask();
            if (childRef.current) {
                childRef.current.fetchTimersAndTasks();
            }
            setActiveTask(null)
            setTaskTimer(timers.filter((time) => time.taskId._id == taskDetails._id))
        } catch (err) {
            if (err.status === 401) {
                logout()
            }
        }
    };
    const fetchCurrentTask = async () => {
        try {
            const response = await API.get('/timer/active');
            setActiveTask(response.data)
        } catch (error) {
            console.error(error.message);
            setActiveTask(null)
        }
    }
    const fetchTagList = async () => {
        try {
            const tagList = await API.get("/tag/list")
            setTags(tagList?.data)
        } catch (error) {
            toast.error("Failed To Get Tag List")
        }
    }

    useEffect(() => {
        fetchTimersAndTasks();
        fetchCurrentTask()
        fetchTagList();
    }, []);

    return (
        <div className="task-list-wrapper">
            <div className='px-5 bg-white rounded-lg h-full overflow-hidden'>
                <div className="">
                    <div className="flex items-center justify-between">
                        <h2 className='text-xl py-2 font-semibold'>My Tasks</h2>
                        <div className="flex items-center gap-2.5">
                            <Tooltip detailClass='top-10 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' text='Quickly show closed tasks' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                                <Button
                                    size="sm"
                                    variant="default"
                                    onClick={() => setIsSelected(!isSelected)}
                                    className={`px-1 gap-1.5 font-semibold border border-[#008748] hover:bg-[#008748] hover:text-white group ${isSelected ? "bg-[#008748] text-white " : "bg-[#00AC4826] text-[#008748]"}`}
                                >
                                    <IconColse className={`h-5 w-5 group-hover:text-white ${isSelected ? "text-white " : "text-[#008748]"}`} />
                                    Closed
                                </Button>
                            </Tooltip>
                            <Button size='sm' className={"font-semibold"} onClick={() => setIsOpen(true)}>Add Task</Button>
                        </div>
                    </div>
                    {loader ? (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '700px' }}>
                        <Loader />
                    </div>) : (
                        <div className={`h-[calc(100vh-180px)] overflow-auto mt-2`}>
                            {
                                sortedTasks?.length ? sortedTasks.filter((sortedTask) => {
                                    if (!isSelected && sortedTask.status === "Closed") {
                                        return false;
                                    }
                                    return true;
                                }).map((sortedTask) => {
                                    const theme = statusType.find((status) => status.name === sortedTask.status)
                                    return (
                                        <div className='mb-2.5'>
                                            <div className="">
                                                <button
                                                    className={clsx(
                                                        `relative text-white capitalize font-semibold  inline-block text-xs px-1 py-0.5 rounded-md border`,
                                                        'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25 '
                                                    )}
                                                    style={{ background: `${theme?.bgColor}`, color: `${theme?.color}`, borderColor: `${theme?.color}` }}
                                                >
                                                    <div className='flex items-center gap-1'>
                                                        {theme?.icon}
                                                        {sortedTask.status}
                                                    </div>
                                                </button>
                                                <div className="">
                                                    <div className="mt-2">
                                                        <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                                                            <div className="w-[60%] px-3.5">Name</div>
                                                            <div className="w-[10%]">Assignee</div>
                                                            <div className="w-[10%]">Due Date</div>
                                                            <div className="w-[10%]">Time Estimate</div>
                                                            <div className="w-[10%]">Priority</div>
                                                        </div>
                                                    </div>
                                                    {
                                                        sortedTask?.tasks.map((task) => {
                                                            return (
                                                                <TaskListItem tags={tags} fetchCurrentTask={fetchCurrentTask} user={user} tag={task?.tags} task={task} key={task._id} sprintDetails={sprintDetails} projectDetails={projectDetails} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <QuickAddTaks className='py-2 px-1.5 text-sm' task={'task'} status={sortedTask?.status} addTask={handleQuickAdd} />
                                        </div>
                                    )
                                }) :
                                    <div className="h-full flex items-center justify-center">
                                        <NoDataUI subtitle={"Tasks and Reminders assigned to you will show here."} title={"Oops, No Tasks Found!"} />
                                    </div>
                            }
                        </div>
                    )}
                </div >
                <AddTaskModal activeSprint={sprintDetails} selectedProject={projectDetails} isOpen={isOpen} setIsOpen={setIsOpen} newTask={newTask} setNewTask={setNewTask} handleAddTask={handleAddTask} loader={loading} />
            </div >
        </div >
    );
};

export default TaskList;
