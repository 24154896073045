import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import React, { useState } from 'react';
import { colorMap } from '../../staticData/data';
import API from '../../services/api';
import { toast } from 'react-toastify';
import { UserRoundPlus, X } from 'lucide-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../../context/AuthContext';
import Tooltip from './Tooltip';
import { getColorFromName } from '../../lib/utils';
import IconUser from '../svgIcons/IconUser';

const AssigneePopover = ({ assignedTo = [], members = [], taskId, onAssigneeUpdate, isArchive, isReport }) => {
    const [hoveredAssignee, setHoveredAssignee] = useState(null);
    const queryClient = useQueryClient();
    const { user, logout } = useAuth();

    const { mutate: addAssigneeMutation } = useMutation({
        mutationFn: async (userId) => {
            const response = await API.post(`/task/${taskId}/add-assignees`, { userId });
            return response.data;
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            toast.success('Assignee added successfully');
        },
        onError: (error) => {
            console.error('Failed to add assignee:', error);
            toast.error('Failed to add assignee');
        }
    });

    const { mutate: removeAssigneeMutation } = useMutation({
        mutationFn: async (userId) => {
            const response = await API.post(`/task/${taskId}/remove-assignees`, { userId });
            return response.data;
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            toast.success('Assignee removed successfully');
        },
        onError: (error) => {
            console.error('Failed to remove assignee:', error);
            toast.error('Failed to remove assignee');
        }
    });

    const handleAddAssignee = (member) => {
        addAssigneeMutation(member?._id);
    };

    const handleRemoveAssignee = (assignee) => {
        removeAssigneeMutation(assignee?._id);
    };

    return (
        <div className="w-full">
            <Popover>
                <PopoverButton className="w-full text-sm/6 font-semibold text-white/50 focus:outline-none data-[active]:text-white data-[hover]:text-white data-[focus]:outline-1 data-[focus]:outline-white flex items-center gap-1">
                    {assignedTo?.map((assignee) => {
                        const initial = assignee?.name?.charAt(0)?.toLowerCase();
                        const backgroundColor = getColorFromName(assignee?.name);
                        return (
                            // <span
                            //     className="relative font-medium uppercase h-6 w-6 text-white rounded-full flex items-center justify-center text-xs p-0.5 first:ms-0 -ms-2.5"
                            //     style={{ background: backgroundColor }}
                            //     key={assignee.name}
                            // >
                            //     {assignee.name
                            //         ?.split(' ')
                            //         .slice(0, 2)
                            //         .map((name) => name.slice(0, 1).toUpperCase())}
                            //     <button
                            //         onClick={(e) => {
                            //             e.stopPropagation();
                            //             handleRemoveAssignee(assignee); // Trigger remove assignee mutation
                            //         }}
                            //         className="absolute top-0 right-[5px] transform translate-x-1/2 -translate-y-1/2 bg-primary text-white rounded-full h-3 w-3 flex items-center justify-center font-bold border border-border"
                            //     >
                            //         <X className="h-3 w-3 text-white" />
                            //     </button>
                            // </span>
                            <Tooltip toolTipClass='first:ms-0 -ms-2.5' text={user?._id === assignee?._id ? `I'm Online` : assignee?.name} bgColor={user?._id === assignee?._id ? 'bg-[#008748]' : 'bg-primary'} borderColor={user?._id === assignee?._id ? 'border-t-[#008748]' : 'border-t-primary'}>
                                <span
                                    className="relative font-medium uppercase h-6 w-6 text-white rounded-full flex items-center justify-center text-[10px] p-1 first:ms-0 -ms-2.5 hover:z-[9] border border-border"
                                    style={{ background: backgroundColor }}
                                    key={assignee.name}
                                    onMouseEnter={() => setHoveredAssignee(assignee?._id)}
                                    onMouseLeave={() => setHoveredAssignee(null)}
                                >
                                    {assignee?.name
                                        ?.split(' ')
                                        .slice(0, 2)
                                        .map((name) => name.slice(0, 1).toUpperCase())}
                                    {hoveredAssignee === assignee?._id && !isArchive && !isReport && (
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemoveAssignee(assignee);
                                            }}
                                            className="absolute top-[1px] right-[1px] transform translate-x-1/2 -translate-y-1/2 bg-primary text-white rounded-full h-3 w-3 flex items-center justify-center font-bold border border-border"
                                        >
                                            <X className="h-3 w-3 text-white" />
                                        </button>
                                    )}
                                </span>
                            </Tooltip>
                        );
                    })}
                    {
                        !assignedTo.length &&
                        <IconUser className='text-dark/60 h-6 w-6' />
                    }
                </PopoverButton>
                {(!isArchive && !isReport) &&
                    <PopoverPanel
                        transition
                        anchor="bottom"
                        className="min-w-[220px] divide-y shadow-custom divide-white/5 rounded-xl bg-white text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0 overflow-hidden"
                    >
                        <div className="p-2 max-h-[200px]">
                            {members.map((member) => {
                                const initial = member?.name?.charAt(0)?.toLowerCase();
                                const backgroundColor = getColorFromName(member?.name);
                                const isAssigned = assignedTo?.some(
                                    (assignee) => assignee?.name === member?.userId?.name
                                );

                                return (
                                    <div
                                        className={`flex items-center gap-2.5 hover:bg-dark/5 p-2 rounded-lg cursor-pointer`}
                                        key={member.name}
                                        // onClick={() => handleAddAssignee(member)} // Trigger add assignee mutation
                                        onClick={() => {
                                            if (isAssigned) {
                                                const assignee = assignedTo.find(
                                                    (assignee) => assignee.name === member?.userId?.name
                                                );
                                                handleRemoveAssignee(assignee);
                                            } else {
                                                handleAddAssignee(member?.userId);
                                            }
                                        }}
                                    >
                                        <div
                                            className={`h-7 w-7 rounded-full flex items-center justify-center p-[1px] ${isAssigned ? 'border border-primary' : ''
                                                }`}
                                        >
                                            <div
                                                className={`h-6 w-6 text-white flex items-center justify-center rounded-full text-sm`}
                                                style={{ background: getColorFromName(member?.userId?.name) }}
                                            >
                                                {member?.userId?.name
                                                    ?.split(' ')
                                                    .slice(0, 2)
                                                    .map((name) => name.slice(0, 1).toUpperCase())}
                                            </div>
                                        </div>
                                        <div>
                                            <p className={`text-dark ${isAssigned ? 'font-semibold' : ''}`}>{member?.userId?.name}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </PopoverPanel>

                }
            </Popover>
        </div>
    );
};

export default AssigneePopover;