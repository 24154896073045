import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { CheckIcon } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import AssigneePopover from '../../common/ui/AssigneePopover';
import DueDate from '../../common/ui/DueDate';
import EstimatedTimePopover from '../../common/ui/EstimatedTimePopover';
import PriorityDropdown from '../../common/ui/PriorityDropdown';
import Title from '../../common/ui/Title';
import API from '../../services/api';
import { statusType } from '../../staticData/data';
import DeleteModal from '../modal/DeleteModal';
import TaskDetailModal from '../modal/TaskDetailModal';
import { cn } from '../../lib/utils';
import moment from 'moment';

const TaskListItem = ({ type, task, projectDetails, user, sprintDetails, fetchCurrentTask, estimateTime = true, tags, isArchive, isRecent, isReport }) => {
  const queryClient = useQueryClient()
  const [taskOpen, setTaskOpen] = useState(false);
  const [hoverId, setHoverId] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [restoreOpen, setRestoreOpen] = useState(false)
  const color = statusType.find((status) => status.name === task.status);
  const [selectedTags, setSelectedTags] = useState([]);
  const [taskTags, setTaskTags] = useState(task?.tags || []);

  const { mutate: updateDueDateMutation } = useMutation({
    mutationFn: async (dueDate) => {
      const response = await API.patch(`/task/${task._id}/due-date`, { dueDate });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
      queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
      if (isArchive) {
        queryClient.invalidateQueries({ queryKey: ['archived-task'] });
      }
      queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
      toast.success('Due date changed successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Failed to update due date');
    }
  });

  const { mutate: updatePriorityMutation } = useMutation({
    mutationFn: async (priority) => {
      const response = await API.patch(`/task/${task._id}/priority`, { priority });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
      queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
      if (isArchive) {
        queryClient.invalidateQueries({ queryKey: ['archived-task'] });
      }
      toast.success('Priority changed successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Failed to update priority');
    }
  });

  const { mutate: removePriorityMutation } = useMutation({
    mutationFn: async () => {
      const response = await API.patch(`/task/${task._id}/remove-priority`);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
      queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
      if (isArchive) {
        queryClient.invalidateQueries({ queryKey: ['archived-task'] });
      }
      toast.success('Priority removed successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Failed to remove priority');
    }
  });

  const { mutate: addEstimatedTimeMutation } = useMutation({
    mutationFn: async (estimatedHours) => {
      const response = await API.patch(`/task/${task._id}/estimated-hours`, { estimatedHours });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
      queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
      if (isArchive) {
        queryClient.invalidateQueries({ queryKey: ['archived-task'] });
      }
      toast.success('Estimated time added successfully')
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Failed to add Estimated time');
    }
  });

  const { mutate: removeEstimatedTimeMutation } = useMutation({
    mutationFn: async (estimatedHours) => {
      const response = await API.patch(`/task/${task._id}/clear-estimated-time`);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
      queryClient.invalidateQueries({ queryKey: ['recentTasks'] })
      if (isArchive) {
        queryClient.invalidateQueries({ queryKey: ['archived-task'] });
      };
      toast.success('Estimated time removed successfully')
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Failed to remove Estimated time');
    }
  });

  const showMenu = (id) => setHoverId(id);
  const hideMenu = () => setHoverId('');
  const { mutate: statusChangeTaskMutation } = useMutation({
    mutationFn: async (newStatus) => {
      const response = await API.patch(`/task/${task._id}`, { status: newStatus });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks', sprintDetails._id] })
      queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
      toast.success('Status Changed successfully');
    },
    onError: (error) => {
      toast.error('Failed to Changed Stutus');
    },
  });

  const { mutate: deleteTaskMutation } = useMutation({
    mutationFn: async () => {
      if (task?.isArchived === true) {
        const response = await API.delete(`/task/${task?._id}`);
        return response.data;
      } else {
        const response = await API.patch(`/task/${task?._id}/archive`);
        return response.data;
      }
    },
    onSuccess: () => {
      if (isArchive) {
        queryClient.invalidateQueries({ queryKey: ['archived-task'] });
      }
      queryClient.invalidateQueries({ queryKey: ['tasks', sprintDetails?._id] })
      queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
      toast.success('Task deleted successfully');
      setDeleteOpen(false)
    },
    onError: (error) => {
      console.error('Task deletion failed:', error);
      toast.error('Failed to delete task');
      setDeleteOpen(false)
    },
  });

  const { mutate: updateTitleMutation } = useMutation({
    mutationFn: async (title) => {
      const response = await API.patch(`/task/${task?._id}/title`, { title });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks', sprintDetails._id] })
      queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
      if (isArchive) {
        queryClient.invalidateQueries({ queryKey: ['archived-task'] });
      }
      toast.success('task Updated successfully')
    },
    onError: (error) => {
      console.error('Task deletion failed:', error);
      toast.error('Failed to update task');
    },
  });

  const { mutate: updateDescriptionMutation } = useMutation({
    mutationFn: async (description) => {
      const response = await API.patch(`/task/${task._id}/details`, { description });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks', sprintDetails._id] });
      if (isArchive) {
        queryClient.invalidateQueries({ queryKey: ['archived-task'] });
      }
    },
    onError: (error) => {
    },
  });

  const handleSelectTag = async (tag) => {


    try {
      setTaskTags((prev) => {
        if (prev.length >= 2) {
          toast.error("You can only add up to 2 tags.");
          return prev;
        }
        return [...prev, tag];
      });
      setSelectedTags((prev) => {
        if (prev.length >= 2) {
          return prev;
        }
        return [...prev, tag];
      });
      if (taskTags.length < 2) {
        await API.patch(`/tag/task/${task._id}/add`, {
          tagIds: [tag?._id],
        });
      }
    } catch (error) {
      toast.error("Failed to add tag");
    }
  };

  const handleRemoveTag = async (tag) => {
    try {
      setTaskTags((prev) => prev.filter((t) => t._id !== tag._id));
      setSelectedTags((prev) => prev.filter((t) => t._id !== tag._id));
      await API.delete(`/tag/task/${task._id}/delete`, {
        data: { tagIds: [tag._id] },
      });
      // toast.success("Tag Removed Successfully");
    } catch (error) {
      toast.error("Failed to remove tag");
    }
  };

  const { mutate: handleRetrieveTask } = useMutation({
    mutationFn: async () => {
      const response = await API.patch(`/task/${task._id}/restore`);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['archived-task'] })
      toast.success('Task restore successfully');
      setRestoreOpen(false)
    },
    onError: (error) => {
      console.error('Task restore failed:', error);
      toast.error('Failed to restore task');
      setRestoreOpen(false)
    },
  });
  return (
    <>
      <div key={task._id} className="body w-full flex gap-2 text-muted border-b border-border cursor-pointer text-sm font-medium hover:bg-primary/5" onMouseEnter={() => showMenu(task?._id)} onMouseLeave={hideMenu}>
        <div className={cn("w-[60%] flex gap-2 items-center border-e border-border p-2 px-1.5", {
          'w-[50%]': isArchive || isRecent,
          'w-[40%]': isReport,
        })}>
          <div className=''>
            <Listbox
              value={task.status}
              onChange={(newStatus) => statusChangeTaskMutation(newStatus)}
            >
              <ListboxButton
                className={clsx(
                  `relative block w-full rounded-lg bg-white/5 text-left text-sm/6 `,
                  'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                )}
              >
                {color?.icon}
              </ListboxButton>
              <ListboxOptions
                anchor="bottom"
                transition
                className={clsx(
                  'w-[210px] rounded-xl shadow-custom border border-white/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                  'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                )}
              >
                {statusType.map((status) => (
                  <ListboxOption
                    key={status.id}
                    value={status.value}
                    className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-lg py-1.5 px-2 select-none hover:bg-dark/5"
                  >
                    <div className="flex items-center gap-1.5">
                      {status?.icon}
                      <div className="text-xs font-medium">{status.name}</div>
                    </div>
                    <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible" />
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Listbox>
          </div>
          <div className="w-full relative" >
            <Title
              handleRetrieve={() => handleRetrieveTask(task?._id)}
              isArchive={isArchive}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              tags={tags}
              taskTags={taskTags}
              handleSelectTag={handleSelectTag}
              handleRemoveTag={handleRemoveTag}
              task={task}
              isReport={isReport}
              handleOpenDetails={() => setTaskOpen(true)}
              updateTitle={updateTitleMutation}
              hoverId={hoverId}
              setHoverId={setHoverId}
              setDeleteOpen={setDeleteOpen}
              setRestoreOpen={setRestoreOpen}
            />
          </div>
        </div>
        {(isArchive || isReport) && <div className='w-[10%] flex items-center border-e border-border'>{projectDetails?.name}</div>}
        {
          !type &&
          <div className={cn("w-[10%] flex items-center border-e border-border", {
            "w-[16%]": isRecent
          })}>
            <AssigneePopover
              isArchive={isArchive}
              isReport={isReport}
              assignedTo={task?.assignedTo}
              members={projectDetails?.team}
              taskId={task?._id}
            />
          </div>
        }
        {
          isReport && <div className={cn("w-[10%] flex items-center border-e border-border", {
            "w-[16%]": isRecent
          })}>{sprintDetails?.name}</div>
        }
        <div className={cn("w-[10%] flex items-center border-e border-border", {
          "w-[16%]": isRecent
        })}><DueDate taskDate={task?.dueDate} id={task._id} handleDueDate={updateDueDateMutation} taskStatus={task.status} /></div>
        {estimateTime && <div className={cn("w-[10%] flex items-center border-e border-border", {
          "w-[16%]": isRecent
        })}><EstimatedTimePopover task={task} id={task?._id} time={task?.estimatedHours ?? 0} addEstimatedTime={addEstimatedTimeMutation} removeEstimatedTime={removeEstimatedTimeMutation} hoverId={hoverId} setHoverId={setHoverId} /></div>}
        <div className="w-[10%] flex items-center">
          <PriorityDropdown priority={task?.priority} id={task._id} handleRemovePriority={removePriorityMutation} handlePriority={updatePriorityMutation} />
        </div>
      </div >
      <TaskDetailModal isArchive={isArchive} isReport={isReport} modalType='Task' tagList={tags} menu={true} projectDetails={projectDetails} sprintDetails={sprintDetails} fetchCurrentTask={fetchCurrentTask} open={taskOpen} handleRemovePriority={removePriorityMutation} setOpen={setTaskOpen} task={task} statusType={statusType} handleStatusChange={statusChangeTaskMutation} handlePriority={updatePriorityMutation} timers={'timers'} member={projectDetails?.team} handleDueDate={updateDueDateMutation} addEstimatedTime={addEstimatedTimeMutation} removeEstimatedTime={removeEstimatedTimeMutation} updateTitle={updateTitleMutation} updateDescription={updateDescriptionMutation} />
      <DeleteModal title={'Are you sure you want to delete this task?'} open={deleteOpen} setOpen={setDeleteOpen} id={task._id} handleDeleteTask={deleteTaskMutation} />
      <DeleteModal title={'Are you sure you want to restore this task?'} open={restoreOpen} setOpen={setRestoreOpen} id={task._id} handleDeleteTask={handleRetrieveTask} />
    </>
  )
}

export default TaskListItem