import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import clsx from "clsx";
import React, { useState, useMemo } from "react";
import { priorityTypes } from "../../staticData/data";
import IconFlagBlank from "../svgIcons/IconFlagBlank";
import { cn } from "../../lib/utils";

const PriorityDropdown = ({
    id,
    priority,
    handlePriority,
    handleRemovePriority,
    listBoxButtonClassName
}) => {
    const [priorityType, setPriorityType] = useState(priority);

    const priorityColor = useMemo(
        () => priorityTypes.find((status) => status.name === priorityType),
        [priorityType]
    );

    const handleStatusChange = (newStatus) => {
        if (newStatus === "clear") {
            if (priorityType !== null) {
                handleRemovePriority();
                setPriorityType(null);
            }
        } else if (newStatus !== priorityType) {
            handlePriority(newStatus);
            setPriorityType(newStatus);
        }
    };

    const renderIcon = (icon, label) => (
        <span className="flex items-center gap-1.5" aria-label={label}>
            {icon}
        </span>
    );

    return (
        <div>
            <Listbox value={priorityType} onChange={handleStatusChange}>
                <ListboxButton
                    className={cn(
                        `w-[92px] rounded-lg py-1 text-sm font-medium flex items-center gap-1.5 focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25`,
                        listBoxButtonClassName
                    )}
                    aria-label="Priority Dropdown"
                >
                    {priorityType ? (
                        <>
                            {renderIcon(priorityColor?.icon, `${priorityType} icon`)}
                            {priorityType}
                        </>
                    ) : (
                        renderIcon(
                            <IconFlagBlank className="h-5 w-5 text-[#98A1B2]" />,
                            "Blank flag icon"
                        )
                    )}
                </ListboxButton>
                <ListboxOptions
                    anchor="bottom"
                    transition
                    className={clsx(
                        "w-[210px] rounded-xl shadow-custom border border-white/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none",
                        "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
                    )}
                >
                    <div className="p-1 border-b border-border">
                        {priorityTypes.map((status) => (
                            <ListboxOption
                                key={status.id}
                                value={status.value}
                                className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-lg py-1.5 px-2 select-none hover:bg-dark/5"
                            >
                                <div className="flex items-center gap-1.5">
                                    {renderIcon(status.icon, `${status.name} icon`)}
                                    <div className="text-sm font-medium">{status.name}</div>
                                </div>
                            </ListboxOption>
                        ))}
                    </div>
                    <div className="p-1">
                        <ListboxOption
                            key="clear"
                            value={"clear"}
                            className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-lg py-1.5 px-2 select-none hover:bg-dark/5"
                        >
                            <div className="flex items-center gap-1.5">
                                {renderIcon(
                                    <IconFlagBlank className="h-5 w-5 text-[#98A1B2]" />,
                                    "Clear icon"
                                )}
                                <p className="text-sm font-medium">Clear</p>
                            </div>
                        </ListboxOption>
                    </div>
                </ListboxOptions>
            </Listbox>
        </div>
    );
};

export default PriorityDropdown;
