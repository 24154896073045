import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import NoDataUI from '../common/NoDataUI';
import TaskListItem from '../components/dashboard/TaskListItem';
import API from '../services/api';
import { groupTasksByStatus } from '../lib/utils';
import { statusType } from '../staticData/data';
import clsx from 'clsx';
import Loader from '../common/ui/Loader';

const TaskAnalytics = () => {
  const [loader, setLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const date = moment(selectedDate).format('YYYY-MM-DD')

  const fetchTasksAnalytics = async () => {
    setLoader(true)
    try {
      const { data } = await API.get(`/task/task-date?startDate=${date}&endDate=${date}`);
      return groupTasksByStatus(data.data);
    } catch (err) {
      console.error(err.message);
      return [];
    } finally {
      setLoader(false)
    }
  };

  const { data: reportDatas } = useQuery({
    queryKey: ['task-analytics', date],
    queryFn: fetchTasksAnalytics,
  });

  return (
    <section className='p-5'>
      <div className="">
        <div className={`overflow-auto mt-2`}>
          <div className="flex items-center gap-4 mb-1">
            <h2>Select Date :</h2>
            <div className='flex border border-black/20 rounded-md w-max'>
              <DatePicker
                value={selectedDate}
                selected={selectedDate}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                onChange={setSelectedDate}
                dateFormat="dd MMM yyyy"
                className={`w-full px-2 py-1 cursor-pointer pointer-events-auto`} // Apply the dynamic class here
              />
            </div>
          </div>
          {
            loader ? <div className='flex justify-center items-center h-[calc(100vh-180px)]'>
              <Loader />
            </div> :
              <div className='mb-2'>
                <div className={`h-[calc(100vh-187px)] overflow-auto mt-2`}>
                  {reportDatas?.length > 0 ?
                    reportDatas?.map((reportData) => {
                      const theme = statusType.find((status) => status.name === reportData.status)
                      return (
                        <div className='mb-2.5'>
                          <button
                            className={clsx(
                              `relative text-white capitalize font-semibold  inline-block text-xs px-1 py-0.5 rounded-md border`,
                              'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25 '
                            )}
                            style={{ background: `${theme?.bgColor}`, color: `${theme?.color}`, borderColor: `${theme?.color}` }}
                          >
                            <div className='flex items-center gap-1'>
                              {theme?.icon}
                              {reportData.status}
                            </div>
                          </button>
                          <div className="">
                            <div className="mt-2">
                              <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                                <div className="w-[40%] px-2.5">Name</div>
                                <div className="w-[10%]">Project</div>
                                <div className="w-[10%]">Assignee</div>
                                <div className="w-[10%]">Sprint</div>
                                <div className="w-[10%]">Due Date</div>
                                <div className="w-[10%]">Time Estimate</div>
                                <div className="w-[10%]">Priority</div>
                              </div>
                            </div>
                          </div>
                          {
                            reportData?.tasks.map((task) => {
                              return (
                                <TaskListItem projectDetails={task?.projectId} sprintDetails={task?.sprintId} tag={task?.tags} estimateTime={true} type={false} task={task} isReport={true} key={task._id} />
                              )
                            })
                          }
                        </div>
                      )
                    })
                    : <div className="h-full flex items-center justify-center">
                      <NoDataUI subtitle={"Tasks and Reminders assigned to you will show here."} title={"Oops, No Tasks Found!"} />
                    </div>}
                </div>
              </div>}
        </div>
      </div>
    </section>
  );
};

export default TaskAnalytics;
