import { Dialog, DialogPanel, DialogTitle, Select } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { Input } from '../../common/Input'
import clsx from 'clsx'
import API from '../../services/api'
import MultiSelect from '../../common/ui/MultiSelect'
import { Calendar, X } from 'lucide-react'
import { Button } from '../../common/ui/Button'
import Buttonloader from '../../common/ui/ButtonLoader'
import { Controller, useForm } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import moment from 'moment'

const AddProjectModal = ({ open, setOpen, newProject, setNewProject, handleAddProject }) => {
    const [member, setMember] = useState();
    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, formState: { errors }, setValue, watch, reset, control } = useForm({
        defaultValues: {
            name: newProject.name || '',
            description: newProject.description || '',
            startDate: newProject.startDate || '',
            endDate: newProject.endDate || '',
            team: newProject.team || [],
        },
    });

    const fetchData = async () => {
        try {
            const [usersRes] = await Promise.all([
                API.get('/auth/user'),
            ]);
            setMember(usersRes.data.users);
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onSubmit = async (data) => {
        const projectData = {
            ...data,
            team: data.team.map((member) => ({ "userId": member._id })),
        };
        setLoading(true);
        try {
            await handleAddProject(projectData);
            reset()
        } catch (error) {
            console.error('Error creating task:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} as="div" className="relative z-10 focus:outline-none" onClose={() => setOpen(false)}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel
                        transition
                        className="w-full max-w-md rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                    >
                        <DialogTitle as="h3" className="text-base/7 font-medium text-dark bg-forground p-4 flex justify-between border-b border-border rounded-t-xl">
                            <p className='text-xl font-bold'>Add Project</p>
                            <span className='cursor-pointer' onClick={() => setOpen(false)}><X className='text-dark/50' /></span>
                        </DialogTitle>
                        <div className="">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="p-8 py-5">
                                    <div className="mb-2">
                                        <input
                                            placeholder="Project Name"
                                            className={clsx(
                                                'block w-full rounded-md border px-4 py-3.5 text-sm',
                                                errors.name && 'border-red-500'
                                            )}
                                            {...register('name', { required: 'Project name is required', minLength: { value: 2, message: 'Minimum 2 characters' } })}
                                        />
                                        {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name.message}</p>}
                                    </div>
                                    <div className="mb-2">
                                        <textarea
                                            rows={3}
                                            placeholder="Description"
                                            className={clsx(
                                                'block w-full rounded-md border px-4 py-3.5 text-sm',
                                                errors.description && 'border-red-500'
                                            )}
                                            {...register('description', { required: 'Description is required' })}
                                        />
                                        {errors.description && <p className="text-red-500 text-xs mt-1">{errors.description.message}</p>}
                                    </div>
                                    <div className="mb-2">
                                        {/* <input
                                            type="date"
                                            className={clsx(
                                                'w-full rounded-md border px-4 py-3.5 text-sm')}
                                            {...register('startDate')}
                                        /> */}
                                        <Controller
                                            name="startDate"
                                            control={control}
                                            render={({ field }) => (
                                                <div className='custome_date border border-secondary rounded-md w-full relative'>
                                                    <DatePicker
                                                        {...field}
                                                        selected={field.value}
                                                        onChange={(date) => field.onChange(date)}
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        dateFormat="dd MMM yyyy"
                                                        className="w-full rounded-md border px-4 py-3.5 text-sm"
                                                        placeholderText="Select start date"
                                                        minDate={moment(Date.now()).format("yyyy-MM-DD")}
                                                    />
                                                    <span className='absolute end-4 top-1/2 -translate-y-1/2'>
                                                        <Calendar className='h-4 w-4' />
                                                    </span>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <Controller
                                            name="endDate"
                                            control={control}
                                            render={({ field }) => (
                                                <div className='custome_date border border-secondary rounded-md w-full relative'>
                                                    <DatePicker
                                                        {...field}
                                                        selected={field.value}
                                                        onChange={(date) => field.onChange(date)}
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        dateFormat="dd MMM yyyy"
                                                        className="w-full rounded-md border px-4 py-3.5 text-sm"
                                                        placeholderText="Select end date"
                                                        minDate={moment(Date.now()).format("yyyy-MM-DD")}
                                                    />
                                                    <span className='absolute end-4 top-1/2 -translate-y-1/2'>
                                                        <Calendar className='h-4 w-4' />
                                                    </span>
                                                </div>
                                            )}
                                        />
                                        {/* <input
                                            type="date"
                                            className={clsx(
                                                'w-full rounded-md border px-4 py-3.5 text-sm',
                                                errors.endDate && 'border-red-500'
                                            )}
                                            {...register('endDate')}
                                        /> */}
                                    </div>
                                    <div className="mb-2">
                                        <MultiSelect
                                            people={member}
                                            selected={watch('team')}
                                            setSelected={(e) => setValue('team', e)}
                                        />
                                    </div>
                                </div>
                                <div className="py-4 px-6 border-t border-border flex items-center justify-end gap-2">
                                    <Button variant='outline' type="button" onClick={() => setOpen(false)}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" disabled={loading}>
                                        {loading ? <Buttonloader /> : 'Create Project'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export default AddProjectModal