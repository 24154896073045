import React, { useEffect, useState } from 'react'
import UserListItem from './UserListItem'
import API from '../../services/api';
import AddEditModal from '../modal/AddUserModal';
import { Button } from '../../common/ui/Button';
import AddUserModal from '../modal/AddUserModal';
import { toast } from 'react-toastify';
import NoDataUI from '../../common/NoDataUI';
import Loader from '../../common/ui/Loader';

const UserList = () => {
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [userData, setUserdata] = useState();
    const [newUser, setNewUser] = useState({
        name: '',
        email: '',
        role: '',
        password: ''
    });
    const [loader, setLoader] = useState(false);

    const fetchUsers = async () => {
        setLoader(true)
        try {
            const { data } = await API.get(`/auth/user-list`);
            setUserdata(data);
        } catch (err) {
            console.error(err.message);
        } finally {
            setLoader(false)
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleAdd = async (data) => {
        // e.preventDefault();
        setLoader(true)
        try {
            await API.post('/auth/register', data);
            setShowAddEditModal(false);
            fetchUsers();
            toast.success('User added successfully');
        } catch (err) {
            // console.error(err.message);
            toast.error(err.response.data.message);
        } finally {
            setLoader(false)
        }
    }


    return (
        <>
            <div className='p-5'>
                <div className="">
                    <div className="flex items-center justify-between">
                        <h2 className='text-lg font-semibold'>Employee List</h2>
                        <Button size='sm' className={"font-semibold"} onClick={() => setShowAddEditModal(true)}>Add Employee</Button>
                    </div>
                    <div className="mt-3">
                        <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                            <div className="w-1/2 px-3.5">Name ({userData?.length})</div>
                            <div className="w-[40%]">Email</div>
                            <div className="w-[30%]">Role</div>
                            <div className="w-[20%]">Action</div>
                        </div>
                        <div className="h-[calc(100vh-225px)] overflow-auto">
                            {
                                loader ? <Loader /> :
                                    userData?.length ? userData?.map((user) => (
                                        <UserListItem key={user.id} userData={user} fetchUsers={fetchUsers} showAddEditModal={showAddEditModal} setShowAddEditModal={setShowAddEditModal} newUser={newUser} setNewUser={setNewUser} />
                                    )) :
                                        <div className="h-full flex items-center justify-center">
                                            <NoDataUI subtitle={"Tasks and Reminders assigned to you will show here."} title={"Oops, No Tasks Found!"} />
                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <AddUserModal isOpen={showAddEditModal} setIsOpen={setShowAddEditModal} newUser={newUser} setNewUser={setNewUser} handleSubmit={handleAdd} loader={loader} />
        </>
    )
}

export default UserList