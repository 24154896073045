import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import NoDataUI from '../common/NoDataUI';
import TaskListItem from '../components/dashboard/TaskListItem';
import { useAuth } from '../context/AuthContext';
import API from '../services/api';
import Loader from '../common/ui/Loader';

const Archive = () => {
  const [loader, setLoader] = useState(false);
  const { user } = useAuth();

  const fetchTasks = async () => {
    setLoader(true)
    try {
      const { data } = await API.get(`/task/archived`);
      return data?.archivedTasks;
    } catch (err) {
      console.error(err.message);
      return [];
    } finally {
      setLoader(false);
    }
  };

  const { data: sortedTasks } = useQuery({
    queryKey: ['archived-task'],
    queryFn: fetchTasks
  });

  const fetchTagList = async () => {
    try {
      const tagList = await API.get('/tag/list');
      return tagList?.data;
    } catch (error) {
      toast.error('Failed To Get Tag List');
      return [];
    }
  };

  const { data: tags } = useQuery({
    queryKey: ['tags'],
    queryFn: fetchTagList
  });

  return (
    loader ? <Loader /> : (
      <section className='p-5'>
        <div className="">
          <h3 className="text-lg font-semibold mb-2">Deleted Tasks</h3>
          <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
            <div className="w-[50%] px-2.5">Name</div>
            <div className="w-[10%]">Project</div>
            <div className="w-[10%]">Assignee</div>
            <div className="w-[10%]">Due Date</div>
            <div className="w-[10%]">Time Estimate</div>
            <div className="w-[10%]">Priority</div>
          </div>
          <div className={`h-[calc(100vh-210px)] overflow-auto mt-2`}>
            {sortedTasks?.length > 0 ?
              sortedTasks?.map((task) => (
                <TaskListItem projectDetails={task?.projectId} sprintDetails={task?.sprintId} isArchive={true} user={user} tags={tags} tag={task?.tags} task={task} key={task._id} />
              ))
              : <div className="h-full flex items-center justify-center">
                <NoDataUI subtitle={"Tasks and Reminders assigned to you will show here."} title={"Oops, No Tasks Found!"} />
              </div>
            }
          </div>
        </div>
      </section>
    )
  );
};

export default Archive;
