import React, { useEffect, useState } from 'react'
import { getColorFromName } from '../lib/utils';
import moment from 'moment';
import { Pencil, Trash2 } from 'lucide-react';
import { Button } from './ui/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../services/api';
import { toast } from 'react-toastify';
import { Input } from './Input';
import IconReply from './svgIcons/IconReply';
import { useAuth } from '../context/AuthContext';

const CommentCard = ({ activity, replyCount, taskId, setReplyMode, setCommentDetails, reply }) => {
    const queryClient = useQueryClient()
    const { user } = useAuth();
    const [hoverId, setHoverId] = useState();
    const [editMode, setEditMode] = useState(false);
    const [newComment, setNewComment] = useState();

    useEffect(() => {
        setNewComment(activity?.content)
    }, [activity])

    const showMenu = (id) => setHoverId(id);
    const hideMenu = () => setHoverId('');

    const { mutate: deleteCommentMutation } = useMutation({
        mutationFn: async () => {
            const response = await API.delete(`/task/activities-comments/${activity._id}`);
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['fetchActivityComments', taskId] });
            toast.success('Comment deleted successfully');
        },
        onError: (error) => {
            toast.error('Failed to delete Comment');
        },
    });

    const { mutate: editCommentMutation } = useMutation({
        mutationFn: async () => {
            const response = await API.patch(`/task/activities-comments/${activity._id}`, { content: newComment });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['fetchActivityComments', taskId] });
            setEditMode(false)
            setCommentDetails({ ...activity, content: newComment })
            toast.success('Comment edited successfully');
        },
        onError: (error) => {
            toast.error('Failed to edit Comment');
        },
    });

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            editCommentMutation();
        }
    };

    const deletReplyComment = () => {
        deleteCommentMutation()
        setCommentDetails()
        setReplyMode(false)
    }


    return (
        <div className="bg-white border border-secondary rounded-lg" onMouseEnter={() => showMenu(activity?._id)} onMouseLeave={hideMenu}>
            <div className="p-2.5 flex flex-col gap-2">
                <div className="flex item-center gap-2 justify-between">
                    <div className="flex item-center gap-2">
                        <div
                            className={`h-6 w-6 text-white flex items-center justify-center rounded-full text-[10px] p-1 font-medium border border-border`}
                            style={{ background: getColorFromName(activity?.userId?.name) }}
                        >
                            {activity?.userId?.name
                                ?.split(' ')
                                .slice(0, 2)
                                .map((name) => name.slice(0, 1).toUpperCase())}
                        </div>
                        <p className='text-sm/6'>{activity?.userId?.name}</p>
                    </div>
                    <div className="">
                        {
                            hoverId ?
                                <div className="flex items-center gap-1.5">
                                    {
                                        reply &&
                                        <Button
                                            className="border border-border h-6 w-6  rounded-md bg-white p-0 text-dark/50 hover:bg-white"
                                            onClick={(e) => {
                                                setReplyMode(true);
                                                setCommentDetails(activity)
                                            }}
                                        >
                                            <IconReply className="h-4 w-4 text-dark/70" />
                                        </Button>
                                    }
                                    {
                                        activity?.userId?._id === user?._id &&
                                        <>
                                            <Button
                                                className="border border-border h-6 w-6  rounded-md bg-white p-0 text-dark/50 hover:bg-white"
                                                onClick={(e) => {
                                                    setEditMode(true);
                                                }}
                                            >
                                                <Pencil className="h-4 w-4" />
                                            </Button>
                                            <Button
                                                className="border border-border h-6 w-6  rounded-md bg-white p-0 text-dark/50 hover:bg-white"
                                                onClick={(e) =>
                                                    !activity ?
                                                        deleteCommentMutation() :
                                                        deletReplyComment()
                                                }
                                            >
                                                <Trash2 className='h-4 w-4 text-red-500' />
                                            </Button>
                                        </>
                                    }
                                </div> :
                                <span className='text-xs text-dark/50'>{moment(activity?.createdAt).format('ddd, DD MMM hh:mm:ss A')}</span>
                        }
                    </div>
                </div>
                {
                    editMode ?
                        <Input
                            className=" rounded-md px-4 py-2 focus:outline-none focus:ring-dark/10 pe-16"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            onKeyPress={handleKeyPress}
                        /> :
                        <div className="flex items-center justify-between">
                            <p className='text-sm'>{activity.content}</p>
                            {
                                replyCount > 0 &&
                                <div className="shadow-none bg-[#FCFCFD] hover:bg-primary/5 text-[#667085] border border-[#EAECF0] h-auto py-1 px-3 rounded-lg text-xs">
                                    {replyCount} Reply
                                </div>
                            }
                        </div>
                }
            </div>
            {
                editMode &&
                <div className="p-4 py-2 flex items-center justify-end gap-2 border-t border-secondary">
                    <Button className='h-auto p-2 px-4 bg-gray-50 shadow-none text-dark hover:bg-gray-100' onClick={() => setEditMode(false)}>Cancel</Button>
                    <Button className='h-auto p-2 px-4' onClick={() => editCommentMutation()}>Send</Button>
                </div>
            }
        </div >
    )
}

export default CommentCard