import React from 'react'

const IconReply = (props) => {
    return (
        <svg {...props} viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.264117 8.45599L6.98412 15.176C7.29612 15.488 7.80012 15.272 7.80012 14.84V11.432C7.94412 11.432 8.08812 11.408 8.20812 11.408C11.2801 11.408 13.7761 13.88 13.7761 16.904C13.7761 18.152 13.3441 19.232 12.4801 20.264C12.1441 20.648 12.6001 21.248 13.0561 21.008C16.0801 19.472 17.8801 16.616 17.8801 13.376C17.8801 8.52799 13.8721 4.61599 8.92812 4.61599C8.54412 4.61599 8.13612 4.63999 7.80012 4.68799V1.39999C7.80012 0.967988 7.29612 0.751988 6.98412 1.06399L0.264117 7.78399C0.0721172 7.97599 0.0721172 8.26399 0.264117 8.45599ZM1.27212 8.11999L6.84012 2.55199V5.23999C6.84012 5.52799 7.10412 5.76799 7.41612 5.71999C7.80012 5.64799 8.37612 5.55199 8.95212 5.55199C13.3441 5.55199 16.9201 9.05599 16.9201 13.376C16.9201 15.56 15.9841 17.576 14.3521 18.992C14.5921 18.344 14.7361 17.648 14.7361 16.928C14.7361 13.352 11.8081 10.472 8.20812 10.472C7.46412 10.472 6.84012 10.472 6.84012 11V13.688L1.27212 8.11999Z" fill="currentColor" />
        </svg>

    )
}

export default IconReply